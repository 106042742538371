import { URL } from "../constants"

export const getLatLng = async (address,token) => {
    console.log('address :', address)
    const response = await fetch(`${URL}map/latlng/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            data: address
        })
    })
    return response.json()
}

export const getCity = async (address, token) => {
    const response = await fetch(`${URL}map/city/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            data: address
        })
    })

    return response.json()
}