import React from 'react'
import { styled } from '@nextui-org/react'
import { useFormContext, Controller } from 'react-hook-form';
import Select from 'react-select'
import zIndex from '@mui/material/styles/zIndex';

const customStyles = {
    control: (state) => ({
        width: '120px',
        margin:'5px',
        height:'30px',
        display:'flex',
        fontSize:'13px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    option: (provided) => ({
        ...provided,
        cursor: 'pointer',
        fontSize:'13px',
        display:'flex',
    }),
  };

export default function SmallSelectTable({name,value, label, options, itemId, onChange}) {

    const Container = styled("div",{
        // border:'1px solid #ccc',
        padding:'0 5px',
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        ['label']:{
            fontSize:'13px',
        },
        ['select']:{
            fontSize: '13px',
            padding: '4px',
            border: '1px solid #ccc',
            ['&:focus']:{
                outline: '1px solid #ccc',
                border: '1px solid #ccc',
            }
        }
    })


    return (
    <Container>
        <label>{label}</label>
        <Select
            className="no-highlight"
            name={name}
            value={value}
            options={options}
            isSearchable={true}
            getOptionValue={(option => option)}
            styles={customStyles}
            onChange={(e)=>{console.log("e :",e); onChange(e, name, itemId)}}
            hideSelectedOptions={false}
            
        />
    </Container>
  )
}