import React, { useEffect, useState } from 'react'
import { Checkbox, Modal, styled } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import Icon from '../../../components/Icon/Icon';
import { useForm, FormProvider } from 'react-hook-form'
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getStatuses, getReasons, getSubTypes, getSolutions, getOffices, getQuoteLanguages, postQuotation } from '../../../services/quotations.services';
import toast from '../../../components/Toast/ToastTypes';
import { getUsers } from '../../../services/user.services';
import { ROLES } from '../../../constants';
import { useTranslation } from "react-i18next";
import FormInputSelect from '../../../components/FormInputSelect/FormInputSelect';
import FormInput from '../../../components/FormInput/FormInput';
import FormAutocomplete from '../../../components/SimpleAutocomplete/FormAutocomplete';
import { getPreliquidations, postLiquidation } from '../../../services/preliquidation.services';

const StyledFormModal = styled(Modal, {
    overflowY: 'auto',
    padding: '1.5rem',
    maxWidth:'60%',
    margin:'auto',
    color: '$primary_500',
    ['h3']: {
        color: '$primary_500'
    }
})
const Grid = styled('form', {
    display: 'grid',
    gap: '20px',
    gridTemplateColumns: 'repeat(3, 1fr)',
})
const formatDataArray = (dataArray) => {
    const formated = dataArray.map(data => {
        return {
            label: data?.name,
            value: data?.value || data?.id,
        }
    })
    return formated
}
const formatData = (data) => {
    return {
        label: data?.name,
        value: data?.value || data?.id,
    }
}

export default function CreateVersionModal({ open, setOpen, currentQuoteData, getData, setCurrentQuote, token }) {
    const { handleSubmit, reset, watch, trigger,formState: { errors, isValid }, ...methods } = useForm({
        defaultValues:{
            subtype:"",
            solution:"",
            office: "",
            client: "",
            origin_terminal: "",
            destination_terminal: "",
            terms: "",
            status: "",
            reason: "",
            language: "",
            observations: "",
        }
    });
    const [selectOptions, setSelectOptions] = useState({
        subtypes: [],
        solutions: [],
        offices: [],
        clients: [],
        statuses: [],
        terms: [],
        reasons: [],
        languages: [],
    })
    const [copyPreliquidation, setCopyPreliquidation] = useState(false)
   
    useEffect(() => {
        const asyncTasks = [getSubTypes(token), getSolutions(token), getOffices(token),  getStatuses(token), getBusinessTerms(token), getReasons(token), getQuoteLanguages(token), getUsers(token)]
        Promise.all(asyncTasks).then(async responses => {
            const subtypesData = await responses[0].json()
            const solutionsData = await responses[1].json()
            const officesData = await responses[2].json()
            const statusesData = await responses[3].json()
            const termsData = await responses[4].json()
            const reasonsData = await responses[5].json()
            const languagesData = await responses[6].json()
            const usersData = await responses[7].json()
            const clientsData = usersData.filter(user=>(
                [ROLES.User, ROLES.Client].includes(user.role_id)
            ))
            
            setSelectOptions({
                subtypes: formatDataArray(subtypesData),
                solutions: formatDataArray(solutionsData),
                offices: formatDataArray(officesData),
                statuses: formatDataArray(statusesData),
                terms: formatDataArray(termsData),
                reasons: formatDataArray(reasonsData),
                languages: formatDataArray(languagesData),
                clients:clientsData.map(client => {
                    return {
                        label:`${client?.name} ${client?.last_name} - ${client?.email}`,
                        value:client?.id,
                    }
                })
            });

            if (currentQuoteData.shipping){
                let currentClient = usersData.find(client => client.id === currentQuoteData.client )
                const formatClient = {
                    label:`${currentClient?.name} ${currentClient?.last_name} - ${currentClient?.email}`,
                    value:currentClient?.id,
                }
                reset({
                    subtype: formatData(subtypesData.find(subtype => subtype.id === currentQuoteData.subtype)),
                    solution: formatData(solutionsData.find(solution => solution.id === currentQuoteData.service)),
                    office: formatData(officesData.find(office => office.id === currentQuoteData.office)),
                    client: formatClient,
                    origin_terminal: currentQuoteData.shipping.origin_boarding_terminal,
                    destination_terminal: currentQuoteData.shipping.destination_boarding_terminal,
                    terms: formatData(termsData.find(term => term.id == currentQuoteData.incoterm)),
                    status: formatData(statusesData.find(status => status.id === currentQuoteData.status)),
                    reason: formatData(reasonsData.find(reason => reason.id === currentQuoteData.reason)),
                    language: formatData(languagesData.find(language => language.id === currentQuoteData.language)),
                    observations: currentQuoteData.observations,
                })
            }
        })
    }, [currentQuoteData])
    const onSubmit = (data) => {
        trigger()
        if (!isValid){
            toast("error","El formulario tiene valores no validos")
        } else {
            const submitData = {
                radication: currentQuoteData.radication,
                origin_terminal: data.origin_terminal,
                destination_terminal: data.destination_terminal,
                observations: data.observations,
                terms: data.terms.label,
                incoterm: data.terms.value,
                client: data.client?.value,
                service: data.solution?.value,
                subtype: data.subtype?.value,
                office: data.office?.value,
                language: data.language?.value,
                status: data.status?.value,
                reason: data.reason?.value,
            }
            postQuotation(submitData, token).then((res) => {
                toast("success", "Version creada con exito")
                setCurrentQuote({
                    radication:res.radication,
                    id:res.id,
                })
                if (copyPreliquidation){
                    const newVersionId = res.id
                    const prelId = currentQuoteData.preliquidation_ids[0]
                    getPreliquidations(token, prelId).then(async res => {
                        const preliquidationData = await res.json();
                        const submitPreliquidationData = {
                            ...preliquidationData,
                            quotation_ids: [newVersionId],
                        }
                        delete submitPreliquidationData.id
                        delete submitPreliquidationData.service_details.id
                        delete submitPreliquidationData.shipping_information.id
                        submitPreliquidationData.items?.forEach((item , i) => {
                            delete submitPreliquidationData.items[i].id
                            delete submitPreliquidationData.items[i].rate?.id
                        })
                        if (submitPreliquidationData.air_cargo){
                            delete submitPreliquidationData.air_cargo.id
                            delete submitPreliquidationData.air_cargo.rate?.id
                        }
                        console.log("submitPreliquidationData :",submitPreliquidationData)
                        postLiquidation(submitPreliquidationData, token).then(()=>{
                            toast("success","La preliquidacion se copio con exito")
                            getData()
                        }).catch(()=>toast("error","Error al pegar la preliquidación"))
                    }).catch(()=>toast("error","Error al copiar la preliquidación"))
                }
                setOpen(false)
            }).catch(err => { console.log('err :', err); toast("error", "Error, no pudo crearse la version") })
        }
    };
    console.log('copyPreliquidation :',copyPreliquidation)
    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Header justify='space-between'>
                <h3>Crear version de cotizacion <b>{currentQuoteData.radication}</b></h3>
            </Modal.Header>
            <FormProvider {...methods} >
            <form onSubmit={handleSubmit(onSubmit)}>
                <Modal.Body>
                    <Grid>
                        <FormInputSelect name="subtype" label="Subtipo" options={selectOptions?.subtypes} required/>
                        <FormInputSelect name="solution" label="Servicio" options={selectOptions?.solutions} required/>
                        <FormInputSelect name="office" label="Oficina" options={selectOptions?.offices} required/>
                        <FormInputSelect name="client" label="Clientes" options={selectOptions?.clients} required/>
                        <FormInputSelect name="subtype" label="Subtipo" options={selectOptions?.subtypes} required/>
                        <FormInputSelect name="subtype" label="Subtipo" options={selectOptions?.subtypes} required/>
                        <FormInputSelect name="subtype" label="Subtipo" options={selectOptions?.subtypes} required/>
                        {/* <FormInput name="origin_terminal" label="Origen"/> */}
                        <FormAutocomplete name="origin_terminal" label='Origen' required/>
                        <FormAutocomplete name="destination_terminal" label='Destino' required/>
                        <FormInputSelect name="status" label="Estado" options={selectOptions?.statuses} required/>
                        <FormInputSelect name="reason" label="Motivo" options={selectOptions?.reasons} required/>
                        <FormInputSelect name="terms" label="Incoterms" options={selectOptions?.terms} required/>
                        <FormInputSelect name="language" label="Idioma de la cotizacion" options={selectOptions?.languages} required/>
                        <FormInput name="observations" label="Observaciones" required/>
                    </Grid>
                    {
                        currentQuoteData.preliquidation_ids?.length>0 &&
                        <div style={{background:'lightgrey', padding:'5px 20px', margin:'25px 0', borderRadius:'8px'}}>
                        <Checkbox value={copyPreliquidation} onChange={(e)=>{setCopyPreliquidation(e)}}>
                        <div>
                            <p style={{fontSize:'14px'}}>Crear con preliquidación</p>
                            <p style={{fontSize:'12px'}}>Copiar la preliquidacion de la version seleccionada para que esta nueva version tenga sus mismos costos</p>
                        </div>
                        </Checkbox>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button text='Crear versión'endIcon={{name:'Versions', color:'white'}} bold bgColor={'primary_300'} type='submit' textColor='neutral_50' />
                </Modal.Footer>
            </form>
            </FormProvider>
        </StyledFormModal>
    );
}