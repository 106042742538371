import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePDF } from 'react-to-pdf';
import { Loading, styled, Tooltip } from '@nextui-org/react'
import { useSelector } from 'react-redux'
import logo from "../../../assets/img/VAL-BLANCO-HORIZONTAL.png"
//services
import { getQuoteData, getSubTypes, getSolutions, getOffices, getClients, getQuoteLanguages, getStatuses, getReasons, postQuotation, deleteQuotation, editQuotation, finishQuotation } from '../../../services/quotations.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { getQuotation } from '../../../services/quotation.services';
import { prelDetails, formatLiquidation} from '../../../services/preliquidation.services'
//components
import Button from '../../../components/Button/Button';
import { useNavigate } from 'react-router-dom';
import DetailsPdf from './DetailsPdf';


const Container = styled("div", {
    boxShadow: '$elevation_2',
    width: '90%',
    padding: '40px 90px',
    margin: '30px',
    borderRadius: '8px',
    background: '$neutral_50',
    ['.prel-info']: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    ['.load']: {
        display: 'flex',
        flexDirection: 'column',
        border: '2px solid $primary_500',
        padding: '5px',
        borderRadius: '8px',
    },
    ['.head']: {
        backgroundColor: '$primary_500',
        color: 'white'
    },
})

const Line = styled("div", {
    background: '$primary_500',
    height: '2px',
    width: '45%',
    margin: '0',
    borderRadius: '5px'
})
const Circle = styled("div", {
    position: 'relative',
    bottom: '4px',
    height: '12px',
    width: '12px',
    border: '3px solid $primary_500',
    borderRadius: '50%',
})
const StyledTable = styled("table", {
    width: '100%',
    borderCollapse: 'collapse',
    fontFamily: 'Arial, sans-serif',
    fontSize: '14px',
});
const TableCell = styled("td", {
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
});
const TableCellh = styled("td", {
    padding: '8px',
    textAlign: 'left',
    color: 'white'
});
const TableCells = styled("td", {
    padding: '8px',
    textAlign: 'center',
});
const TableHead = styled("th", {
    padding: '8px',
    textAlign: 'right',
    borderBottom: '1px solid #ddd',
});
const TableHeadh = styled("th", {
    padding: '8px',
    textAlign: 'right',
});
const TableHeads = styled("th", {
    padding: '8px',
    textAlign: 'center',
    borderBottom: '2px solid #ddd',
});
const itemsTranslation = {
    "ORIGIN": "Gastos en Origen",
    "DESTINATION": "Gastos en Destino",
    "INSURANCE": "Gastos en Seguro",
    "OCEAN": "Gastos Maritimos",
    "Air cargo":"Gastos Aéreos"
};

export default function QuoteDetails() {
    const token = useSelector(state => state.user.token)
    const [searchParams] = useSearchParams()
    const { toPDF, targetRef } = usePDF({ filename: 'quotation.pdf' })
    const navigate = useNavigate()
    const [loadingPdf, setLoadingPdf] = useState(false)
    const [loading, setLoading] = useState(false)
    const [quoteData, setQuoteData] = useState()
    const [quoteId, setQuoteId] = useState()
    const [radication, setRadication] = useState()
    const [RFQ, setRFQ] = useState()
    const [prels, setPrels] = useState({})
    const [airCargo, setairCargo] = useState({})
    const [totalUSD, setTotals] = useState({})
    const [versions, setVersions] = useState([])
    const [terms, setTerms] = useState('')
    const [current, setcurrent] = useState({});
    useEffect(() => {
        setQuoteId(searchParams.get('id'))
        setRadication(searchParams.get('radication'))
    }, [])
    function formatearFecha(fechaOriginal) {
        if (fechaOriginal) {
            const fechaFormateada = new Date(fechaOriginal).toISOString().split('T')[0];
            return fechaFormateada;
        } else {
            return ""
        }

    }
    useEffect(() => {
        const asyncTasks = [
            getQuoteData(token, quoteId),
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getBusinessTerms(token),
            getClients(token),
            getQuoteLanguages(token),
            getStatuses(token),
            getReasons(token),
            getQuoteData(token),
            getQuoteData(token, quoteId),
            // getQuotation(radication, token)
        ];
        setLoading(true)
        if (quoteId && radication) {
            Promise.all(asyncTasks).then(async responses => {
                const liquidationData = await responses[0].json();
                const subtypesData = await responses[1].json();
                const servicesData = await responses[2].json();
                const officesData = await responses[3].json();
                const incotermsData = await responses[4].json();
                const quoteLanguagesData = await responses[6].json();
                const statusesData = await responses[7].json();
                const reasonsData = await responses[8].json();
                const quotesData = await responses[9].json()
                const currentQuoteData = await responses[10].json()
                const prelD = await prelDetails(liquidationData.preliquidation_ids, token);
                let formated = await formatLiquidation([prelD[prelD.length - 1]], token)
                setcurrent(prelD[0])
                setTerms(liquidationData.terms)
                const groups = {}
                const airCargo = {}
                formated.forEach((item) => {
                    airCargo['Air cargo'] = [item.air_cargo]
                    let group = item.service_group
                    if (groups[group]) {
                        groups[group].push(item)
                    } else {
                        groups[group] = [item]
                    }
                })
                const totals = {}
                Object.keys(groups).forEach((group) => {
                    let sum = 0
                    groups[group].forEach((item) => {
                        sum += item.sale_price
                    })
                    totals[group] = sum
                })
                if (groups.DESTINATION && groups.DESTINATION.length > 0) {
                    groups.DESTINATION[0].factor = 5;
                }
                setPrels(groups)
                setTotals(totals)
                setairCargo(airCargo)

                const client = 'a' /* serviceData.users.find(user => user.role[0] === 'Usuario') */
                const comercial = 'b' /* serviceData.users.find(user => user.role[0] === 'Admin') */
                setLoading(false)
                const formattedData = {
                    subtype: subtypesData.find(e => e.id === liquidationData.subtype),
                    service: servicesData.find(e => e.id === liquidationData.service),
                    office: officesData.find(e => e.id === liquidationData.office),
                    client: client,
                    origin: liquidationData?.shipping?.origin_boarding_terminal,
                    destination: liquidationData?.shipping?.destination_boarding_terminal,
                    reason: reasonsData.find(e => e.id === liquidationData.reason),
                    incoterm: incotermsData.find(e => e.id === liquidationData.incoterm),
                    language: quoteLanguagesData.find(e => e.id === liquidationData.language),
                    status: statusesData.find(e => e.id === liquidationData.status),
                    notes: liquidationData.observations,
                    created: liquidationData.created_at,
                    approval: liquidationData.approve_date,
                    comercial: comercial,
                    creation: liquidationData.created_at
                }
                setQuoteData(formattedData);
                const versions = quotesData?.filter((quote => quote.radication === currentQuoteData.radication))
                    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                setVersions(versions)
                // if (radication) {
                //     const RFQData = await responses[11].json(); setRFQ(RFQData)
                //     console.log(liquidationData)
                // }
            }).catch(error => console.error('Error:', error));
        }
    }, [radication, quoteId]);

    function downloadPDF() {
        setLoadingPdf(true)
        toPDF().then(() => {
            setLoadingPdf(false)
        }).catch((err) => {
            console.log("error al descargar pdf :", err)
            setLoadingPdf(false)
        })
    }
    const handleVersionSelect = (event) => {
        const selectedVersion = event.target.value;
        setQuoteId(versions[selectedVersion].id)
        navigate(`/manageQuotes/quotation-details/?id=${versions[selectedVersion].id}&radication=${versions[selectedVersion].radication}`)
    }

    return (
        <>
            <DetailsPdf/>
            {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%' }}>
                <div style={{ margin: '30px 0px 0 30px' }}>
                    <Button handler={() => downloadPDF()} text={loadingPdf ? 'Cargando...' : 'Descargar'} endIcon={{ name: 'Download' }} outline disabled={loadingPdf} />
                </div>
                <div style={{ margin: '35px 0px 0 30px' }}>
                    <select id="versionSelector" onChange={handleVersionSelect}>
                        <option value="">Selecciona una versión</option>
                        {versions.map((group, index) => (
                            <option value={index} key={index}>
                                Versión {index + 1}
                            </option>
                        ))}
                    </select>
                </div>
            </div> */}
        </>
    )
}
