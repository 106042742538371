import React from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../../../theme';
import { useNavigate } from 'react-router-dom';
// Components
import Table from '../../../../components/Table/Table';
import items from '../Items';

const StyledTableDocuments = styled('div', {
    [`.${theme} &`]: {

    }
})

const TableDocuments = () => {

    const update = (item) => {
        // console.info(item)
        //console.log(item)
    }

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'Documento', uid: 'name', type: 'text' },
        { name: 'Tipo', uid: 'type', type: 'text' },
        { name: 'Fecha de vencimiento', uid: 'dueDate', type: 'date' },
    ]

    const navigate = useNavigate()

    const actions = [
        {
            name: 'Ver detalle',
            icon: 'View',
            handle: (e) => navigate('/profile/document?id=' + e.id)
        },
        {
            name: 'Descargar',
            icon: 'Download',
            handle: () => { }
        }
    ]

    return (
        <StyledTableDocuments>

        </StyledTableDocuments>
    );
}

export default TableDocuments;
