import { URL } from "../constants"



export const getTrackingDetails = async (trackingNumber, token) => {
    const response = await fetch(`${URL}thirdparties/dhl/${trackingNumber}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getShippers = async (token) => {
    const response = await fetch(`${URL}shippers/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getTrackingsByUser = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}usertracking/${startDate}/2024-06-23/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const createTracking = async (data, token) => {
    const response = await fetch(`${URL}trackings/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}