import React, { useState } from 'react'
import { styled } from '@nextui-org/react';
import { Radio } from '@nextui-org/react';

import MailForm from './MailForm';
import WhatsAppForm from './WhatsappForm';
import { useEffect } from 'react';
import { getOperations } from '../../../services/operations.services';
import { useSelector } from 'react-redux';

const StyledContainer = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px',
    gap: '15px',
    backgroundColor: '$neutral_50',
    color: '$primary_300',
    borderRadius: '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    padding: '40px',
})



export default function Notificate({ selectedOperation }) {
    const token = useSelector(state => state.user.token)

    const [currentForm, setCurrentForm] = useState('email')
    // useEffect(() => {
    //     getOperations({ token:token, id: selectedOperation }).then(async res => {
    //         const data = await res.json();
    //         setSelectedOperationData(data);
    //     })
    // }, [selectedOperation])

    return (
        <StyledContainer>
            {/* <p style={{ margin: '10px' }}>Como desea notificar al cliente :</p>
            <Radio.Group defaultValue="email" size="xs" orientation="horizontal" onChange={(e) => setCurrentForm(e)}>
                <Radio value="email"><p style={{ color: '#292E68' }}>Correo</p></Radio>
                <Radio value="whatsapp"><p style={{ color: '#292E68' }}>Whatsapp</p></Radio>
            </Radio.Group> */}
            {/* {
                currentForm === 'email' ?
                    <MailForm selectedOperation={selectedOperation} /> :
                    <WhatsAppForm />
            } */}
            <MailForm selectedOperation={selectedOperation} />
        </StyledContainer>
    )
}
