import React, { useEffect, useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  sidebarClasses,
} from "react-pro-sidebar";
import theme from "../../../theme";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { logOutAction } from "../../../store/sessionDuck";
import { ROLES } from "../../../constants";
// Componets
import Icon from "../../../components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { getServiceSplitRadication } from "../../../services/quotation.services";
import useMediaQueries from "../../../hooks/useMediaQueries";

function MainSidebar({ logOutAction, userRoles }) {
  const { collapseSidebar, collapsed, toggleSidebar, toggled } =
    useProSidebar();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const radication = searchParams.get("radication");
  const [quoteId, setQuoteId] = useState("");
  const { t: translate } = useTranslation("Sidebar");
  const token = useSelector((state) => state.user.token);
  const { md, lg } = useMediaQueries();

  useEffect(() => {
    if (!md && !toggled) {
      toggleSidebar();
    }
    if (md && toggled) {
      toggleSidebar();
    }
  }, [md]);

  useEffect(() => {
    if (!token) {
      logOutAction();
    }
  }, [token]);

  useEffect(() => {
    getServiceSplitRadication(radication, token).then(async (res) => {
      const quoteData = await res.json();
      setQuoteId(quoteData.results[0]?.id);
    });
  }, [radication]);

  const havePermissions = (allowedRoles) => {
    return allowedRoles.find((role) => userRoles.includes(role));
  };

  return toggled ? (
    <button
      onClick={() => {
        toggleSidebar();
      }}
      style={{
        background: "#3D509A",
        color: "white",
        position: "fixed",
        left: "20px",
        top: "100px",
        opacity: 0.8,
        zIndex: 801,
        borderRadius: "12px",
        padding: "10px",
      }}
    >
      {"==>"}
    </button>
  ) : (
    <Sidebar
      breakPoint="md" //768 px
      rootStyles={{
        display: toggled ? "none" : "flex",
        zIndex: "801 !important",
        maxWidth: 250,
        borderWidth: "0rem",
        [`.${sidebarClasses.container}`]: {
          zIndex: "801 !important",
          padding: !collapsed ? "1rem 2rem" : "1rem 1rem",
          backgroundColor: "white",
          height: "100vh",
          width: collapsed ? 80 : 250,
          borderRadius: "0px 0px 30px",
          display: "flex",
          flexDirection: "column",
          position: "fixed",
          top: "0",
          left: "0",
          transition: "width 0.3s",
        },
        [`.headerLogo`]: {
          display: "flex",
          flex: "0 0 auto",
          alignItems: "center",
          justifyContent: "center",
          gap: "2rem",
          marginBottom: "2rem",
          marginTop: "1rem",
          [`.logo`]: {
            display: collapsed ? "none" : "inherit",
          },
          [`.collapseButton`]: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "white",
            boxShadow:
              "0px 16px 32px rgba(79, 94, 113, 0.08), 0px 8px 16px rgba(79, 94, 113, 0.09), 0px 4px 8px rgba(79, 94, 113, 0.1), 0px 2px 4px rgba(79, 94, 113, 0.11), 0px 0px 2px rgba(79, 94, 113, 0.12)",
            borderRadius: "2rem",
            padding: "8px",
            width: "24px",
            height: "24px",
            transform: collapsed ? "rotate(180deg)" : "initial",
            cursor: "pointer",
          },
        },
      }}
    >
      <div className="headerLogo">
        {/* <img src="/logo.webp" className="logo" alt="" /> */}
        <img src="/otrologo.jpg" className="logo" alt="" />
        {/* <img src="/maersk_logo.jpg" className="logo" alt="" width='140px'/> */}
        {/* <img src="/rodatech_logo.png" className="logo" alt="" width='140px'/> */}
        <div
          className="collapseButton"
          onClick={() => (md ? collapseSidebar() : toggleSidebar())}
        >
          <img
            src="/sidebar_collapse_arrow.svg"
            alt=""
            width="6px"
            height="11px"
          />
        </div>
      </div>

      {userRoles.length > 0 && (
        <>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    color: active ? "#FFFFFF" : "#292E68",
                    backgroundColor: active ? "#3D509A" : undefined,
                    borderRadius: "8px",
                    ":hover": {
                      background: active ? "#3D509A" : "#F3F5FF",
                    },
                    padding: 7,
                  };
                if (level === 1)
                  return {
                    color: active ? "#FFFFFF" : "#292E68",
                    backgroundColor: active ? "#EB9D3A" : undefined,
                    borderRadius: "8px",
                    ":hover": {
                      background: active ? "#EB9D3A" : "#F3F5FF",
                    },
                    padding: 7,
                  };
              },
            }}
            style={{ flex: "1 1 0%", marginBottom: "32px" }}
          >
            <MenuItem
              icon={
                <Icon
                  name="Diagram"
                  color={
                    location.pathname == "/"
                      ? "white"
                      : theme.colors.primary_500
                  }
                />
              }
              active={location.pathname == "/"}
              component={<Link to="/" />}
            >
              {translate("home")}
            </MenuItem>
            {
              /*faltaria el rol de Asesor,Pricing*/
              havePermissions([
                ROLES.Admin,
                ROLES.User,
                ROLES.Client,
                ROLES.Advisor,
                ROLES.Pricing,
                ROLES.Commercial,
              ]) && (
                <div className="quotationSubMenu">
                  <SubMenu
                    label={translate("quotes")}
                    color={
                      location.pathname == "/services" ||
                      location.pathname.startsWith("/services/detail")
                        ? "white"
                        : theme.colors.primary_500
                    }
                    icon={
                      <Icon
                        name="Cotizaciones"
                        color={
                          location.pathname.startsWith("/quotation-requests")
                            ? "white"
                            : theme.colors.primary_500
                        }
                      />
                    }
                    active={
                      location.pathname.startsWith("/quotation-requests") ||
                      location.search.startsWith("?radication=QRFQ")
                    }
                    // component={<Link to="/quotation-requests" />}
                    rootStyles={{
                      ul: {
                        backgroundColor: "#F3F5FF",
                        borderRadius: "0px 0px 8px 8px",
                      },
                      li: {
                        marginTop: "0.625rem",
                        marginBottom: "0rem",
                      },
                    }}
                  >
                    <MenuItem
                      component={<Link to="/quotation-requests" />}
                      label={translate("quotes")}
                      color={
                        location.pathname == "/services" ||
                        location.pathname.startsWith("/services/detail")
                          ? "white"
                          : theme.colors.primary_500
                      }
                      icon={
                        <Icon
                          name="Cotizaciones"
                          color={
                            location.pathname.startsWith("/quotation-requests")
                              ? "white"
                              : theme.colors.primary_500
                          }
                        />
                      }
                      active={
                        location.pathname.startsWith("/quotation-requests") ||
                        location.search.startsWith("?radication=QRFQ")
                      }
                    >
                      {translate("view_quotations")}
                    </MenuItem>
                    {havePermissions([
                      ROLES.Admin,
                      ROLES.Advisor,
                      ROLES.Pricing,
                      ROLES.Commercial,
                    ]) && (
                      <MenuItem
                        icon={<Icon name="Manage" />}
                        active={location.pathname == "/manageQuotes"}
                        component={<Link to="/manageQuotes/?tab=form" />}
                      >
                        {translate("liquidation")}
                      </MenuItem>
                    )}
                    {havePermissions([
                      ROLES.Admin,
                      ROLES.Advisor,
                      ROLES.Pricing,
                      ROLES.Commercial,
                    ]) && (
                      <MenuItem
                        icon={<Icon name="Diagram" />}
                        active={location.pathname == "/manageQuotes/dashboard"}
                        component={<Link to="/manageQuotes/dashboard" />}
                      >
                        {translate("dashboard")}
                      </MenuItem>
                    )}
                  </SubMenu>
                </div>
              )
            }
            {
              /*Faltaria el rol de asesor y %pricing */
              havePermissions([
                ROLES.Admin,
                ROLES.Advisor,
                ROLES.Pricing,
                ROLES.Commercial,
                ROLES.Operations,
              ]) && (
                <SubMenu
                  label={translate("operations")}
                  icon={
                    <Icon
                      name="Operations"
                      size="50px"
                      color={
                        location.pathname == "/operations" ||
                        location.pathname.startsWith("/operations")
                          ? "white"
                          : theme.colors.primary_500
                      }
                    />
                  }
                  active={location.pathname.startsWith("/operations")}
                  rootStyles={{
                    ul: {
                      backgroundColor: "#F3F5FF",
                      borderRadius: "0px 0px 8px 8px",
                    },
                    li: {
                      marginTop: "0.625rem",
                      marginBottom: "0rem",
                    },
                    // ".ps-submenu-expand-icon": {
                    //   display: "none",
                    // },
                  }}
                >
                  <MenuItem
                    icon={<Icon name="Tracking" size="30px" />}
                    active={location.pathname.startsWith(
                      "/operations/tracking"
                    )}
                    component={
                      <Link to="/operations/tracking/?tab=create_operation" />
                    }
                  >
                    {translate("operationsTracking")}
                  </MenuItem>
                  <MenuItem
                    icon={<Icon name="Diagram" size="25px" />}
                    active={location.pathname == "/operations/dashboard"}
                    component={<Link to="/operations/dashboard" />}
                  >
                    {translate("dashboard")}
                  </MenuItem>
                </SubMenu>
              )
            }
            {havePermissions([
              ROLES.Admin,
              ROLES.User,
              ROLES.Client,
              ROLES.Advisor,
              ROLES.Guest,
              ROLES.Supplier,
              ROLES.Pricing,
              ROLES.Commercial,
              ROLES.Operations,
            ]) && (
              <div className="servicesSubMenu">
                <SubMenu
                  label={translate("services")}
                  icon={
                    <Icon
                      name="Servicios"
                      color={
                        location.pathname.startsWith("/services") ||
                        location.search.startsWith("?radication=VAL")
                          ? "white"
                          : theme.colors.primary_500
                      }
                    />
                  }
                  active={
                    location.pathname.startsWith("/services") ||
                    location.search.startsWith("?radication=VAL")
                  }
                  // component={<Link to="/services" />}
                  // open={location.pathname.startsWith("/services/detail")}
                  rootStyles={{
                    ul: {
                      backgroundColor: "#F3F5FF",
                      borderRadius: "0px 0px 8px 8px",
                    },
                    li: {
                      marginTop: "0.625rem",
                      marginBottom: "0rem",
                    },
                    // ".ps-submenu-expand-icon": {
                    //   display: "none",
                    // },
                  }}
                >
                  {!collapsed &&
                    location.pathname.startsWith("/services/detail") && (
                      <MenuItem
                        icon={
                          <Icon
                            name="Mail"
                            color={
                              location.pathname == "/services/detail/chat"
                                ? "white"
                                : theme.colors.primary_500
                            }
                          />
                        }
                        active={location.pathname == "/services/detail/chat/"}
                        component={
                          <Link
                            to={`/services/detail/chat/?radication=${radication}&tab=chat`}
                          />
                        }
                      >
                        {translate("chat")}
                      </MenuItem>
                    )}
                  {!collapsed &&
                    location.pathname.startsWith("/services/detail") &&
                    havePermissions([
                      ROLES.Admin,
                      ROLES.User,
                      ROLES.Advisor,
                      ROLES.Guest,
                      ROLES.Supplier,
                      ROLES.Pricing,
                      ROLES.Commercial,
                      ROLES.Operations,
                    ]) && (
                      <MenuItem
                        icon={
                          <Icon
                            name="File"
                            color={
                              location.pathname ==
                              "/services/detail/liquidation"
                                ? "white"
                                : theme.colors.primary_500
                            }
                          />
                        }
                        active={
                          location.pathname == "/services/detail/liquidation/"
                        }
                        component={
                          <Link
                            to={`/services/detail/liquidation/?radication=${radication}&id=${quoteId}`}
                          />
                        }
                      >
                        {translate("liquidation")}
                      </MenuItem>
                    )}
                  <MenuItem
                    rootStyles={{
                      [".ps-menu-label"]: {
                        marginTop: "5px",
                      },
                    }}
                    icon={
                      <Icon
                        name="View"
                        color={
                          location.pathname == "/services"
                            ? "white"
                            : theme.colors.primary_500
                        }
                      />
                    }
                    active={location.pathname == "/services"}
                    component={<Link to="/services" />}
                  >
                    <span>{translate("view_services")}</span>
                  </MenuItem>
                  {havePermissions([
                    ROLES.Admin,
                    ROLES.User,
                    ROLES.Client,
                    ROLES.Advisor,
                    ROLES.Supplier,
                    ROLES.Pricing,
                    ROLES.Commercial,
                    ROLES.Operations,
                    ROLES.Co2,
                  ]) && (
                    <MenuItem
                      rootStyles={{
                        [".ps-menu-label"]: {
                          marginTop: "5px",
                        },
                      }}
                      icon={
                        <Icon
                          name="Diagram"
                          color={
                            location.pathname == "/services/Dash"
                              ? "white"
                              : theme.colors.primary_500
                          }
                        />
                      }
                      active={location.pathname == "/services/Dash"}
                      component={<Link to="/services/Dash" />}
                    >
                      <span> {translate("dashboard")}</span>
                    </MenuItem>
                  )}
                </SubMenu>
              </div>
            )}

            {
              //    havePermissions([ROLES.Admin, ROLES.User, ROLES.Advisor]) &&
              //  <MenuItem
              //    icon={<Icon name="4pl" color={location.pathname == "/4pl" ? "white" : theme.colors.primary_500} />}
              //     active={location.pathname == "/4pl"}
              //     component={<Link to="/4pl" />}
              //  >
              //</Menu>      4PL
              //   </MenuItem>
            }
            {havePermissions([
              ROLES.Admin,
              ROLES.User,
              ROLES.Client,
              ROLES.Advisor,
              ROLES.Supplier,
              ROLES.Pricing,
              ROLES.Commercial,
              ROLES.Operations,
              ROLES.Co2,
            ]) && (
              <SubMenu
                rootStyles={{
                  [".ps-menu-label"]: {
                    marginTop: "5px",
                  },
                }}
                label={
                  <span
                    dangerouslySetInnerHTML={{ __html: "CO<sub>2</sub>" }}
                  />
                }
                icon={<Icon name="Tree" color={theme.colors.primary_500} />}
                // active={location.pathname == "/co2"}
              >
                {havePermissions([
                  ROLES.Admin,
                  ROLES.User,
                  ROLES.Client,
                  ROLES.Advisor,
                  ROLES.Supplier,
                  ROLES.Pricing,
                  ROLES.Commercial,
                  ROLES.Operations,
                  ROLES.Co2,
                ]) && (
                  <MenuItem
                    rootStyles={{
                      [".ps-menu-label"]: {
                        marginTop: "5px",
                      },
                    }}
                    icon={
                      <Icon
                        name="TreePerson"
                        color={
                          location.pathname == "/co2"
                            ? "white"
                            : theme.colors.primary_500
                        }
                      />
                    }
                    active={location.pathname == "/co2"}
                    component={<Link to="/co2" />}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translate("demo_co2"),
                      }}
                    />
                  </MenuItem>
                )}

                {havePermissions([
                  ROLES.Admin,
                  ROLES.User,
                  ROLES.Client,
                  ROLES.Advisor,
                  ROLES.Supplier,
                  ROLES.Pricing,
                  ROLES.Commercial,
                  ROLES.Operations,
                  ROLES.Co2,
                ]) && (
                  <MenuItem
                    rootStyles={{
                      [".ps-menu-label"]: {
                        marginTop: "5px",
                      },
                    }}
                    icon={
                      <Icon
                        name="Diagram"
                        color={
                          location.pathname == "/co2/Dash"
                            ? "white"
                            : theme.colors.primary_500
                        }
                      />
                    }
                    active={location.pathname == "/co2/Dash"}
                    component={<Link to="/co2/Dash" />}
                  >
                    <span> {translate("dashboard")}</span>
                  </MenuItem>
                )}
                {havePermissions([
                  ROLES.Admin,
                  ROLES.User,
                  ROLES.Client,
                  ROLES.Advisor,
                  ROLES.Supplier,
                  ROLES.Pricing,
                  ROLES.Commercial,
                  ROLES.Operations,
                  ROLES.Co2,
                ]) && (
                  <MenuItem
                    rootStyles={{
                      [".ps-menu-label"]: {
                        marginTop: "5px",
                      },
                    }}
                    icon={
                      <Icon
                        name="Calculator"
                        color={
                          location.pathname == "/co2/persons/calculate"
                            ? "white"
                            : theme.colors.primary_500
                        }
                      />
                    }
                    active={location.pathname == "/co2/persons/calculate"}
                    component={<Link to="/co2/persons/calculate" />}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: translate("co2_calculator"),
                      }}
                    />
                  </MenuItem>
                )}
                {havePermissions([
                  ROLES.Admin,
                  ROLES.User,
                  ROLES.Client,
                  ROLES.Advisor,
                  ROLES.Supplier,
                  ROLES.Pricing,
                  ROLES.Commercial,
                  ROLES.Operations,
                  ROLES.Co2,
                ]) && (
                  <MenuItem
                    rootStyles={{
                      [".ps-menu-label"]: {
                        marginTop: "5px",
                      },
                    }}
                    icon={
                      <Icon
                        name="Historial"
                        color={
                          location.pathname == "/co2/history"
                            ? "white"
                            : theme.colors.primary_500
                        }
                      />
                    }
                    active={location.pathname == "/co2/history"}
                    component={<Link to="/co2/history" />}
                  >
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: translate("co2_history"),
                        }}
                      />
                    </span>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {havePermissions([
              ROLES.Admin,
              ROLES.User,
              ROLES.Client,
              ROLES.Advisor,
              ROLES.Pricing,
              ROLES.Commercial,
              ROLES.Operations,
            ]) && (
              <SubMenu
                icon={
                  <Icon
                    name="Rastreo"
                    color={
                      location.pathname == "/tracking"
                        ? "white"
                        : theme.colors.primary_500
                    }
                  />
                }
                label={translate("tracking")}
                active={location.pathname == "/tracking"}
                // component={<Link to="/tracking" />}
              >
                <MenuItem
                  rootStyles={{
                    [".ps-menu-label"]: {
                      marginTop: "5px",
                    },
                  }}
                  icon={
                    <Icon
                      name="Diagram"
                      size="20px"
                      color={
                        location.pathname == "/tracking/dashboard"
                          ? "white"
                          : theme.colors.primary_500
                      }
                    />
                  }
                  active={location.pathname.startsWith("/tracking/dashboard")}
                  component={<Link to="/tracking/dashboard/?tab=summary" />}
                >
                  Dashboard
                </MenuItem>
                <MenuItem
                  rootStyles={{
                    [".ps-menu-label"]: {
                      marginTop: "5px",
                    },
                  }}
                  icon={
                    <Icon
                      name="Containers"
                      color={
                        location.pathname.startsWith("/tracking/ocean-shipment")
                          ? "white"
                          : theme.colors.primary_500
                      }
                    />
                  }
                  active={location.pathname.startsWith(
                    "/tracking/ocean-shipment"
                  )}
                  component={
                    <Link to="/tracking/ocean-shipment/?tab=tracking" />
                  }
                >
                    {translate("ocean_shipment")}
                </MenuItem>
                <MenuItem
                  rootStyles={{
                    [".ps-menu-label"]: {
                      marginTop: "5px",
                    },
                  }}
                  icon={
                    <Icon
                      name="Aereo"
                      color={
                        location.pathname.startsWith("/tracking/air-shipment")
                          ? "white"
                          : theme.colors.primary_500
                      }
                    />
                  }
                  active={location.pathname.startsWith(
                    "/tracking/air-shipment"
                  )}
                  component={<Link to="/tracking/air-shipment/?tab=tracking" />}
                >
                    {translate("air_shipment")}
                </MenuItem>
              </SubMenu>
            )}
            {havePermissions([
              ROLES.Admin,
              ROLES.User,
              ROLES.Client,
              ROLES.Advisor,
              ROLES.Pricing,
              ROLES.Commercial,
              ROLES.Operations,
            ]) && (
              <MenuItem
                icon={
                  <Icon
                    name="Historial"
                    color={
                      location.pathname == "/history"
                        ? "white"
                        : theme.colors.primary_500
                    }
                  />
                }
                active={location.pathname == "/history"}
                component={<Link to="/history" />}
              >
                {translate("history")}
              </MenuItem>
            )}
            {havePermissions([ROLES.Admin, ROLES.Advisor, ROLES.User]) && (
              <MenuItem
                icon={
                  <Icon
                    name="Technology"
                    color={
                      location.pathname == "/ValGpt"
                        ? "white"
                        : theme.colors.primary_500
                    }
                  />
                }
                active={location.pathname == "/ValGpt"}
                component={<Link to="/ValGpt" />}
              >
                GPT
              </MenuItem>
            )}
            {havePermissions([
              ROLES.Admin,
              ROLES.User,
              ROLES.Client,
              ROLES.Advisor,
              ROLES.Pricing,
              ROLES.Commercial,
              ROLES.Operations,
            ]) && (
              <MenuItem
                icon={
                  <Icon
                    name="Soporte"
                    color={
                      location.pathname == "/support"
                        ? "white"
                        : theme.colors.primary_500
                    }
                  />
                }
                active={location.pathname == "/support"}
                component={<Link to="/support" />}
              >
                {translate("support")}
              </MenuItem>
            )}
            {havePermissions([
              ROLES.Admin,
              ROLES.Advisor,
              ROLES.Pricing,
              ROLES.Operations,
            ]) && (
              <MenuItem
                icon={
                  <Icon
                    name="Diagram"
                    color={
                      location.pathname == "/backstage"
                        ? "white"
                        : theme.colors.primary_500
                    }
                  />
                }
                active={location.pathname == "/backstage"}
                component={<Link to="/backstage/?tab=dashboard" />}
              >
                {translate("management")}
              </MenuItem>
            )}
          </Menu>

          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                // only apply styles on first level elements of the tree
                if (level === 0)
                  return {
                    borderRadius: "0px 0px 8px 8px",
                    padding: 7,
                  };
              },
            }}
            style={{ borderTop: "solid 1px #DFE2E6" }}
          >
            <MenuItem
              icon={<Icon name="Exit" />}
              active={location.pathname == "/"}
              component={<Link to="/" />}
              onClick={logOutAction}
            >
              {translate("log-out")}
            </MenuItem>
          </Menu>
        </>
      )}
    </Sidebar>
  );
}

const mapState = ({}) => {
  return {};
};

export default connect(mapState, { logOutAction })(MainSidebar);
