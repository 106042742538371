import React from "react";
import { styled, Input as inp } from "@nextui-org/react";
import theme from "../../theme";
import Icon from "../Icon/Icon";

const StyledInput = styled(inp, {
  [`.${theme} &`]: {
    borderRadius: "8px",
    border: "solid 1px $primary_300",
    transition: "border 0.25s ease",
    [".nextui-input-wrapper"]: {
      borderRadius: "6px",
      backgroundColor: "$neutral_50",
    },
    ["label"]: {
      color: "$primary_500",
    },
  },
  ["& + .nextui-input-helper-text-container .nextui-input-helper-text"]: {
    fontSize: "11px",
  },
});

const StyledInputPassword = styled(inp.Password, {
  [`.${theme} &`]: {
    borderRadius: "8px",
    border: "solid 1px $primary_300",
    [".nextui-input-wrapper"]: {
      borderRadius: "6px",
      backgroundColor: "$neutral_50",
    },
    [".nextui-input-helper-text"]: {
      fontSize: "12px",
      color: "yellow",
    },
  },
  ["& + .nextui-input-helper-text-container .nextui-input-helper-text"]: {
    fontSize: "12px",
  },
});

const Input = ({
  className,
  placeholder,
  name,
  value,
  onChange,
  label,
  labelColor = "primary_500",
  password,
  disabled = false,
  startIcon,
  endIcon,
  fullWidth,
  helperText,
  required = false,
  type,
  animated = false,
  selectBorderColor = "primary_100",
  onContentClick = () => { },
  register = () => { },
}) => {
  const StyledLabel = styled("div", {
    [`.${theme} &`]: {
      color: `$${labelColor}`,
      ["span"]: {
        color: "#ff676c",
      },
    },
  });

  if (password) {
    return (
      <StyledInputPassword
        {...register(name)}
        name={name}
        fullWidth={fullWidth}
        animated={false}
        placeholder={placeholder}
        label={
          label ? (
            <StyledLabel>
              {label} {required ? <span>*</span> : ""}
            </StyledLabel>
          ) : (
            ""
          )
        }
        aria-label={name || 'input'}
        helperText={helperText}
        css={{
          "&:focus-within": { border: `solid 1px $${selectBorderColor}` },
        }}
      />
    );
  }

  return (
    <StyledInput
      {...register(name, { required })}
      className={className}
      fullWidth={fullWidth}
      animated={animated}
      placeholder={placeholder}
      aria-label={name}
      value={value}
      disabled={disabled}
      name={name}
      type={type}
      onChange={onChange}
      label={
        label ? (
          <StyledLabel>
            {label} {required ? <span>*</span> : ""}
          </StyledLabel>
        ) : (
          ""
        )
      }
      helperText={helperText}
      css={{ "&:focus-within": { border: `solid 1px $${selectBorderColor}` } }}
      contentLeft={
        startIcon ? (
          <Icon
            name={startIcon?.name}
            color={startIcon?.color}
            size={startIcon?.size}
          ></Icon>
        ) : (
          ""
        )
      }
      contentRight={endIcon ? endIcon : ""}
      contentRightStyling
      contentClickable
      onContentClick={onContentClick}
    ></StyledInput>
  );
};

export default Input;
