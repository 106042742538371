import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { styled, Loading } from "@nextui-org/react";
import InputSelect from "../../components/Select/inputSelect";
import Button from "../../components/Button/Button";
import toast from "../../components/Toast/ToastTypes";
import { getQuotationByRadication, getSubTypes } from "../../services/quotations.services";

import { useForm, Controller } from "react-hook-form";

import {
  postOperation,
  getOperationTypes,
  getStatuses,
  getOperationStatuses,
  getAgents,
  getCoordinators,
} from "../../services/operations.services";
import { getProviders,getProviderTypes } from "../../services/preliquidation.services";
/*ffw,currier*/
import { getUsers } from "../../services/user.services";
import { getClients } from "../../services/quotations.services";
import { getServiceSplitRadication } from "../../services/quotation.services";
import DisplayData from "../ManageQuotes/QuoteForm/DisplayData";
import PendingTable from "./PendingTable";
import { ROLES } from "../../constants";
import { useTranslation } from "react-i18next";

const StyledContainer = styled("div", {
  display: "grid",
  marginTop: "35px",
  gridTemplateColumns: "55% 45%",
  gap: "30px",
});

const FormContainer = styled("form", {
  display: "grid",
  gridTemplateColumns: "50% 50%",
  padding: "50px",
  boxShadow: "$elevation_1",
  gap: "30px",
  flexDirection: "column",
  backgroundColor: "$neutral_50",
  borderRadius: "8px",
  fontSize: "13px",
});
const InputContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  ["span"]: {
    color: "$delete",
    fontSize: "12px",
  },
});
const RFQDataContainer = styled("div", {
  overflowY: "scroll",
  maxH: "540px",
  gridRow: "span 2",
  background: "$neutral_50",
  borderRadius: "8px",
  boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.3)",
  padding: "20px",
});

export default function CreateOperation({
  updateTable,
  setUpdateTable,
  setSelectedOperation,
}) {
  const {
    handleSubmit,
    control,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const token = useSelector((state) => state.user.token);
  const [showPending, setShowPending] = useState(true);

  const [radication, setRadication] = useState();
  const [RFQ, setRFQ] = useState();

  const [users, setUsers] = useState([]);
  const [operationsData, setOperationsData] = useState();
  const { t: translate } = useTranslation("OperationsTracking");

  function getData() {
    setRFQ();
    const asyncTasks = [
      getOperationTypes(token),
      getUsers(token),
      getClients(token),
      getAgents(token),
      getStatuses(token),
      getOperationStatuses(token),
      getServiceSplitRadication(radication, token),
      getQuotationByRadication(token, radication),
      getProviders(token),
      getProviderTypes(token),
      getSubTypes(token)
    ];
    Promise.all(asyncTasks)
      .then(async (responses) => {
        const operationTypesData = await responses[0].json();
        const usersData = await responses[1].json();
        const agentsData = await responses[3].json();
        const statusesData = await responses[4].json();
        const operationStatusesData = await responses[5].json();
        const RFQData = await responses[6].json();
        const currentQuotation = await responses[7].json();
        const providersData = await responses[8].json();
        const providerTypesData = await responses[9].json();
        const subTypesData = await responses[10].json();

        const quotationSubType = subTypesData.find(type => type.id === currentQuotation.subtype)?.name
        const operationType = operationTypesData.find(type => type.name?.toLowerCase() == quotationSubType?.toLowerCase())

        setValue("operation_type", {
          key: operationType?.id,
          label:operationType?.name,
          value:operationType?.id,
        })

        const currentClient = currentQuotation.client;
        setRFQ(RFQData.results[0]);
        const client = RFQData.results[0]?.users?.find(
          (user) =>
            user.user?.groups.includes("Usuario") ||
            user.user?.groups.includes("Cliente")
        )?.user;
        if (client) {
          setValue("client", {
            label: `${client.first_name} ${client.last_name} - ${client.email}`,
            value: client.user,
          });
        }
        setUsers(usersData);

        let filteredProviders = providersData.filter(prov => {
          const type = providerTypesData.find(type => type.id == prov.provider_type)
          if (['COURIER','FFWW'].includes(type.name)){
            return true
          } else return false
        })

        setOperationsData({
          types: operationTypesData,
          statuses: statusesData,
          operation_statuses: operationStatusesData,
          clients: usersData,
          coordinators: usersData.filter((user) =>
            [
              ROLES.Admin,
              ROLES.Advisor,
              ROLES.Pricing,
              ROLES.Operations,
            ].includes(user.role_id)
          ),
          filteredProviders,
          agents: agentsData,
          currentClient: currentClient,
        });
      })
      .catch((err) => console.log("ERROR:", err));
  }
  useEffect(() => {
    if (radication) {
      getData();
    }
  }, [radication]);
  const onSubmit = (formData) => {
    const submitData = {
      // ETD: ETD,
      // ETA: ETA,
      operation_type: formData.operation_type?.value,
      status: formData.status?.value,
      operation_status: formData.operation_status?.value,
      client: formData.client?.value,
      agent: formData.agent?.value,
      coordinator: formData.coordinator?.value,
      radication,
    };
    postOperation(submitData, token)
      .then(async (res) => {
        const response = await res.json();
        console.log("response :", response);
        setSelectedOperation({
          id: response.id,
          radication: response.radication,
        });
        setUpdateTable(updateTable + 1);
        toast("success", "Operación creada con exito");
      })
      .catch((err) => toast("error", "No se ha podido crear la operacion"));
  };

  return (
    <div>
      <Button
        text={
          showPending
            ? "Ocultar operaciones pendientes"
            : translate("pending_op")
        }
        bold
        bgColor={"primary_300"}
        endIcon={{ name: "View", color: "white" }}
        textColor="neutral_50"
        handler={() => {
          showPending ? setShowPending(false) : setShowPending(true);
        }}
      />
      <StyledContainer>
        {operationsData && (
          <FormContainer onSubmit={handleSubmit(onSubmit)}>
            <InputContainer>
              <Controller
                name="operation_type"
                control={control}
                rules={{ required: "Este campo es obligatorio" }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    label="Tipo de operación"
                    options={operationsData?.types?.map((type) => ({
                      key: type.id,
                      label: type.name,
                      value: type.id,
                    }))}
                  />
                )}
              />
              {errors.operation_type && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.operation_type.message}
                </span>
              )}
            </InputContainer>
            <InputContainer>
              <Controller
                name="status"
                control={control}
                rules={{ required: "Este campo es obligatorio" }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    label="Estado"
                    options={operationsData.statuses?.map((status) => ({
                      label: status.name,
                      value: status.id,
                    }))}
                  />
                )}
              />
              {errors.status && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.status.message}
                </span>
              )}
            </InputContainer>
            <InputContainer>
              <Controller
                name="operation_status"
                control={control}
                rules={{ required: "Este campo es obligatorio" }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    label="Estado de operación"
                    options={operationsData.operation_statuses?.map(
                      (status) => ({
                        label: status.name,
                        value: status.id,
                      })
                    )}
                  />
                )}
              />
              {errors.operation_status && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.operation_status.message}
                </span>
              )}
            </InputContainer>
            <InputContainer>
              <Controller
                name="agent"
                control={control}
                rules={{ required: "Este campo es obligatorio" }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    label="Agente"
                    options={operationsData.agents?.map((agent) => ({
                      label: agent.name,
                      value: agent.id,
                    }))}
                  />
                )}
              />
              {errors.agent && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.agent.message}
                </span>
              )}
            </InputContainer>
            <InputContainer style={{ gridColumn: "span 2" }}>
              <Controller
                name="supplier"
                control={control}
                render={({ field }) => (
                  <>
                    <label htmlFor="supplier">Tercerizado</label>
                    <InputSelect
                      {...field}
                      label=""
                      options={[
                        { label: "No", value: "" },
                        ...operationsData.filteredProviders?.map((prov) => ({
                          label: `${prov?.name} - ${prov?.mail}`,
                          value: prov.id,
                        })),
                      ]}
                      defaultValue=""
                    />
                  </>
                )}
              />
              {errors.supplier && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.supplier.message}
                </span>
              )}
            </InputContainer>

            <InputContainer style={{ gridColumn: "span 2" }}>
              <Controller
                name="client"
                control={control}
                rules={{ required: "Este campo es obligatorio" }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    label="Cliente"
                    options={operationsData?.clients?.map((client) => ({
                      label: `${client?.name} ${client?.last_name} - ${client?.email}`,
                      value: client?.id,
                    }))}
                    defaultValue={operationsData.currentClient}
                  />
                )}
              />
              {errors.client && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.client.message}
                </span>
              )}
            </InputContainer>
            <InputContainer style={{ gridColumn: "span 2" }}>
              <Controller
                name="coordinator"
                control={control}
                rules={{ required: "Este campo es obligatorio" }}
                render={({ field }) => (
                  <InputSelect
                    {...field}
                    label="Coordinador"
                    options={operationsData.coordinators?.map((coordinator) => {
                      return {
                        label: `${coordinator?.name} ${coordinator?.last_name} - ${coordinator?.email}`,
                        value: coordinator.id,
                      };
                    })}
                  />
                )}
              />
              {errors.coordinator && (
                <span style={{ color: "#FF6B6B", fontSize: "12px" }}>
                  {errors.coordinator.message}
                </span>
              )}
            </InputContainer>

            <Button
              text="Crear operacion"
              disabled={Object.keys(errors).length || !radication}
              bold
              bgColor={"primary_300"}
              textColor="neutral_50"
              type="submit"
            />
          </FormContainer>
        )}
        <RFQDataContainer>
          {!radication ? (
            <div style={{gridColumn:'span 2'}}>
              <h3>Selecciona una cotizacion</h3>
              <p>
                Presiona "Nueva operacion" y selecciona una cotizacion de la
                tabla para empezar a operar
              </p>
            </div>
          ) : RFQ ? (
            <DisplayData data={RFQ} radication={radication} />
          ) : (
            <Loading />
          )}
        </RFQDataContainer>
        {showPending && (
          <div style={{gridColumn:'span 2'}}>
            <PendingTable
              setRadication={setRadication}
              setShowPending={setShowPending}
            />
          </div>
      )}
      </StyledContainer>
    </div>
  );
}
