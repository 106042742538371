import { useState } from "react"
import { Modal, styled } from "@nextui-org/react"
import format from 'date-fns/format'
import TextEditor from '../../../../components/TextEditor/TextEditor'

import DropZone from '../../../../components/DropZone/DropZone'

import Input from '../../../../components/Input/Input'
import Button from '../../../../components/Button/Button'
import DatePickerModal from '../../../../components/DatePicker/DatePicker'
import { useSelector } from "react-redux"
import { createServiceByServiceSplit } from '../../../../services/quotation.services';
import LoadingBar from 'react-top-loading-bar'
import { useNavigate  } from "react-router-dom";
import { sendMessage } from "../../../../services/chat.services"
import { SendFilesToService } from "../../../../services/quotation.services"
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import toast from "../../../../components/Toast/ToastTypes";


const StyledModal = styled(Modal,{
    background:'$neutral_50',
    color:'$primary_500 !important',
    padding:'10px',
})


const data = 
    {
    
      "loads": [],
      "radication": "",
      "business_type": "",
      "expiration_date": null,
      "industry": null,
      "other_industry": null,
      "business_term": null,
      "state": 1
  }

export default function QuickRFQModal({open, setOpen}){
    const navigate = useNavigate();
    const token = useSelector(state => state.user.token)
    const [loadingtop, SetLoadingtop] = useState(0)
    const [date, setDate] = useState(new Date());
    const [time, setTime] = useState(format(new Date(), 'HH:mm'))
    const [note, setNote] = useState("<ul><li>Origen:</li><li>Destino:&nbsp;</li</ul><p>A&ntilde;ade las especificaciones que quieras a continuaci&oacute;n&nbsp;📦: </p>")
    const [files, setFiles] = useState([]);
    const [disableConfirm, setDisableConfirm] = useState(false)
    const [openDate, setOpenDate] = useState(false);
    const [openBackdrop, setOpenBackdrop] =  useState(false);

    const handleClose = () => {
        toast("info", "Enviando Cotizacion por favor espera");
    };
    const handleOpen = () => {
        setOpenBackdrop(true);
    };

    const handleSetSubState = async () => {
        try {
            
            setOpenBackdrop(true);
            const service = await createServiceByServiceSplit(data, token);
           
            console.log(service);
            const radication = service.radication;
            await sendMessage(token, note, radication);
            await SendFilesToService(token, radication, files)
            SetLoadingtop(100);
            setOpenBackdrop(false);
            navigate(`/services/detail/chat/?radication=${radication}&tab=chat`);
          } catch (error) {
            console.error(error);
          }
        // setDisableConfirm(true)

     
    }
    const handleFiles = (newFiles) => {
        setFiles(newFiles);
      };
      

    return (
        <StyledModal
            closeButton
            open={open}
            onClose={() => { setOpen(false) }}
            width="700px"
        >


<Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
            <LoadingBar
color='#3D509A'
progress={loadingtop}
onLoaderFinished={() => SetLoadingtop(0)}
/>
            <Modal.Body>
                <h3>Crear cotizacion rapida</h3>
                <p style={{fontSize:'12px'}}>Sube como minimo factura comercial y lista de empaque para iniciar</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* <div onClick={() => { setOpenDate(true) }} style={{ display: 'flex', width: 'fit-content' }}>
                        <label style={{ margin: '7px' }}>Fecha :</label>
                        <Input name="Fecha" value={format(date, "dd/MM/yyyy")} />
                    </div> */}
                  {/*   <Input name="Hora" type={'time'} value={time} onChange={(e) => setTime(e.target.value)} /> */}
                </div>
                <TextEditor state={note} setState={setNote} />
                <DropZone files={files} handleFiles={handleFiles}/>
            </Modal.Body>
            <Modal.Footer>
                <Button text="Cancelar" handler={() => setOpen(false)} outline />
                <Button disabled={disableConfirm} text="Confirmar" bold outline bgColor={'primary_500'} textColor={'neutral_50'} handler={() => handleSetSubState()} />
            </Modal.Footer>
            <DatePickerModal open={openDate} setOpen={setOpenDate} date={date} setDate={setDate} />
        </StyledModal>
    )
}