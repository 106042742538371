import React, { useState } from 'react';
import { styled } from '@nextui-org/react';
import theme from '../../theme';
import { Helmet } from "react-helmet";

// Components
import PageTitle from '../../components/PageTitle/PageTitle'
import Card4 from '../../components/Cards/Card4/Card4';
import Card5 from '../../components/Cards/Card5/Card5';
import CarouselCards from '../../components/Cards/CarouselCards/CarouselCards';
import TutorialsData from './TutorialsData';

import calculator from '../../assets/tools/calculator.jpg';
import consulte from '../../assets/tools/consulte.jpg';

import { useTranslation } from "react-i18next";

const Tools = () => {
    const [tutorials, setTutorials] = useState(TutorialsData);

    const StyledTools = styled('div', {
        [`.${theme} &`]: {
            padding: '3rem 2rem',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            section: {
                gridColumn:'span 4',
                display: 'flex',
                flexDirection: 'column',
            },
            aside: {
                gridColumn:'span 1',
                gridRow:'span 2',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
            },
            ['.content']: {
                display: 'grid',
                gridTemplateColumns:'repeat(5, 1fr)',
                gap: '1rem'
            },
            ['.tutorials']:{
                gridColumn:'span 4',
            }
        }
    })

    return (
        <StyledTools>
            <PageTitle>
                Herramientas
            </PageTitle>

            <div className='content'>
                <section>
                    <Card4 title={'Tráfico marítimo'}>
                        <iframe
                            src="https://www.marinetraffic.com/en/ais/embed/zoom:4/centerx:-66.8846/centery:3.4242maptype:0/shownames:false/mmsi:0/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:false/remember:false"
                            title="MarineTraffic Map"
                            allowFullScreen
                        ></iframe>
                    </Card4>
                </section>
                <aside>
                    <Card5 src="calculator" title={'Calculadora de peso'} image={calculator} description={'Utilice la opcion ver mas para calcular el peso de su carga.'}></Card5>
                    <Card5 src="tariff" title={'Consulta Arancelaria'} image={consulte} description={'Utilice la opcion ver mas para calcular los aranceles de su carga.'}></Card5>
                </aside>
                <div className='tutorials'>
                    {/* <PageTitle>
                        Tutoriales
                    </PageTitle> */}
                    <CarouselCards data={tutorials}></CarouselCards>
                </div>
            </div>
        </StyledTools>
    );
}

export default Tools;
