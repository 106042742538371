import React, { useState, useEffect, useRef } from "react";
import { styled, Loading } from "@nextui-org/react";
import PageTitle from "../../components/PageTitle/PageTitle";
import useDraggableScroll from "use-draggable-scroll";
import styles from "./Home.module.scss";
import { useSelector } from "react-redux";
import { ENABLE_LOGS } from "../../constants";
import { useTranslation } from "react-i18next";
import { startOfMonth, format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { usePDF } from "react-to-pdf";
import { useDispatch } from "react-redux";
// Components
import CardTop from "../../components/CardTop/CardTop";
import Card from "../../components/Cards/Card/Card";
import CO2 from "../../components/Graphics/CO2/CO2";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import SimpleBarChart from "../../components/Graphics/ActivitiesBarChart/BarChart";
import HorizontalBarChart from "../../components/Graphics/HorizontalChart/HorizontalChart";
import PieRechart from "../../components/Graphics/PieChart/PieChart";
import HomeConfig from "../../components/HomeConfig/HomeConfig";
import DatesRangePickerModal from "../../components/DateRangePicker/DateRangePicker";
import { Modal } from "@mui/material";
import LoadingBar from "react-top-loading-bar";
import { onBoardingSteps } from "./homeConstants";
//services
import { getStatisticsSplitDated } from "../../services/home.services";
import { getBusinessTermsByUser, getIncotermsByUser} from "../../services/businessTerms.services";
import { GetShipmentsByCountryOrigin } from "../../services/countries.services";
import { GetShipmentsByCountryDestination } from "../../services/countries.services";
import { getShipmentsByShippingMethod, getShippingAnalysisByMethod, getUserShippingMethodRatio, getShippingMethodRatio} from "../../services/shippingMethods.services";
import { getWeightTrendByUser, getWeightTrendByShippingMethod} from "../../services/weight.services";

//User Tracking
import Tracker from "@openreplay/tracker/cjs";
import { getUserAuth } from "../../services/auth.services";
const tracker = new Tracker({projectKey: "QUQx75VyCHiMfj9WpnB4"});
try {
} catch (error) {
  if (error.message.includes("doNotTrack") || error.message.includes("incognito")) {
    console.warn("Please disable doNotTrack or use a regular browsing window for full tracking functionality.");
  } else {
    console.error("An unexpected error occurred:", error);
  }
}
import * as Sentry from "@sentry/react";
import OnBoarding from "../../components/onBoarding/onBoarding";
import useMediaQueries from "../../hooks/useMediaQueries";
import ColoredPieChart from "../../components/Graphics/ColoredPieChart";
import DashboardHorizontalBarChart from "../../components/Graphics/HorizontalChart/DashboardHorizontalChart";
import UltraHorizontalBarChart from "../../components/Graphics/HorizontalChart/UltraHorizontalBarChart";
import Co2Chart from "../../components/Graphics/CO2/Co2Chart";

if (ENABLE_LOGS !== "on") {
  console.log("sentry enabled");
  Sentry.init({
    dsn: "https://82588c9fa13ffce8c0d8360e890a60a0@o4506419815645184.ingest.sentry.io/4506419817480192",
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/yourserver\.io\/api/,
        ],
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

const StyledHome = styled("section", {
  backgroundColor: "$primary_50",
  height: "100%",
  padding: "3rem 2rem",
  "@media (max-width: 768px)": {
    gridTemplateColumns: "100%",
  },
  gap: "1.5rem",
  ".title": {
    display: "flex",
    gridColumn: "span 2",
    marginBottom: "2rem",
  },
  ".vignette": {
    width: "20px",
    backgroundColor: "$secondary_300",
    marginRight: "1rem",
    borderRadius: "10px",
  },
  h2: {
    marginBottom: "0px",
  },
  ".dateFilter div": {
    display: "flex",
  },
  ".dateFilter span": {
    fontWeight: "600",
    color: "$primary_500",
  },
  ".dateFilter > div": {
    gap: "1rem",
  },
});

const StyledCardDeck = styled("div", {
  display: "flex",
  gap: "1rem",
  overflowX: "scroll",
  width: "100%",
  paddingBottom: "4px",
  gridColumn: "span 2",
  "@media (max-width: 768px)": {
    gridColumn: "span 1",
  },
});
const ChartsContainer = styled("div",{
  display:'grid',
  margin:'20px 0',
  gap:'1rem',
  width:'100%',
  gridTemplateColumns:'auto auto auto auto',
  "@media (max-width: 1200px)": {
    gridTemplateColumns:'50% 50%',
  },
  "@media (max-width: 768px)": {
    display:'flex',
    flexDirection:'column',
    gap:'10px'
  },
})
const ChartContainer = styled("div", {
  background: 'white',
  borderRadius: '8px',
  padding: '20px',
  width: '100%',
  boxShadow:'$elevation_1',
  minHeight:'500px',
  maxHeight:'600px',
  'span':{margin: '15px', color: 'grey'},
  "@media (max-width: 768px)": {
    minHeight: "0",
  },
})

const colors = ['#3D509A', '#EB9D3A ', '#81D393','#5b658d']

function Home() {
  const currentPath = localStorage.getItem("currentPath");
  const dispatch = useDispatch();

  const { md, lg } = useMediaQueries();
  const navigate = useNavigate();
  const { t: translate } = useTranslation("Home");
  const { i18n } = useTranslation();

  const token = useSelector((state) => state.user.token);
  const ref = useRef("div");
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });
  const [dates, setDates] = useState({
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  });
  const [configOpen, setConfigOpen] = useState(false);
  const [openDates, setOpenDates] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [kpis, setKpis] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: "admin_dashboard.pdf" });
  const [loadingtop, SetLoadingtop] = useState(0);

  console.log('kpis :',kpis)

  const getKpis = async () => {
    try {
      SetLoadingtop(0);
      const dateFormat = "yyyy-MM-dd";
      const startDate = format(dates.startDate, dateFormat);
      const endDate = format(dates.endDate, dateFormat);
  
      const asyncTasks = [
        getBusinessTermsByUser(token, startDate, endDate),
        getIncotermsByUser(token, startDate, endDate),
        GetShipmentsByCountryOrigin(token, startDate, endDate),
        GetShipmentsByCountryDestination(token, startDate, endDate),
        getShippingAnalysisByMethod(token, startDate, endDate),
        getShipmentsByShippingMethod(token, startDate, endDate),
        getUserShippingMethodRatio(token, startDate, endDate),
        getShippingMethodRatio(token, startDate, endDate),
        getWeightTrendByUser(token, startDate, endDate),
        getWeightTrendByShippingMethod(token, startDate, endDate),
        getStatisticsSplitDated(token, startDate, endDate)
      ];
      const kpiResponses = await Promise.all(asyncTasks);
      const keys = [
        "businessTermsByUser",
        "incotermsByUser",
        "shipmentsByCountryOrigin",
        "shipmentsByCountryDestination",
        "shippingAnalysisByMethod",
        "shipmentsByShippingMethod",
        "userShippingMethodRatio",
        "shippingMethodRatio",
        "weightTrendByUser",
        "weightTrendByShippingMethod",
        "statistics"
      ];
      const updatedKpis = {};
      await Promise.all(
        kpiResponses.map(async (res, index) => {
          const key = keys[index];
          updatedKpis[key] = await res.json();
        })
      );
  
      setKpis(updatedKpis); 
      setLoading(false);
      setError(false); 
      SetLoadingtop(100); 
    } catch (error) {
      setLoading(false); 
      setError(error.message); 
      console.error(`Error fetching data: ${error.message}`);
    }
  };
  

  useEffect(() => {
    getKpis();
    if (currentPath) {
      localStorage.removeItem("currentPath");
      navigate(currentPath);
    }
    getUserAuth(token)
      .then(({ user }) => {
        const userAuth = {
          email: user.email,
        };
        const userAtributes = {
          user_language: user.language,
          user_currency: user.currency,
          user_timezone: user.timezone,
        };
        console.log(userAtributes);
        switch (userAtributes.user_language) {
          case "es_CO":
            i18n.changeLanguage("es");
            break;
          case "en_US":
            i18n.changeLanguage("en_US");
            break;
          default:
            i18n.changeLanguage("es");
            break;
        }
        if (ENABLE_LOGS !== "on") {
          tracker.start({
            userID: userAuth.email,
            metadata: {
              plan: "free",
              rol: "Asesor",
            },
          });
        }
        if (user.first_name === "guest") {
          navigate("/services");
        }
      })
      .catch((error) => {
        localStorage.removeItem("storage");
        dispatch({ type: "LOG_OUT" });
        console.error(
          "Hubo un error al obtener la autenticación del usuario:",
          error
        );
      });
  }, [dates]);

  if (loading) {
    return (
      <div>
        <Loading></Loading>
      </div>
    );
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const formatNumber = (value) => {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toLocaleString(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 2,
      });
      return `${formattedValue}K`;
    } else {
      return value?.toString();
    }
  };

  const formatKpiCards = (stat, index, suffix = '') => 
    kpis?.statistics?.[stat] ? formatNumber(kpis.statistics[stat][index].toFixed(2)) + suffix : '0';
  
  const cardsData = [
    { icon: "Cube", value: formatKpiCards('kilosMoved', 0), text: translate("kg-moved"), percentage: formatKpiCards('kilosMoved', 1, '%') },
    { icon: "Expand", value: formatKpiCards('cubicMeters', 0), text: translate("cubic-meters"), percentage: formatKpiCards('cubicMeters', 1, '%') },
    { icon: "Terrestre", value: formatKpiCards('teus', 0), text: translate("teus"), percentage: formatKpiCards('teus', 1, '%') },
    { icon: "Cotizaciones", value: formatKpiCards('quoteCount', 0), text: translate("quotations"), percentage: formatKpiCards('quoteCount', 1, '%') },
    { icon: "Servicios", value: formatKpiCards('servicesCount', 0), text: translate("services"), percentage: formatKpiCards('servicesCount', 1, '%') },
    { icon: "MoneyBag", value: `USD $${formatKpiCards('totalCost', 0)}`, text: translate("spend"), percentage: formatKpiCards('totalCost', 1, '%') },
  ];

  const getValues = (stat, type) => kpis?.statistics?.[stat]?.[type] && Object.values(kpis.statistics[stat][type]);

  const topCardsAir = {origenes: getValues('origins', 'air'),destinos: getValues('destinations', 'air')};
  const topCardsOcean = {origenes: getValues('origins', 'ocean'),destinos: getValues('destinations', 'ocean')};
  const topCardsGround = {origenes: getValues('origins', 'ground'),destinos: getValues('destinations', 'ground')};
  const topInTransitCards = {origenes: getValues('origins', 'operation'),destinos: getValues('destinations', 'operation')};

  function formatKpi(data, dataKey="value"){
    const formated = Object.keys(data).map(key=>{
        return {
          label:key,
          name:key,
          [dataKey]:data[key],
        }
    })
    return formated;
  }
  const incotermsByUser =  kpis?.incotermsByUser && formatKpi(kpis.incotermsByUser, "value")
  const shipmentsByShippingMethod =  kpis?.shipmentsByShippingMethod && formatKpi(kpis.shipmentsByShippingMethod, "value")
  const shipmentsByCountryOrigin =  kpis?.shipmentsByCountryOrigin && formatKpi(kpis.shipmentsByCountryOrigin, "value")
  const shipmentsByCountryDestination =  kpis?.shipmentsByCountryDestination && formatKpi(kpis.shipmentsByCountryDestination, "value")
  const shipmentsByCountryOriginBar =  kpis?.shipmentsByCountryOrigin && formatKpi(kpis.shipmentsByCountryOrigin, "quotations")?.sort((a,b)=>b.quotations - a.quotations)?.slice(0,15)
  const shipmentsByCountryDestinationBar =  kpis?.shipmentsByCountryDestination && formatKpi(kpis.shipmentsByCountryDestination, "quotations")?.sort((a,b)=>b.quotations - a.quotations)?.slice(0,15)
  const weightByShippingMethod =  kpis?.weightTrendByShippingMethod && formatKpi(kpis.weightTrendByShippingMethod, "weight")
  
  function formatCostStatistics(data) {
    return [
      {
        name: "",
        Aereo: data?.air,
        Maritimo: data?.ocean,
        Terrestre: data?.ground,
        Air: data?.air,
        Ocean: data?.ocean,
        Land: data?.ground,
      },
    ];
  }

  function downloadPDF() {
    setLoadingPdf(true);
    toPDF().then(() => {
        setLoadingPdf(false);
    }).catch((err) => {
      console.log("error al descargar pdf :", err);
      setLoadingPdf(false);
    });
  }

  return (
    <StyledHome ref={targetRef}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <LoadingBar
          color="#3D509A"
          progress={loadingtop}
          onLoaderFinished={() => SetLoadingtop(0)}
        />
      </div>
      <OnBoarding steps={onBoardingSteps} />
      <div className="pageTitle" style={{ width: "fit-content" }}>
        <PageTitle>{translate("title")}</PageTitle>
      </div>
      <div
        className={styles.dateclass}
        style={{ gridColumn: md ? "span 2" : "span 1" }}
      >
        <div className="dateFilter">
          <span>{translate("dates")}</span>
          <div
            className="datePicker"
            onClick={() => {setOpenDates(true)}}
            style={{ display: "flex", gap: "20px", width: "fit-content" }}
          >
            <Input label={translate("from")} value={format(dates.startDate, "dd/MM/yyyy")}/>
            <Input label={translate("to")} value={format(dates.endDate, "dd/MM/yyyy")}/>
          </div>
          <DatesRangePickerModal
            aria-labelledby="modal-date-range"
            setDates={setDates}
            open={openDates}
            setOpen={setOpenDates}
          />
        </div>
        {md && (
          <div className={styles.btnContainer}>
            <div className="download">
              <Button
                handler={() => downloadPDF()}
                text={loadingPdf ? "Cargando..." : translate("download-button")}
                endIcon={{ name: "Download" }}
                outline
                disabled={loadingPdf}
              />
            </div>
            <Button
              text={translate("config")}
              endIcon={{ name: "Setting" }}
              outline
              handler={() => {
                configOpen ? setConfigOpen(false) : setConfigOpen(true);
              }}
            ></Button>
            <Modal
              open={configOpen}
              onClose={() => setConfigOpen(false)}
              aria-labelledby="modal-config"
            >
              <HomeConfig setConfigOpen={setConfigOpen} />
            </Modal>
          </div>
        )}
      </div>
      <StyledCardDeck
        className={styles.customTrack}
        ref={ref}
        onMouseDown={onMouseDown}
      >
        {cardsData.map((card) => (
          <Card key={card.text} {...card} />
        ))}
      </StyledCardDeck>
      <div
        className={styles.charts}
        style={{ gridColumn: md ? "span 2" : "span 1" }}
      >
      </div>
      <ChartsContainer>
        <ChartContainer style={{gridColumn: lg ? "span 3" : "span 2"}}>
          <SimpleBarChart
            data={
              kpis.statistics &&
              formatCostStatistics(kpis.statistics.totalCostType)
            }
            title={translate("cost-by-shipment-method")}
            icon={"MoneyBag"}
          />
        </ChartContainer>
        <ChartContainer>
            <span>Huella de carbono</span>
            <div style={{height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
             <Co2Chart value={kpis.statistics.co2 || 0} max={1000}/>
            </div>
        </ChartContainer>
        <ChartContainer style={{gridColumn:'span 2'}}>
            <span>Envios por pais de origen</span>
            <DashboardHorizontalBarChart color={'#3D509A'} dataKey={"quotations"} data={shipmentsByCountryOriginBar} marginLeft={50}/>
        </ChartContainer>
        <ChartContainer>
          <span>Incoterms</span>
          <ColoredPieChart colors={colors} radius={80} legend={true} data={incotermsByUser}/>  
        </ChartContainer>
        <ChartContainer>
          <span>Envios por metodo de envio</span>
          <ColoredPieChart colors={colors} radius={80} legend={true} data={shipmentsByShippingMethod}/>  
        </ChartContainer>
        {/* <ChartContainer>
          <ColoredPieChart colors={colors} radius={60} legend={true} data={shipmentsByCountryDestination}/>  
        </ChartContainer>
        <ChartContainer>
          <ColoredPieChart colors={colors} radius={60} legend={true} data={shipmentsByCountryOrigin}/>  
        </ChartContainer> */}
        <ChartContainer style={{gridColumn:'span 2'}}>
            <span>Envios por pais de destino</span>
            <DashboardHorizontalBarChart color={'#EB9D3A'} dataKey={"quotations"} data={shipmentsByCountryDestinationBar} marginLeft={50}/>
        </ChartContainer>
        <ChartContainer style={{gridColumn:'span 2'}}>
            <span>Peso por metodo de envio</span>
            <UltraHorizontalBarChart
              data={weightByShippingMethod}
              barDataKeys={[{ key:'weight', color: '#3D509A' }]}
              lineDataKeys={[]}
            />
        </ChartContainer>
        <CardTop title={translate("top-air")} objects={topCardsAir} icon="Aereo"/>
        <CardTop title={translate("top-ocean")} objects={topCardsOcean} icon="Maritimo"/>
        <CardTop title={translate("transit-in-operation")} objects={topInTransitCards}/>
        <CardTop title={translate("top-land")} objects={topCardsGround} icon="Terrestre"/>
      </ChartsContainer>
    </StyledHome>
  );
}

export default Home;
