import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, IconButton, List, ListItem, Collapse, Select, MenuItem, InputAdornment } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { URL } from "../../../constants";
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

function ItemModal(props) {
    const { titleText, buttonText, close, id, endpoint, methodType, headers } = props;
    const [items, setItems] = useState([
        {
            item: 'Gastos en Origen',
            valor: '',
            subitems: []
        },
        {
            item: 'Flete Internacional',
            valor: '',
            subitems: []
        },
        {
            item: 'Gastos en Destino',
            valor: '',
            subitems: []
        }
    ]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [transportTimes, setTransportTimes] = useState({
        portToPort: '',
        doorToPort: '',
        portToDoor: ''
    });

    // Agrega un estado para la moneda de la cotización y el shipper
    const [currency, setCurrency] = useState('USD');

    const [shipper, setShipper] = useState('');

    // Agrega un estado para controlar si el panel de tiempos de transporte está abierto o cerrado
    const [transportTimesOpen, setTransportTimesOpen] = useState(false);

    const addItem = (itemIndex) => {
        setSelectedItems([...selectedItems, items[itemIndex]]);
    };


    const handleSubmit = (event) => {
        event.preventDefault();

        // Comprueba si hay algún costo asignado
        const isAnyCostAssigned = items.some(item => item.valor !== '' || item.subitems.some(subitem => subitem.subvalor !== ''));

        if (!isAnyCostAssigned) {
            alert('Por favor, asigna al menos un costo antes de enviar.');
            return;
        }

        let dataToSend = {
            serviceId: id,
            data: items.map((item) => ({
                item: item.item,
                valor: item.valor,
                subitems: item.subitems
            }))
        };

        fetch(`${URL}${endpoint}`, {
            method: methodType,
            headers: headers,
            body: JSON.stringify(dataToSend)
        })
            .then(response => response.json())
            .then(response => toast('success', response.message));

        close();
    };


    const addSubitem = (itemIndex) => {
        const updatedItems = [...items];
        updatedItems[itemIndex].subitems.push({ subitem: '', subvalor: '' });
        setItems(updatedItems);
    };

    const deleteSubitem = (itemIndex, subitemIndex) => {
        const updatedItems = [...items];
        updatedItems[itemIndex].subitems.splice(subitemIndex, 1);
        setItems(updatedItems);
    };


    const handleSubitemChange = (event, itemIndex, subitemIndex) => {
        const updatedItems = [...items];
        updatedItems[itemIndex].subitems[subitemIndex].subitem = event.target.value;
        setItems(updatedItems);
    };

    const handleSubvalorChange = (event, itemIndex, subitemIndex) => {
        const updatedItems = [...items];
        updatedItems[itemIndex].subitems[subitemIndex].subvalor = event.target.value;

        // Calculamos la suma de los valores de los subítems
        let subitemsSum = updatedItems[itemIndex].subitems.reduce((total, subitem) => {
            // Asegúrate de convertir los subvalores a números antes de sumar
            return total + Number(subitem.subvalor);
        }, 0);

        // Y asignamos esa suma al valor del ítem
        updatedItems[itemIndex].valor = subitemsSum;

        setItems(updatedItems);
    };

    return (
        <Dialog open={true} onClose={close} fullWidth maxWidth="md">
            <DialogTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {titleText}
                    <IconButton edge="end" color="inherit" onClick={close} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers>
                <form onSubmit={handleSubmit}>
                    {/* Selectores para la moneda y el shipper */}
                    <Select
                        value={currency}
                        onChange={(e) => setCurrency(e.target.value)}
                        style={{ maxWidth: '100px' }}
                        startAdornment={
                            <InputAdornment position="start">
                                <AttachMoneyIcon />
                            </InputAdornment>
                        }
                    >
                        <MenuItem value={'USD'}>USD</MenuItem>
                        <MenuItem value={'COP'}>COP</MenuItem>
                        <MenuItem value={'MXN'}>MXN</MenuItem>
                        <MenuItem value={'EUR'}>EUR</MenuItem>
                    </Select>
                    {/* <Select
                        value={shipper}
                        onChange={(e) => setShipper(e.target.value)}
                        fullWidth
                        startAdornment={
                            <InputAdornment position="start">
                                <LocalShippingIcon />
                            </InputAdornment>
                        }
                    >
                        <MenuItem value={'Empresa 1'}>Empresa 1</MenuItem>
                        <MenuItem value={'Empresa 2'}>Empresa 2</MenuItem>
                        <MenuItem value={'Empresa 3'}>Empresa 3</MenuItem>
                        <MenuItem value={'Empresa 4'}>Empresa 4</MenuItem>
                        <MenuItem value={'Empresa 5'}>Empresa 5</MenuItem>
                    </Select> */}
                    {/* Lista de ítems */}
                    <List>
                        {items.map((item, itemIndex) => (
                            <>
                                {/* Título del ítem */}
                                <ListItem key={itemIndex}>
                                    <h3>{item.item}</h3>
                                    {/* Botón para agregar un subítem */}
                                    <Button variant="contained" color="primary" onClick={() => addSubitem(itemIndex)} style={{ marginLeft: '20px' }}>
                                        Añadir
                                    </Button>
                                </ListItem>
                                {/* Lista de subítems */}
                                {item.subitems.map((subitem, subitemIndex) => (
                                    <ListItem key={`${itemIndex}-${subitemIndex}`}>
                                        {/* Campos de texto para el subítem */}
                                        <TextField
                                            style={{ maxWidth: '200px' }}
                                            label="Gasto"
                                            value={subitem.subitem}
                                            onChange={(e) => handleSubitemChange(e, itemIndex, subitemIndex)}
                                        />
                                        <TextField
                                            style={{ maxWidth: '100px' }}
                                            label="Valor"
                                            value={subitem.subvalor}
                                            onChange={(e) => handleSubvalorChange(e, itemIndex, subitemIndex)}
                                        />
                                        {/* Icono para eliminar el subítem */}
                                        <IconButton edge="end" color="inherit" onClick={() => deleteSubitem(itemIndex, subitemIndex)} aria-label="delete">
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                ))}
                                {/* Total del ítem */}
                                <ListItem>
                                    Total: {item.valor}
                                </ListItem>
                            </>
                        ))}
                    </List>
                    {/* Total de todos los ítems */}
                    Total: {items.reduce((total, item) => total + Number(item.valor), 0)}
                    {/* Panel para los tiempos de transporte */}
                    <ListItem button onClick={() => setTransportTimesOpen(!transportTimesOpen)}>
                        Tiempos de Transporte
                        <ExpandMoreIcon />
                    </ListItem>
                    <Collapse in={transportTimesOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {/* Campos de texto para los tiempos de transporte */}
                            <ListItem>
                                <TextField
                                    style={{ maxWidth: '70px' }}
                                    label="P2P"
                                    value={transportTimes.portToPort}
                                    onChange={(e) => setTransportTimes({ ...transportTimes, portToPort: e.target.value })}
                                    inputProps={{ maxLength: 2 }}
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    style={{ maxWidth: '70px' }}
                                    label="D2P"
                                    value={transportTimes.doorToPort}
                                    onChange={(e) => setTransportTimes({ ...transportTimes, doorToPort: e.target.value })}
                                    inputProps={{ maxLength: 2 }}
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    style={{ maxWidth: '70px' }}
                                    label="P2D"
                                    value={transportTimes.portToDoor}
                                    onChange={(e) => setTransportTimes({ ...transportTimes, portToDoor: e.target.value })}
                                    inputProps={{ maxLength: 2 }}
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                    {/* Botón para enviar el formulario */}
                    <Button variant="contained" color="primary" type="submit">
                        {buttonText}
                    </Button>
                </form>
            </DialogContent>
        </Dialog>
    );
}
export default ItemModal;