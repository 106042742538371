import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import { useSearchParams } from "react-router-dom";
import Icon from "../../../components/Icon/Icon";
import { getShipmentTracking } from "../../../services/cargoes.services";
import SimpleMap from "../../../components/Map/Map";
import { Tooltip } from "@nextui-org/react";
import { useSelector } from "react-redux";
import { Modal } from "@nextui-org/react";
import { useNavigate } from "react-router-dom"
import Button from "../../../components/Button/Button";
import AirPlane from "../../../components/Graphics/AirPlane/AirPlane";
import { Loading } from '@nextui-org/react';
import { differenceInDays, differenceInHours, format, formatDistanceToNow, parse } from "date-fns";
import BasicTimeline from "./Timeline";
import Table from "../../../components/Table/Table";
import { getAirports, getCountriesNew } from "../../../services/countries.services";
import ReactCountryFlag from "react-country-flag";

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  background: "$neutral_50",
  alignItems: "center",
  gap: "20px",
  width: "95%",
  margin: "3rem auto",
  padding: "20px 40px",
  borderRadius: "8px",
  ".title": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: "$primary_300",
    marginBottom: "20px",
    borderBottom: "3px solid $primary_300",
  },
});
const DataContainer = styled("div", {
  display: "grid",
  width: "100%",
  gridTemplateColumns: "48% 48%",
  gap:'0 4%'
});
const InfoCard = styled("div", {
  fontSize: "15px",
  div: {
    margin: "15px 5px",
    // paddingRight:'20px',
    display: "flex",
    // borderBottom: "1px solid $neutral_300",
    justifyContent: "space-between",
    'span':{
      paddingRight:'20px',
    }
  },
});
const UpperData = styled("div", {
  marginBottom: "20px",
  display: "flex",
  gridColumn: "span 2",
  justifyContent: "space-around",
  width: "100%",
  div: {
    display: "flex",
    gap:'10px',
    flexDirection: "column",
  },
});
const Activity = styled("div",{
  display:'flex',
  justifyContent:'space-between',
  borderBottom:'1px solid $neutral_300',
  marginBottom:'4px',
  'span':{
    marginBottom:'3px',
    fontSize:'12px',
  }
})
const StyledModal = styled(Modal, {
  color: "$primary_500 !important",
  paddingTop:'20px',
  display:'flex',
  flexDirection:'column',
});

const columns = [
  {name:'Transport name', uid:'transport', type:'text'},
  {name:'Origin Airport', uid:'origin', type:'text'},
  {name:'Destination Airport', uid:'destination', type:'text'},
  {name:'Flight #', uid:'flight_ref', type:'text'},
  {name:'Departure', uid:'departure', type:'text'},
  {name:'Arrival', uid:'arrival', type:'text'},
]

export default function TrackingDetailsAir() {
  const navigate = useNavigate()
  const token = useSelector((state) => state.user.token);
  const [searchParams] = useSearchParams();
  const [shipment, setShipment] = useState({});
  const [shipping, setShipping] = useState({});
  const [items, setItems] = useState();
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [noShipment, setNoShipment] = useState(false)
  const [lastUpdated, setLastUpdated] = useState('Cargando...')
  const [flags, setFlags] = useState()
  console.log("flags :",flags)
  let radication = searchParams.get("radication");
  
  useEffect(() => {
    setLoading(true);
    getShipmentTracking(token, radication, 'AIR_SHIPMENT').then(async (trackingRes) => {
      const shipmentData = await trackingRes.json();
      if (shipmentData.length > 0) {
        setShipment(shipmentData[0]);
        setShipping(shipmentData[0]?.shipmentLegs?.portToPort)
        const segments = shipmentData[0]?.shipmentLegs?.portToPort?.segments?.map((item,i)=>{
          return {
            id:item.tripNumber || i,
            transport: item.transportName,
            origin: item.origin,
            destination: item.destination,
            flight_ref: item.tripNumber,
            departure: item.atd || item.etd,
            arrival: item.ata || item.eta,
          }
        })
        setItems(segments)
        if (shipmentData[0]?.trackingUpdatedAt){
          const lastUpdatedDate = new Date(shipmentData[0]?.trackingUpdatedAt);
          const lastUpdateToNow = formatDistanceToNow(lastUpdatedDate);
          setLastUpdated(lastUpdateToNow)
        } else {setLastUpdated("")}
      } else {
        setShipment("");
        setOpen(true)
        setLoading(false);
      }
      setLoading(false);
    }).catch((err) => {
      setOpen(true);
      setLoading(false);
    });
  }, []);

  useEffect(()=>{
    if (shipping){
      const asyncTasks = [getAirports(token),getCountriesNew(token)]
      Promise.all(asyncTasks).then(async res => {
        const airportsData = await res[0].json();
        const countriesData = await res[1].json();
  
        const originAirport = airportsData.find(airport => airport.iata_code == shipping.originPortCode)
        const destinationAirport = airportsData.find(airport => airport.iata_code == shipping.destinationPortCode) 

        const originCountry = countriesData.find(country => country.name == originAirport?.country)
        const destinationCountry = countriesData.find(country => country.name == destinationAirport?.country)
        
        setFlags({
          origin:originCountry,
          destination:destinationCountry,
        })
      })
    }
  },[shipping])

  useEffect(()=>{
    if (noShipment){
      navigate("/services")
    }
  },[noShipment])

  const events = shipment?.shipmentEvents;
  const locations = events?.map((event) => event.location).reverse();
  const uniqueLocations = [...new Set(locations)];

  const activities = uniqueLocations.map((location,i) => {
    const locationEvents = events.filter((e) => e.location === location)?.reverse();
    const nextLocationEvents = events.filter((e) => e.location === uniqueLocations[i-1])?.reverse();
    return {
      title:location,
      content: locationEvents.map((e, eventIndex) => {
        const eventDateString = e.actualTime
        const nextEventDateString = locationEvents[eventIndex-1]?.actualTime ||
        nextLocationEvents[nextLocationEvents.length - 1]?.actualTime

        const eventDate = parse(eventDateString, "yyyy-MM-dd h:mm:ss a", new Date());
        const nextEventDate = parse(nextEventDateString, "yyyy-MM-dd h:mm:ss a", new Date())

        let durationDays = differenceInDays(nextEventDate, eventDate)
        let durationHours = differenceInHours(nextEventDate, eventDate)
        return(
        <Activity key={eventIndex}>
          <span style={{width:'30%'}}>{e.name}</span>
          <span style={{width:'30%'}}>{ eventDateString ? format(eventDate, 'dd/MM/yyyy , HH:mm') + ' Hs' : "" }</span>
          <span style={{width:'10%'}}>{nextEventDateString ? durationDays? `${durationDays} Dias` : `${durationHours} Hs` : ""}</span>
        </Activity>)
      }) 
    }
  })

  return (
    <div>
      {loading?
      <div style={{height:'80vh', background:'white', borderRadius:'8px', display:'flex', justifyContent:'center', alignItems:'center'}}>
        <Loading size="lg"/>
      </div>:
      shipment && <Container>
      <div className="title">
        <h2>{radication}</h2>
        {/* <Button text={"ATRAS"} handler={()=>navigate("/services")}/> */}
        <Tooltip content="Maritimo">
          <Icon name="Aereo" size="40px" />
        </Tooltip>
      </div>
      <UpperData>
        <div>
          <b>AWB No.</b>
          <span>
            {shipment?.awbNumber}
          </span>
        </div>
        <div>
          <b>Airline</b>
          <span>{shipping?.carrier}</span>
        </div>
        <div>
          <b>Status</b>
          <span>{shipment?.subStatus1}</span>
          <span>{shipment?.subStatus2}</span>
        </div>
        <div>
          <b>Weight</b>
          <span>{shipment?.totalWeight} {shipment?.totalWeightUom}</span>
        </div>
        <div>
          <b>Pieces</b>
          <span>{shipment?.totalNumberOfPackages}</span>
        </div>
        <div>
          <b>Shipment ref</b>
          <span>{shipment?.shipmentNumber}</span>
        </div>
      </UpperData>
      <DataContainer>
        <div className="title" style={{gridColumn:'span 2'}}>
          <h3>Tracking</h3>
          <Icon name="Tracking" size="32px" />
        </div>
        <InfoCard>
          <div>
            <b>Shipper : </b>
            <span>{shipment?.shipper}</span>
          </div>
          <div>
            <b>Origin Airport : </b>
            <div style={{display:'flex', margin:"0"}}>
              <span>{shipping?.originPortCode}</span>
              <Icon name="Airport" size="30"/>
            </div>
          </div>
          <div>
            <b>Origin: </b>
            <div style={{display:'flex', margin:"0", height:'1.5rem', alignItems:'center', justifyContent:'end'}}>
              <span>{shipping?.originPort}</span>
              <Tooltip content={flags?.origin?.name} color="primary">
                <ReactCountryFlag
                  countryCode={flags?.origin?.code}
                  svg
                  style={{
                    width: '1.8rem',
                    height: '1.5rem',
                    borderRadius:'6px',
                    margin:0,
                    padding:0,
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div>
            <b>Origin ETD : </b>
            {shipping?.originPortEtd && <span>{ format(new Date(shipping?.originPortEtd), 'dd/MM/yyyy HH:mm')}</span>}
          </div>
        </InfoCard>
        <InfoCard>
          <div>
            <b>Consignee : </b>
            <span>{shipment?.consignee}</span>
          </div>
          <div>
            <b>Destination Airport : </b>
            <div style={{display:'flex', margin:"0"}}>
              <span>{shipping?.destinationPortCode}</span>
              <Icon name="Airport" size="30"/>
            </div>
          </div>
          <div>
            <b>Destination : </b>
            <div style={{display:'flex', margin:"0", height:'1.5rem', alignItems:'center'}}>
              <span>{shipping?.destinationPort}</span>
              <Tooltip content={flags?.destination?.name} color="primary">
                <ReactCountryFlag
                  countryCode={flags?.destination?.code}
                  svg
                  style={{
                    width: '1.8rem',
                    height: '1.5rem',
                    borderRadius:'6px',
                    margin:0,
                    padding:0,
                  }}
                />
              </Tooltip>
            </div>
            
          </div>
          <div>
            <b>Destination ETA : </b>
            {shipping?.destinationPortEta && <span>{ format(new Date(shipping?.destinationPortEta), 'dd/MM/yyyy HH:mm')}</span>}
          </div>       
        </InfoCard>
        {items && <div style={{gridColumn:'span 2'}}><Table columns={columns} items={items} searchBar={false} excel={false}/></div>}
        <h4>Last updated : {lastUpdated}</h4>
        <div style={{ gridRow: "span 3", marginTop: "15px", gridColumn:'span 2' }}>
          {shipment?.shipmentLegs && <SimpleMap coordinatesArray = {[
            {
              name:shipping?.originPort,
              lat:shipping?.loadingPortCoordinates?.latitude || shipping?.originPortCoordinates?.latitude,
              lng:shipping?.loadingPortCoordinates?.longitude || shipping?.originPortCoordinates?.longitude,
            },
            {
              name:shipping?.destinationPort,
              lat:shipping?.dischargePortCoordinates?.latitude || shipping?.destinationPortCoordinates?.latitude,
              lng:shipping?.dischargePortCoordinates?.longitude || shipping?.destinationPortCoordinates?.longitude,
            },
          ]}/>}
        </div>
      </DataContainer>
      <BasicTimeline items={activities}/>
    </Container>}
      <StyledModal
        open={open}
        onClose={()=>{setNoShipment(true)}}
        width='700px'  
        placement="left"
      >
        <h3>Los resultados que solicitaste están en camino</h3>
        <span>ya sea por mar o por aire, te avisaremos en cuanto lleguen!</span>
        <div style={{height:'100px', margin:'20px 0'}}>
          <AirPlane/>
        </div>
        <Modal.Footer>
          <Button text="Aceptar" handler={() => setOpen(false)} startIcon={{ name: 'CheckModal', color: 'white' }} bgColor='excel' textColor='neutral_50' />
        </Modal.Footer>
      </StyledModal>
    </div>
  );
}
