import { styled } from '@nextui-org/react';
import TextEditor from '../../../components/TextEditor/TextEditor';
import Button from '../../../components/Button/Button'
import InputSelect from '../../../components/Select/inputSelect';
import InputFileCard from '../../../components/InputFileCard/InputFileCard';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getQuoteData, sendInstructions } from '../../../services/quotations.services';
import toast from '../../../components/Toast/ToastTypes';
import { toBase64 } from '../../../services/base64.services';
import { AIR, EXPORTACION_MARITIMA, IMPORTACION_MARITIMA } from './templates'

const StyledDiv = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    bgColor: '$neutral_50',
    borderRadius: '8px',
    padding: '40px'
})

export default function Instructions({ currentQuote }) {

    const [HTML, setHTML] = useState('')
    const [readOnly, setReadOnly] = useState(false)
    const [radication, setRadication] = useState(false)
    const [attachedFile, setAttachedFile] = useState('')
    const token = useSelector(state => state.user.token)

    useEffect(() => {
        getQuoteData(token, currentQuote).then(async res => {
            const data = await res.json();
            setRadication(data.radication)
        }).catch(err => console.log('err :', err))
    }, [currentQuote])

    const handleFile = async (e) => {
        if (e?.target?.files.length > 0) {
            const file = await toBase64(e.target.files[0])
            setAttachedFile(file)
        }
    }

    const handleSubmit = () => {
        const submitData = {
            shipping_instructions: HTML,
            attachment: attachedFile,
            radication,
        }
        sendInstructions(submitData, token).then(res => {
            toast("success", "Instrucciones enviadas")
        }).catch(err => { console.log('err :', err); toast("error", "Hubo un error al enviar las intrucciones, es posible que ya haya instrucciones para esta cotizacion") })
    }

    return (
        <div>
            <StyledDiv>
                {/* <div style={{display:'flex', gap:'15px', margin:'20px'}}>
                    <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' handler={()=>setReadOnly(false)} disabled={!readOnly || !currentQuote}/>
                    <Button text='Enviar' bold bgColor={'primary_300'} textColor='neutral_50' handler={()=>handleSubmit()} disabled={readOnly || !currentQuote}/>
                </div> */}
                <div style={{ display: 'flex', margin: '10px' }}>
                    <label style={{ margin: '10px' }}>Plantillas :</label>
                    <InputSelect
                        isDisabled={readOnly}
                        disableClear={true}
                        onChange={(e) => setHTML(e.value)}
                        options={[
                            { label: 'Ninguno', value: '' },
                            { label: 'Importacion/exportacion aerea', value: AIR },
                            { label: "EXPORTACION_MARITIMA", value: EXPORTACION_MARITIMA },
                            { label: "IMPORTACION_MARITIMA", value: IMPORTACION_MARITIMA },
                        ]}
                    />
                </div>
                <TextEditor state={HTML} setState={setHTML} readOnly={readOnly} />
                <InputFileCard label='Sube o arrastra' onChange={handleFile} name='provider-rate' startIcon={{ name: 'Upload', color: '#FFF' }} width={'100%'} aspectRatio={'6/1'} />
                <Button text='Enviar instrucciones' size="lg" bold bgColor={'primary_300'} textColor='neutral_50' handler={() => handleSubmit()} disabled={!currentQuote} />
            </StyledDiv>
        </div>
    )
}