import { URL } from "../constants"

export const sendQuotation = async (data, token) => {
    const response = await fetch(`${URL}service/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const editQuotation = async (data, token) => {
    const response = await fetch(`${URL}service/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}
export const editQuotationByServiceSplit = async (data, token) => {
    const response = await fetch(`${URL}service-split/${data.id}/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const setServiceUser = async (token, userId, serviceId) => {
    const data = {
        user:userId,
        service:serviceId,
    }
    // console.log("data :",data)
    const response = await fetch(`${URL}userservice/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}

export const deleteService = async (token, radication) => {
    const response = await fetch(`${URL}services/radication/${radication}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    if (response.status === 204 || response.status === 200) {
        return true;
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const approveLiquidation = async (data, token) => {
    const response = await fetch(`${URL}services/approve/${data}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const getQuotation = async (data, token) => {
    const response = await fetch(`${URL}services/radication/${data}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const getQuotations = async (token) => {
    const response = await fetch(`${URL}service/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const getAllQuotations = async (token) => {
    const response = await fetch(`${URL}service/all/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const sendCodeParticipant = async (data, token) => {
    const response = await fetch(`${URL}invitation/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            email: data.email,
            radication: data.radication
        })
    })
    return response;
}

export const getServiceSplit= async (token, page, page_size, search, quotation = false) => {
    let endpoint = `${URL}service-split/?page=${page}&page_size=${page_size}`
    console.log("search :",search)
    if (search){
        endpoint = `${URL}service-split/?page=${page}&page_size=${page_size}&search=${search}`
    }
    if (quotation){
        endpoint = endpoint +'&quotation=true'
    } else {
        endpoint = endpoint +'&quotation=false'
    }
    console.log("endpoint :",endpoint)
    const response = await fetch(endpoint, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}
export const getServiceSplitRadication = async (radication, token) => {
    const response = await fetch(`${URL}service-split/?page=1&page_size=1&search=${radication}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}
export const getServiceSplitQuote = async (id, token) => {
    const response = await fetch(`${URL}service-split/${id}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

export const getPurchaseOrders = async (token) => {
    const response = await fetch(`${URL}purchaseorder/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}
export const editPurchaseOrderByRadication = async (data,radication, token) => {
    const response = await fetch(`${URL}purchase-order/${radication}/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}


export const createServiceByServiceSplit = async (data, token) => {
    const response = await fetch(`${URL}service-split/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Token " + token,
      },
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json(); 
  }


  export const SendFilesToService = async (token, radication, files) => {

    const formData = new FormData();
    formData.append("radication", radication);
   
    files.forEach((file, index) => {
        formData.append(`attachments`, file);
    });

    const headers = new Headers();
    headers.append('Authorization', 'Token ' + token);

    const response = await fetch(`${URL}send-document-bulk/`, {
        method: "POST",
        headers: headers,
        body: formData
    });

    return response;
}
