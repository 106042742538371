import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import PageTitle from "../../../../../components/PageTitle/PageTitle";
import NeedlePieChart from "../../../../../components/Graphics/NeedlePieChart/NeedlePieChart";
import { getLatLng } from "../../../../../services/map.services";
import {
  getEmissions,
  recalculateCO2,
  compensateCO2,
} from "../../../../../services/CO2.services";
import {
  getPortsByCountry,
  getAirportsByCountry,
  getCountriesNew,
  getPortsByCode,
} from "../../../../../services/countries.services";
import { getShippingMethods } from "../../../../../services/shippingMethods.services";
import { getBusinessTerms } from "../../../../../services/businessTerms.services";
import { useSelector } from "react-redux";
import toast from "../../../../../components/Toast/ToastTypes";
import { ROLES } from "../../../../../constants";
import useUserRoles from "../../../../../hooks/useUserRoles";
import { useNavigate } from "react-router-dom";
import { InitMap } from "../../../../../functions/mapFunctions";
import { useTranslation } from "react-i18next";

const StyledContainer = styled("div", {
  display: "grid",
  gridTemplateColumns: "repeat(5 , 1fr)",
  margin: "20px",
  padding: "40px",
  backgroundColor: "$neutral_50",
  borderRadius: "8px",
  boxShadow: "1px 1px 7px #3D509A",
  gap: "20px",
});

const GeneralInfo = styled("div", {
  fontSize: "13px",
  gridColumn: "span 2",
  display: "flex",
  flexDirection: "column",
});
const InfoContainer = styled("div", {
  display: "flex",
  gap: "30px",
  marginTop: "20px",
  ["div"]: {
    display: "flex",
    flexDirection: "column",
  },
  ["span"]: {
    margin: "5px",
  },
});
const MapContainer = styled("div", {
  gridColumn: "span 3",
  gridRow: "span 2",
});
const GraphContainer = styled("div", {
  gridColumn: "span 2",
  display: "flex",
  padding: "30px",
  flexDirection: "column",
  alignItems: "center",
  ["button"]: {
    padding: "10px",
    cursor: "pointer",
  },
});
const cardStyles = {
  buttonGroup: {
    marginTop: "18px",
    marginLeft: "124px",
  },
  buttonCert: {
    transform: "translate(-50%, -50%)",
    backgroundColor: "#12C909",
    background: "linear-gradient(45deg, #39509c, #5a6acc)",
    color: "#fff",
    padding: "12px 24px",
    borderRadius: "10px",
    border: "none",
    fontWeight: "bold",
    fontSize: "16px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
  },
};

export default function ServiceDetails({ service }) {
  const userRoles = useUserRoles();
  const { t: translate } = useTranslation("Services");

  const token = useSelector((state) => state.user.token);
  const havePermissions = (allowedRoles) => {
    return allowedRoles.find((role) => userRoles.includes(role));
  };
  const [emissions, setEmissions] = useState(0);
  const [shippingMethods, setShippingMethods] = useState([]);
  const [incoterms, setIncoterms] = useState([]);
  const [emissionDistance, setEmissionDistance] = useState(0);
  const navigate = useNavigate();

  console.log("service :", service);

  useEffect(() => {
    if (Object.keys(service).length > 0) {
      getEmissions(token, service.radication).then(async (res) => {
        const emissionsData = await res.json();
        const distanceInMiles = emissionsData?.distance;
        const distanceInKilometers = distanceInMiles * 1.60934;
        setEmissions(emissionsData?.co2);
        setEmissionDistance(distanceInKilometers);
      });
      switch (service.shipping.shipping_method) {
        case "ocean":
          getCountriesNew(token).then(async (res) => {
            const countriesData = await res.json();
            const originCountry = countriesData.find(
              (country) =>
                country.name?.toLowerCase() ===
                service.shipping.origin_country?.toLowerCase()
            );
            const destinationCountry = countriesData.find(
              (country) =>
                country.name?.toLowerCase() ===
                service.shipping.destination_country?.toLowerCase()
            );

            const asyncTasksOcean = [
              getPortsByCode(token, originCountry?.code),
              getPortsByCode(token, destinationCountry?.code),
            ];
            Promise.all(asyncTasksOcean).then(async (res) => {
              const originPorts = await res[0].json();
              const destinationPorts = await res[1].json();

              const originPort = originPorts.find(
                (port) =>
                  port.name === service.shipping.origin_boarding_terminal
              );
              const destinationPort = destinationPorts.find(
                (port) =>
                  port.name === service.shipping.destination_boarding_terminal
              );

              const originCoordinates = {
                lng: originPort.coordinates[0],
                lat: originPort.coordinates[1],
              };
              const destinationCoordinates = {
                lng: destinationPort.coordinates[0],
                lat: destinationPort.coordinates[1],
              };
              InitMap(
                [originCoordinates, destinationCoordinates],
                "serviceMap"
              );
            });
          });

          break;
        case "air":
          const asyncTasksAir = [
            getAirportsByCountry(token, service.shipping.origin_country),
            getAirportsByCountry(token, service.shipping.destination_country),
          ];
          Promise.all(asyncTasksAir).then(async (res) => {
            const originAirports = await res[0].json();
            const destinationAirports = await res[1].json();

            const originPort = originAirports.find(
              (port) =>
                port.name ===
                service.shipping.origin_boarding_terminal?.city?.name
            );
            const destinationPort = destinationAirports.find(
              (port) =>
                port.name ===
                service.shipping.destination_boarding_terminal?.city?.name
            );

            const originCoordinates = originPort._geoloc;
            const originCoordsData = {
              lat: originCoordinates.lat,
              lng: originCoordinates.lng,
              label: "Origen",
            };
            const destinationCoordinates = destinationPort._geoloc;
            const destinationCoordsData = {
              lat: destinationCoordinates.lat,
              lng: destinationCoordinates.lng,
              label: "Destino",
            };
            InitMap([originCoordsData, destinationCoordsData], "serviceMap");
          });
          break;
        default:
          const asyncTasks = [
            getLatLng(
              service.shipping.origin_boarding_terminal?.city?.name,
              token
            ),
            getLatLng(
              service.shipping.destination_boarding_terminal?.city?.name,
              token
            ),
          ];
          Promise.all(asyncTasks).then(async (responses) => {
            const originCoordsData = responses[0].location;
            const destinationCoordsData = responses[1].location;
            InitMap([originCoordsData, destinationCoordsData], "serviceMap");
          });
          break;
      }
    }
  }, [service]);
  useEffect(() => {
    Promise.all([getShippingMethods(token), getBusinessTerms(token)]).then(
      async (res) => {
        const shippingMethodsData = await res[0].json();
        const incotermsData = await res[1].json();

        setShippingMethods(shippingMethodsData);
        setIncoterms(incotermsData);
      }
    );
  }, []);

  let totalWeight = 0;
  service.loads.forEach((load) => {
    totalWeight += load.total_weight;
  });
  const handleRecalculate = async () => {
    try {
      await recalculateCO2(token, service.radication);
      const res = await getEmissions(token, service.radication);
      const emissionsData = await res.json();
      const distanceInMiles = emissionsData?.distance;
      const distanceInKilometers = distanceInMiles * 1.60934;
      setEmissions(emissionsData?.co2);
      setEmissionDistance(distanceInKilometers);
      toast("success", "Cálculo hecho exitosamente");
    } catch (error) {
      console.error("Error recalculating emissions:", error);
      toast("error", "Hubo un error calculando el co2");
    }
  };

  const handleCompensate = async () => {
    try {
      await compensateCO2(token, service.radication, "radication");
      navigate(`/co2/compensations?radication=${service.radication}`);
    } catch (error) {
      console.error("Error recalculating emissions:", error);
      toast("error", "Hubo un error calculando el co2");
    }
  };

  return (
    <StyledContainer>
      <GeneralInfo>
        <PageTitle>{translate("service")}</PageTitle>
        {service && (
          <InfoContainer>
            <div>
              <span>
                <strong>{translate('incoterms')}: </strong>
                {
                  incoterms?.find((term) => term.id === service?.business_term)
                    ?.name
                }
              </span>
              <span>
                <strong>{translate("operation_type")}: </strong>
                {service?.business_type}
              </span>
              <span>
                <strong>{translate("load_type")}: </strong>
                {service?.loads[0]?.load_type?.name}
              </span>
              <span>
                <strong>{translate("load_weight")}: </strong>
                {service?.loads.map((load, i) => {
                  return (
                    <p style={{ fontSize: "13px" }}>{`${i + 1}-${
                      load.name
                    }:${load.total_weight?.toFixed(2)} ${
                      load.total_weight_unit
                    }`}</p>
                  );
                })}
              </span>
            </div>
            <div>
              <span>
                <strong>{translate("origin")}: </strong>
                {service?.shipping?.origin_boarding_terminal?.city?.name}
              </span>
              <span>
                <strong>{translate("destination")}: </strong>
                {service?.shipping?.destination_boarding_terminal?.city?.name}
              </span>
              <span>
                <strong>{translate("shipping_method")}: </strong>
                {
                  shippingMethods?.find(
                    (method) => method.id === service?.shipping?.shipping_method
                  )?.name
                }
              </span>
              <span>
                <strong>{translate("distance")}: </strong>
                {emissionDistance.toFixed(2)} kms
              </span>
            </div>
          </InfoContainer>
        )}
      </GeneralInfo>
      {/**traducir */}
      <MapContainer>
        <div id={"serviceMap"} className="w-100 h-100 border-rounded"></div>
      </MapContainer>
      <GraphContainer>
        <h3 style={{ width: "fit-content" }}>
          {`Emision CO₂ : ${emissions}`} kg
        </h3>
        <NeedlePieChart value={emissions} />
        <div
          style={{ display: "flex", gap: "10px", ...cardStyles.buttonGroup }}
        >
          {havePermissions([ROLES.Admin, ROLES.Advisor]) && (
            <button
              onClick={handleRecalculate}
              style={{ ...cardStyles.buttonCert }}
            >
              {translate("calculate")}
            </button>
          )}
          {havePermissions([ROLES.Admin, ROLES.Advisor]) && (
            <button
              onClick={handleCompensate}
              style={{ ...cardStyles.buttonCert }}
            >
              {translate("compensate")}
            </button>
          )}
        </div>
      </GraphContainer>
    </StyledContainer>
  );
}
