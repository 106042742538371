import { URL } from "../constants"


export const getQuotationsKpis = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}kpi/quotationsreport/dated/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}
export const getPricingTimes = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}kpi/pricingtimes/dated/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}
export const getOperationsKpis = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}kpi/operationkpis/dated/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}

// export const getCommercialKpis = async (token) => {
//     const response = await fetch(`${URL}kpicommercial/`, {
//         method: "GET",
//         headers: {
//             "Content-Type": "application/json",
//             "Authorization": "Token " + token,
//         }
//     })
//     return response;
// }

export const getTrms = async (token) => {
    const response = await fetch(`${URL}trm-val/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })

    return response;
}
export const getServicesSubType=async (token, fechaInicio, fechaFin, operation)=>{
    const response = await fetch(`${URL}quotationsbyservice/${fechaInicio}/${fechaFin}/${operation}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })

    return response;
}
export const getQuotationsBysubtype=async (token, fechaInicio, fechaFin, operation)=>{
    const response = await fetch(`${URL}quotationscountbysubtype/${fechaInicio}/${fechaFin}/${operation}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}


export const getCommercialKpis= async (token, dateStart, dateFinish) => {
    const response = await fetch(`${URL}kpi/kpi-commercial/${dateStart}/${dateFinish}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,

        },
    })
    return response
}


export const getSpendByCountry = async (token, dateStart, dateFinish) => {
    const response = await fetch(`${URL}spend-by-country/${dateStart}/${dateFinish}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,

        },
    })
    return response
}


export const getSpendByConcept = async (token, dateStart, dateFinish) => {
    const response = await fetch(`${URL}kpi/spend-by-concept/${dateStart}/${dateFinish}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,

        },
    })
    return response
}

export const getOperationKpis = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}kpi/operationkpis/dated/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    return response;
};

export const getUsersOperationReport = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}kpi/operatorsreport/dated/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    return response;
};
export const getLeadTimes = async (token) => {
    const response = await fetch(`${URL}service-times/lead_times/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,

        },
    })
    return response
}