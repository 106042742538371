import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { styled } from "@nextui-org/react";
import { useTranslation } from 'react-i18next';

export default function DashboardHorizontalBarChart({ dataKey, data, height, color = "#fd7c5c", marginLeft=50}) {

    const { t: translate } = useTranslation("Home")
    const filteredData = Array.isArray(data) ? data.filter(e => e[dataKey] !== 0) : [];
        return (
        <ResponsiveContainer width="100%" height="90%">
            <BarChart
                layout="vertical" // establece el diseño en vertical
                // width={width || 700}
                height={height || 400}
                data={filteredData}
                margin={{
                    top: 10,
                    right: 20,
                    bottom: 10,
                    left: marginLeft,
                }}
            >
                <CartesianGrid stroke="#f5f5f5"/>
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" tick={{ fontSize: 11 }} interval={0}/>
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey={dataKey} stackId="a" fill={color} barSize={10} radius={8}/>
            </BarChart>
        </ResponsiveContainer>
    );
}