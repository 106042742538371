import React from 'react'
import Table from '../../../../components/Table/Table'

export default function CalendarTable() {

  const columns = [
    { name: 'Date', uid: 'date', type: 'text' },
    { name: 'Departures', uid: 'departures', type: 'text' },
    { name: 'Arrivals', uid: 'arrivals', type: 'text' },
    { name: 'Pickups', uid: 'pickups', type: 'text' },
    { name: 'Deliveries #', uid: 'deliveries', type: 'text' },
  ]
  const mockData = [
    {
      key:1,
      date:"04/09",
      departures:"1",
      arrivals:"1",
      pickups:"pickup",
      deliveries:"delivery",
    },
    {
      key:2,
      date:"04/05",
      departures:"0",
      arrivals:"1",
      pickups:"pickup",
      deliveries:"delivery",
    }
  ]
  return (
    <Table columns={columns} items={mockData} excel={false} searchBar={false}/>
  )
}