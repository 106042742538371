import toast from "../components/Toast/ToastTypes";
import { logOutAction } from "../store/sessionDuck";
import { URL } from "../constants"

export const login_post = async (credentials) => {
    const response = await fetch(`${URL}login/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(credentials)
    })

    const data = await response.json();
    if (data.non_field_errors && data.non_field_errors[0] == "Unable to log in with provided credentials."){
        toast('error', data.message ? data.message : 'Usuario o contraseña incorrectas')
    } else {
        !response.ok && toast('error', data.message ? data.message : 'Ha ocurrido un error')
    }
    return data;
}

export const register = async (data) => {
    const response = await fetch(`${URL}dj-rest-auth/registration/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    });
    if (response.status === 200 ||response.status === 204 ) {
        return response; // Registro exitoso
    } else if (response.status === 400) {
        const errorData = await response.json();
        return errorData // Error de validación u otros errores
    } else {
        throw new Error("Hubo un error en el registro."); // Otros errores
    }
}


export const logout = async (token) => {
    const response = await fetch(`${URL}logout/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })

    return response
}

export const getUserAuth = async (token) => {
    const response = await fetch(`${URL}user/profile/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token  " + token,
        },
    })
    if (response.status === 200) {
        return response.json()
    } else {
        throw new Error("Hubo un error al obtener la autenticación del usuario");
    }
}

export const getProfile = async (token) => {
    const response = await fetch(`${URL}userprofile/my_profile/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response
}

export const editProfile = async (token,data) => {
    const response = await fetch(`${URL}userprofile/my_profile/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response
}
