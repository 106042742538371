import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import userReducer, { restoreSessionAction } from "./sessionDuck"
import co2Reducer from "./CO2"
import thunk from "redux-thunk";

/**
 * Reducers
 */

let rootReducer = combineReducers({
    user: userReducer,
    co2: co2Reducer
})

/**
 * Devtools
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    let store = createStore(
        rootReducer,
        composeEnhancers(applyMiddleware(thunk))
    )
    restoreSessionAction()(store.dispatch)
    return store;
}