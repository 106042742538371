import React from "react";
import {
  endOfDay,
  startOfYear,
  startOfMonth,
  endOfMonth,
  addMonths,
  subMonths,
  isSameDay,
  addDays,
} from "date-fns";
import { useEffect, useState } from "react";
import { styled, Modal } from "@nextui-org/react";
import { DateRangePicker, defaultStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import * as locales from "react-date-range/dist/locale";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal, {
  position: "relative",
  right: "250px",
  minWidth: "fit-content",
  height: "460px",
});

export default function DatesRangePickerModal({ setDates, open, setOpen }) {
  const [state, setState] = useState([
    {
      startDate: startOfMonth(subMonths(new Date(), 3)),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const { t: translate } = useTranslation("Components");

  useEffect(() => {
    setDates(state[0]);
  }, [state]);
  return (
    <StyledModal
      closeButton
      aria-label="date-range-picker"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <div className="App">
        <DateRangePicker
          onChange={(item) => setState([item.selection])}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={2}
          maxDate={addDays(new Date(), 1)}
          ranges={state}
          direction="horizontal"
          locale={locales["es"]}
          staticRanges={[
            //   {
            //     label: "Año pasado",
            //     range: () => ({
            //       startDate: startOfYear(addYears(new Date(), -1)),
            //       endDate: endOfYear(addYears(new Date(), -1)),
            //     }),
            //     isSelected(range) {
            //       const definedRange = this.range();
            //       return (
            //         isSameDay(range.startDate, definedRange.startDate) &&
            //         isSameDay(range.endDate, definedRange.endDate)
            //       );
            //     },
            //   },
            {
              label:translate("this_year"),
              range: () => ({
                startDate: startOfYear(new Date()),
                endDate: endOfDay(new Date()),
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              },
            },
            {
              label:translate("this_month"),
              range: () => ({
                startDate: startOfMonth(new Date()),
                endDate: endOfMonth(new Date()),
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              },
            },
            {
              label:translate("two_months"),
              range: () => ({
                startDate: startOfMonth(addMonths(new Date(), -1)),
                endDate: endOfMonth(new Date()),
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              },
            },
            {
              label:translate("three_months"),
              range: () => ({
                startDate: startOfMonth(addMonths(new Date(), -2)),
                endDate: endOfMonth(new Date()),
              }),
              isSelected(range) {
                const definedRange = this.range();
                return (
                  isSameDay(range.startDate, definedRange.startDate) &&
                  isSameDay(range.endDate, definedRange.endDate)
                );
              },
            },
          ]}
        />
      </div>
    </StyledModal>
  );
}