import { URL } from "../constants"
import { v4 as uuidv4 } from 'uuid';

export const getUserNotificationMethods = async (token) => {
    const response = await fetch(`${URL}get_user_notification_methods/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const activateUserNotificationMethod = async (token, id) => {
    const response = await fetch(`${URL}activate_user_notification_method/${id}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const deactivateUserNotificationMethod = async (token, id) => {
    const response = await fetch(`${URL}deactivate_user_notification_method/${id}/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const testNotification = async (token) => {
    const response = await fetch(`${URL}test_notification/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const addDevice = async (token, FCMToken) => {
    const browser = window.navigator.userAgent;
    let platform;
    if (navigator.userAgentData) {
        platform = navigator.userAgentData.platform;
    } else {
        platform = navigator.userAgent;
    }

    // Crea el nombre del dispositivo
    const name = `FCM-${browser}${platform}`;
    let uid = uuidv4();

    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Token ${token}`,
    };

    const body = JSON.stringify({
        registration_id: FCMToken,
        type: "web",
        device_id: uid,
        name: name
    });

    try {
        const response = await fetch(`${URL}devices/`, {
            method: "POST",
            headers: headers,
            body: body
        });
        return response;
    } catch (error) {
        console.error('An error occurred:', error);
    }
}