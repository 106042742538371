import React, { useState, useEffect } from 'react'
import Table from '../../../components/Table/Table'
import { deleteDO } from '../../../services/operations.services'
import { styled, Modal } from '@nextui-org/react'
import Button from '../../../components/Button/Button'
import { useSelector } from 'react-redux'
const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500',
})

const columns = [
    { name: 'Acciones', uid: 'actions', type: 'action' },
    { name: 'Radicado', uid: 'radication', type: 'text' },
    { name: 'Fecha de creacion', uid: 'created_at', type: 'text' },
]

export default function InstructionsTable({ items }) {
    const [openModal, setOpenModal] = useState(false)
    const [selectedDO, setSelectedDo] = useState({})
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const token = useSelector(state => state.user.token)
    const handleDelete = () => {
        deleteDO(selectedDO.id, token).then(res => {
            toast('success', 'Servicio borrado con exito')
            setOpenModal(false)
            setDeleteConfirmation(false)
            setSelectedDo('')
        }).catch(err => { console.log('ERROR :', err); toast('error', 'No se pudo borrar la instruccion') })
    }

    const actions = [
        {
            name: 'Ver instruccion',
            icon: 'View',
            handle: (DO) => {
                setSelectedDo(DO)
                setOpenModal(true)
            },
        },
        {
            name: 'Borrar instruccion',
            icon: 'Trash',
            handle: () => { setDeleteConfirmation(true) },
        },
    ];

    return (
        <div style={{ backgroundColor: '#EBEDF0', padding: '20px', margin: '20px', borderRadius: '8px' }}>
            <Table columns={columns} items={items} actions={actions}></Table>

            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={openModal}
                onClose={() => setOpenModal(false)}
                width="fit-content"
            >
                <Modal.Body>
                    <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => setDeleteConfirmation(true)} />
                    <div dangerouslySetInnerHTML={{ __html: selectedDO.shipping_instructions }} />
                </Modal.Body>
            </StyledFormModal>
            <StyledFormModal
                closeButton
                aria-label="modal-title"
                open={deleteConfirmation}
                onClose={() => setDeleteConfirmation(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la instruccion seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setDeleteConfirmation(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledFormModal>
        </div>
    )
}
