import React, { useState } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { getUserAuth } from '../../services/auth.services';
import { loginWithTokenAction } from '../../store/sessionDuck';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { useTranslation } from "react-i18next";

export default function LoginUrl() {
    const [response, setResponse] = useState('Login url')
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const isLoggedIn = useSelector(state => state.user.loggedIn)

    useEffect(() => {
        if (isLoggedIn) {
            setResponse('Ya hay una sesion iniciada')
        } else {
            getUserAuth(token).then(res => {

                if (res.user) {
                    dispatch(loginWithTokenAction({ ...res.user, token }))
                    navigate('/')
                } else setResponse(res.detail)
            })
        }
    }, [])

    return (
        <div>
            <h2 style={{ margin: '50px' }}>{response}</h2>
            <Link to='/' style={{ margin: '50px' }}>Inicio</Link>
        </div>
    )
}
