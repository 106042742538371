import React, { useEffect } from "react";
import { useState } from "react";
import InputTable from "../../../../../components/InputTable/InputTable";
import { useFormContext } from "react-hook-form";
import { useSelector } from "react-redux";
import { getProviders } from "../../../../../services/preliquidation.services";
import Button from "../../../../../components/Button/Button";
import CreateProviderModal from "../../../../Admin/providersTable/CreateProviderModal";

export default function NetInputTable({id, relatedTableId, providers, getProvidersData}){
    const methods = useFormContext()
    const token = useSelector(state => state.user.token)
    const [tableTotal,setTableTotal] = useState(0)
    const [tableProfit,setTableProfit] = useState(0)
    const [open, setOpen] = useState(false)

    function getIndex(str){
        const regex = /\[(.*?)\]/;
        const match = regex.exec(str); 
        if (match && match.length > 1) {
        const valorEntreCorchetes = match[1];
        return valorEntreCorchetes;
        } else {
        return null
        }
    }

    const columns = [
        {name:"Concepto", type:'text'},
        {name:"Unit", type:'select'},
        {name:"Net", type:'number'},
        {name:"Currency & Trm", type:'text'},
        {name:"Minima", type:'number'},
        {name:"Total", type:'number'},
        {name:"Profit", type:'number'},
        {name:"Proveedor", type:'select', options: providers?.map(prov => {return {
            label:prov.name,
            value:prov.id,
        }})},
    ]

    const defaultData = {
        itemId:999,
        Concepto: '',
        Net: '',
        Mínima: '',
        Total: '',
        Profit: '',
        Proveedor: '',
    }

    function calculateTotal(net, min, factor, unit, trm, currency) {
        const F = factor || 1
        const currencyValue = trm || 1/currency?.value

        const unitValue = unit?.value || 1
        const total = Math.max(unitValue * (net || 0), min || 0) * F / currencyValue
        return total
    }

    function calculateTableTotal(items, relatedItems){
        let tableTotalCount = 0
        let tableProfitCount = 0
        items.map((row, index ) => {
            const rowTotal = calculateTotal(row.Net, row.Minima, relatedItems[index]?.Factor,relatedItems[index]?.Unit, relatedItems[index]?.Trm, relatedItems[index]?.Currency)
            const rowProfit = relatedItems[index]['Total in USD'] - rowTotal
            tableTotalCount += rowTotal
            tableProfitCount += rowProfit
        })
        setTableTotal(tableTotalCount)
        setTableProfit(tableProfitCount)
    }

    function syncronizeTable(value){
        const netValues = value[id]
        const relatedTableData = value[relatedTableId].map(row => {
            return {
                itemId:row.itemId,
                concepto: row.Concepto,
                factor: row.Factor,
                unit: row.Unit,
                Net:row.Net,
                NetMin:row.NetMin,
                netProvider:row.netProvider,
                currency:row.Currency,
                trm:row.Trm,
                total: row['Total in USD'],
            }
        })
        const items = relatedTableData.map((rowData , index) => {
            if (rowData.concepto){
                // const profit = rowData.total - calculateTotal(netValues[index]?.Net, netValues[index]?.Minima, rowData.factor, rowData.trm, rowData.currency)
                return {
                    ...netValues[index],
                    itemId: index+1,
                    Factor:rowData.factor,
                    Unit:rowData.unit,
                    Net:rowData.Net,
                    Minima:rowData.NetMin,
                    Proveedor:rowData.netProvider,
                    Currency:rowData.currency,
                    Trm:rowData.trm,
                    "Currency & Trm": `${rowData.currency.label} (${rowData.trm || (1/rowData.currency.value).toFixed(2)})`,
                    CostTotal: rowData.total,
                    Concepto:rowData.concepto.label || rowData.concepto,
                    // Total:calculateTotal(netValues[index]?.Net, netValues[index]?.Minima, rowData.factor, rowData.trm, rowData.currency),
                    // Profit:profit.toFixed(2),
                }
            }
        })
        const cleanItems = items.filter(e=>e?.Concepto)
        methods.setValue(id, cleanItems)
    }

    useEffect(()=>{
        const value = methods.getValues()
        syncronizeTable(value)
        calculateTableTotal(value[id], value[relatedTableId])
    },[])

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            const index = getIndex(name)
            if (name === `${id}[${index}].Net`){
                methods.setValue(`${relatedTableId}[${index}].Net`, value[id][index]?.Net)
            }
            if (name === `${id}[${index}].Minima`){
                methods.setValue(`${relatedTableId}[${index}].NetMin`, value[id][index]?.Minima)
            }
            if (name === `${id}[${index}].Proveedor`){
                if (value[id][index]?.Proveedor){
                    methods.setValue(`${relatedTableId}[${index}].netProvider`, value[id][index]?.Proveedor)
                }
            }
            if (name?.startsWith(relatedTableId)){
                syncronizeTable(value)
            }
            if (name?.startsWith(id) || name?.startsWith(relatedTableId)){
                const items = methods.getValues(id)
                const index = getIndex(name)
                if (
                    name === `${id}[${index}].Net` ||
                    name === `${id}[${index}].Minima` ||
                    name === `${relatedTableId}[${index}].Factor` ||
                    name === `${relatedTableId}[${index}].Unit` ||
                    name === `${relatedTableId}[${index}].Rate` ||
                    name === `${relatedTableId}[${index}].Min` ||
                    name === `${relatedTableId}[${index}].Currency` ||
                    name === `${relatedTableId}[${index}].Trm`
                ){
                    //TOTAL DE FILA
                    const {Net, Minima, Factor, Unit, Trm, CostTotal, Currency } = items[index]
                    const rowTotal = calculateTotal(Net, Minima, Factor, Unit, Trm, Currency)
                    const rowProfit = CostTotal - rowTotal
                    methods.setValue(`${id}[${index}].Total`,rowTotal>0? rowTotal?.toFixed(2) : 0)
                    methods.setValue(`${id}[${index}].Profit`,rowProfit?.toFixed(2) )
                    //TOTAL DE TABLA
                    calculateTableTotal(items, value[relatedTableId])
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);
    
    return <div style={{paddingBottom:'80px'}}>
        <div style={{float:'right', margin:'10px'}}>
            <Button text="Crear proveedor" handler={()=>{setOpen(true)}} bgColor={'primary_300'} textColor={'white'} size={'sm'}/>
        </div>
        <InputTable tableName={id} columns={columns} defaultData={defaultData} enableAddDelete={false}/>
        <h4>Total : {isNaN(tableTotal)? 0.00 : tableTotal.toFixed(2)}</h4>
        <h4>Profit : {isNaN(tableProfit)? 0.00 :tableProfit.toFixed(2)}</h4>
        <CreateProviderModal open={open} setOpen={setOpen} getData={getProvidersData}/>
    </div>

}