import React, {useEffect, useState} from 'react'
import { styled } from '@nextui-org/react'
import { useFormContext } from 'react-hook-form';
import SmallField from '../Fields/SmallField';
import SmallSelect from '../Fields/SmallSelect';
import { getTrms } from '../../../../../services/kpis.services';
import { useSelector } from 'react-redux';
import NetAirFreight from './NetAirFreight';

const Container = styled("div",{
    display:'grid',
    gap:'20px 60px',
    gridTemplateColumns:'repeat(2, 1fr)',
    ['input']:{
        fontSize: '13px',
        padding: '4px',
        margin:'5px',
        border: '1px solid #ccc',
        ['&:focus']:{
            outline: '1px solid #ccc',
            border: '1px solid #ccc',
        }
    }
})

function calculateFreightPrice(trm, actualWeight, chargeableVol, frequency, pricePerKg) {
    let result = 0;
    const formatTrm = trm || 1
    if (Number(actualWeight) > Number(chargeableVol)){
        result =  pricePerKg * actualWeight / formatTrm;
    } else {
        result = chargeableVol  * pricePerKg / formatTrm;
    }
    if (isNaN(result)){
        return 0
    } else return result;
}

function calculateFuelSurcharge(actualWeight, chargeableVol, weight, trm){
    const formatTrm = trm || 1
    if (!actualWeight || !chargeableVol || !weight || !formatTrm){return 0}
    const max = Math.max(actualWeight , chargeableVol);
    const result = weight * max / formatTrm
    if (isNaN(result)){
        return 0
    } else return result;
}

export default function AirFreight() {
    const token = useSelector(state=>state.user.token)
    const nameForm='air_freight'
    const methods = useFormContext()

    const [weight, setWeight] = useState(0)
    const [chargeableVol, setChargeableVol] = useState(0)
    const [currencyOptions, setCurrencyOptions] = useState()

    function updateValues(value ,name){
        if (name === `${nameForm}.currency` || name == `${nameForm}.trm` || name === 'shipping_information.weight' || name === 'shipping_information.chargeable_vol' || name === `${nameForm}.frequency` || name === `${nameForm}.price_per_kg`|| name === `${nameForm}.min` || name === `${nameForm}.surcharge_min` || name === `${nameForm}.surcharge_weight`){
            setWeight(value.shipping_information?.weight)
            setChargeableVol(value.shipping_information?.chargeable_vol)
            let price = calculateFreightPrice(value[nameForm].trm, value.shipping_information?.weight, value.shipping_information?.chargeable_vol, value[nameForm].frequency, value[nameForm].price_per_kg)
            if (value[nameForm]?.min > price){
                price = value[nameForm]?.min
            }
            methods.setValue(`${nameForm}.freight_total`, Number(price)?.toFixed(2))
            let fuelPrice = calculateFuelSurcharge(value.shipping_information?.weight, value.shipping_information?.chargeable_vol, value[nameForm].surcharge_weight, value[nameForm].trm)
            if (value[nameForm].surcharge_min > fuelPrice){
                fuelPrice = Number(value[nameForm].surcharge_min)
            }
            
            methods.setValue(`${nameForm}.surcharge_total`, fuelPrice? fuelPrice?.toFixed(2) : 0)
            
            let Total = value[nameForm]?.freight_total
            if (value[nameForm]?.surcharge_total > 0){
                Total = Number(value[nameForm].freight_total) + Number(value[nameForm].surcharge_total)
            } 
            methods.setValue(`${nameForm}.total`,Total)
        }
        if (name === `${nameForm}.currency`){
            let trmValue = 1/value[nameForm].currency?.value
            if (isNaN(trmValue)){
                trmValue=1
            }
            methods.setValue(`${nameForm}.trm`,trmValue?.toFixed(2))
        }
    }
    

    useEffect(()=>{
        const values = methods.getValues()
        updateValues(values,`${nameForm}.currency`)
        getTrms(token).then(async res => {
            const trmData = await res.json();
            setCurrencyOptions([
                {label:'USD', value:1},
                {label:'COP', value:1/trmData.usd_cop},
                {label:'EUR', value:1/trmData.usd_eur},
            ])
        })
    },[])

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            updateValues(value,name)
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    return (
    <>
        <Container>
            <div>
            <h4>Peso : {weight} Kg</h4>
                <SmallSelect required={false} name={`${nameForm}.currency`} label="Moneda:" options={currencyOptions}/>
                <SmallField required={true} name={`${nameForm}.trm`} label="trm:"/>
                <SmallField required={false} name={`${nameForm}.frequency`} label="Frequency:"/>
                <SmallField required={false} name={`${nameForm}.min`} label="Min:" type='number'/>
                <SmallField required={false} name={`${nameForm}.price_per_kg`} label="Precio por kg:" type='number'/>
                <h4 style={{margin:'5px'}}>Fuel Surcharge :</h4>
                <div style={{display:'flex'}}>
                    <SmallField required={false} name={`${nameForm}.surcharge_min`} label="Min:" type='number'/>
                    <SmallField required={false} name={`${nameForm}.surcharge_weight`} label="Kg:" type='number'/>
                </div>
                </div>
                <div>
                <h4>Chargeable Vol : {Number(chargeableVol)?.toFixed(2)} Kg</h4>
                <SmallField required={false} name={`${nameForm}.freight_total`} label="Total flete USD:" type='number'/>
                <SmallField required={false} name={`${nameForm}.surcharge_total`} label="Total Fuel Surcharge USD:" type='number'/>
                <SmallField required={false} name={`${nameForm}.total`} bold label="TOTAL USD:" type='number'/>
            </div>
        </Container>
        <NetAirFreight/>
    </>
  )
}