import React from 'react'
import { styled, Image } from '@nextui-org/react'
import PageTitle from '../../components/PageTitle/PageTitle'
import ship from '../../assets/sendMethods/maritime.jpg'
import Card3 from '../../components/Cards/Card3/Card3'
import video from '../../assets/tools/video.jpg'
import YoutubeEmbed from '../../components/Youtube/EmbedYoutube'
import TutorialsData from './TutorialsData'

const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '40px',
    // gridTemplateRows: '160px 500px 450px',
})

const StyledMain = styled("div", {
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    gridColumn: 'span 3',
    padding: '50px',
    margin: '20px',
    ['img']: {
        margin: '40px 0',
        borderRadius: '8px',
    }
})
const OtherTutorials = styled("div", {
    backgroundColor: '$neutral_50',
    padding: '50px 20px',
    margin: '20px 0',
})

const tutorials = TutorialsData;


export default function Co2Tutorial() {
    return (
        <StyledContainer>
            <StyledMain>
                <PageTitle>Reduciendo emisiones de CO2</PageTitle>
                <YoutubeEmbed embedId="u31qwQUeGuM" />
                <h3>Minimizando la Huella de Carbono en tus Operaciones</h3>
                <p>
                    Explora estrategias clave para reducir las emisiones de CO2 en tus procesos comerciales. En este video, te proporcionaremos consejos esenciales y prácticas sostenibles que puedes implementar para minimizar el impacto ambiental de tus operaciones.
                </p>
                <p>
                    Desde opciones de transporte con bajas emisiones hasta la adopción de prácticas de embalaje ecoamigables, abordaremos medidas específicas que te permitirán contribuir activamente a la preservación del medio ambiente. ¡Descubre cómo hacer de tu negocio una fuerza positiva para el planeta!
                </p>
            </StyledMain>
            <OtherTutorials>
                <PageTitle>Explorar mas</PageTitle>
                {
                    tutorials.map(((tutorial, i) => {
                        return <Card3 key={i} title={tutorial.title} image={tutorial.image} to={tutorial.url} iconVideo />
                    }))
                }
            </OtherTutorials>
        </StyledContainer>
    )
}
