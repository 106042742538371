import { URL } from "../constants"
import { getPreliquidationCosts } from "../pages/functions"

export const getPreliquidations = async (token, id) => {
    const getURL = id ? `${URL}preliquidation/preliquidations/${id}/` : `${URL}preliquidation/preliquidations/`

    const response = await fetch(getURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const postLiquidation = async (data, token) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}
export const editLiquidation = async (data, id, token) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/${id}/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteLiquidation = async (id, token) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    if (response.status === 204 || response.status === 200) {
        return true;
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const getServiceGroups = async (token) => {
    const response = await fetch(`${URL}preliquidation/service-groups/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getServices = async (token, serviceGroupId) => {
    const servicesURL = serviceGroupId ? `${URL}preliquidation/services/${serviceGroupId}/` : `${URL}preliquidation/services/`
    const response = await fetch(servicesURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}


export const getServiceVariations = async (token, serviceId) => {
    const servicesVariationsURL = serviceId ? `${URL}preliquidation/service-variations/${serviceId}/` : `${URL}preliquidation/service-variations/`
    const response = await fetch(servicesVariationsURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getProviderTypes = async (token) => {
    const response = await fetch(`${URL}preliquidation/provider-types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getProviders = async (token, providerTypeId) => {
    const providersURL = providerTypeId ? `${URL}preliquidation/providers/${providerTypeId}/` : `${URL}preliquidation/providers/`
    const response = await fetch(providersURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getProviderDetails = async (token, providerId) => {
    const providersURL = `${URL}preliquidation/providers/${providerId}/`
    const response = await fetch(providersURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const editProvider = async (token, providerId, data) => {
    const providersURL = `${URL}preliquidation/providers/${providerId}/`
    const response = await fetch(providersURL, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body:JSON.stringify(data)
    })
    return response
}
export const createProvider = async (token, data) => {
    const response = await fetch(`${URL}preliquidation/providers/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}

export const deleteProvider = async (token, providerId) => {
    const response = await fetch(`${URL}preliquidation/providers/${providerId}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    if (response.ok){
        return response
    } else throw Error('Error en DELETE :', response.message)
}

export const getAppliesPer = async (token) => {
    const response = await fetch(`${URL}preliquidation/applies-per/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getRateTypes = async (token) => {
    const response = await fetch(`${URL}preliquidation/rate-types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getRates = async (token) => {      //Rate number, Minimum rate, Currency, Trm, Rate type
    const response = await fetch(`${URL}preliquidation/rates/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSaleRates = async (token) => {
    const response = await fetch(`${URL}preliquidation/sale-rates/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getPurchaseRates = async (token) => {
    const response = await fetch(`${URL}preliquidation/purchase-rates/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}


export const getChargeConcepts = async (token) => {
    const response = await fetch(`${URL}preliquidation/ChargeConcept/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const prelDetails = async (data, token) => {
    const response = await fetch(`${URL}preliquidation/preliquidations-detail/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

//NUEVA PRELIQUIDACION :
export const getMoveTypes = async (token) => {
    const response = await fetch(`${URL}preliquidation/move-type/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getLoadTypes = async (token) => {
    const response = await fetch(`${URL}preliquidation/load-type/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

function handlePrelErrors(errorData){
    const errorMessages = []
    errorData.items?.forEach(item => {
        Object.keys(item).map((key)=>{
            console.log("key :",key)
            switch(key){
                case "item_rate": errorMessages.push("Costo enviado sin rate")  
                case "rate": return errorMessages.push("Un proveedor enviado no existe")
                case "service_details":{
                    if (item?.service_details.move_type){errorMessages.push("Tipo de movimiento es requerido")}
                    if (item?.service_details.load_type){errorMessages.push("Tipo de carga es requerido")}
                }
            }
        })
    })
    const countOccurrences = errorMessages.reduce((acc, curr) => {
        acc[curr] = (acc[curr] || 0) + 1;
        return acc;
    }, {});
    const errorsArray = Object.keys(countOccurrences).map(key => ({
        message: key,
        count: countOccurrences[key]
    }));
    return errorsArray.map(error => `${error.message}: (${error.count})`).join('\n');
}
export const createPreliquidation = async (token, data) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else {
        const errorData = await response.json()
        const errorMessage = handlePrelErrors(errorData)
        throw Error(errorMessage)
    }
}

export const editPreliquidation = async (token, data, preliquidationId) => {
    const response = await fetch(`${URL}preliquidation/preliquidations/${preliquidationId}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else {
        const errorData = await response.json()
        const errorMessage = handlePrelErrors(errorData)
        throw Error(errorMessage)
    }
}

export const uploadPreliquidationExcel = async (token, file) => {
    const formData = new FormData();
    formData.append(`file`, file);
    const headers = new Headers();
    headers.append('Authorization', 'Token ' + token);
    const response = await fetch(`${URL}preliquidation/excel-preliquidation/`, {
        method: "POST",
        headers: headers,
        body: formData
    });
    return response
    if (response.ok){
        return response
    } else {
        const errorData = await response.json()
        const errorMessage = handlePrelErrors(errorData)
        throw Error(errorMessage)
    }
}

export const formatLiquidation = async (data,token) => {
    const formatted=[]
    const providersData = await (await getProviders(token)).json();
    data?.forEach((prel) => {
        if (prel?.items?.length > 0) {
            prel.items.forEach((item, i) => {
                let dataReturn = {}
                const getItemData = (item) => {
                    const source = item.rate || item.ocean_rate;
                    const providerId = source?.provider;
                    const provider = providersData.find(provider => provider.id === providerId)?.name;
                   
                    return {
                        id: prel.id,
                        provider: provider,
                        unit: item.unit,
                        service_group: item.item_type,
                        profit: source?.profit || item.total_in_usd,
                        sale_price: item.total_in_usd * Number(item.trm),
                        sale_in_usd: item.total_in_usd,
                        sale_currency: item.currency,
                        item_rate:item.item_rate,
                        purchase_price: source?.total || 0,
                        service: item.concept,
                        factor: item.factor,
                        min:item.min,
                        sale_trm: item.trm ? Number(item.trm) : 1,
                        air_cargo: prel.air_cargo? {...prel.air_cargo, air_rate:0, key:i} : null
                    };
                };

                dataReturn = getItemData(item);
                formatted.push(dataReturn);
            });
        }

    });
    return formatted

}
export const getConceptRecommendation = async (token, concept) => {
    const getURL = `${URL}preliquidation/recommendations/?concept=${concept}`
    const response = await fetch(getURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    if (response.ok){
        return response
    } else throw Error("No se pudo traer recomendaciones")
}