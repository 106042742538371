import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import { usePDF } from 'react-to-pdf';
import ColoredPieChart from '../../../components/Graphics/ColoredPieChart'
import ComposedBarChart from '../../../components/Graphics/ComposedChart/ComposedChart'
import DashboardHorizontalBarChart from '../../../components/Graphics/HorizontalChart/DashboardHorizontalChart'
import Button from '../../../components/Button/Button';
import Input from '../../../components/Input/Input';
import { getQuotesByRange } from '../../../services/quotations.services'
import { useSelector } from "react-redux";
import { getServicesSubType, getQuotationsBysubtype, getQuotationsKpis, getPricingTimes,getCommercialKpis } from '../../../services/kpis.services';
import { startOfMonth, format } from "date-fns";
import DatesRangePickerModal from '../../../components/DateRangePicker/DateRangePicker.jsx';
import { useTranslation } from "react-i18next";
import UltraHorizontalBarChart from '../../../components/Graphics/HorizontalChart/UltraHorizontalBarChart.jsx';
import Card from '../../Admin/dashboard/Card.jsx';

const colors = ['#3D509A', '#EB9D3A ', '#81D393','#5b658d']

const Container = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '20px',
    margin:'30px',
})

const ChartContainer = styled("div", {
    background: 'white',
    borderRadius: '8px',
    padding: '20px',
    width: '100%',
    boxShadow:'$elevation_1',
})

const PieCharts = styled("div", {
    gridColumn:'span 4',
    background: 'white',
    borderRadius: '8px',
    padding: '20px',
    width: '100%',
    boxShadow:'$elevation_1',
    display:'flex',
    justifyContent:'space-around',
    '.chart':{
        width: '100%',
    }
})

const formatNumber = (value) => {
    const numValue = Number(value);
    if (numValue >= 1000) {
      const formattedValue = (numValue / 1000).toFixed(2);
      return `${formattedValue}K`;
    } else {
      return numValue.toFixed(2);
    }
};
function stringToNumber(string) {
    const match = string?.match(/([\d,]+\.\d+)/);
    if (match) {
      const numeroStr = match[1].replace(/,/g, '');
      return parseFloat(numeroStr);
    } else {
      return null;
    }
}


export default function PricingDashboard() {
    const { t: translate } = useTranslation("Admin");
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [loading, setLoading] = useState(false);
    const [dataOperations, setdataOperations] = useState([]);
    const [openDates, setOpenDates] = useState(false)
    const [dates, setDates] = useState({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
    });
    const [kpis, setKpis] = useState();
    const [cardsData, setCardsData] = useState()

    console.log("kpis :",kpis)
    
    const user = useSelector(state => state.user);
    const { toPDF, targetRef } = usePDF({ filename: 'pricing_dashboard.pdf' });

    function downloadPDF() {
        setLoadingPdf(true)
        toPDF().then(() => {
            setLoadingPdf(false)
        }).catch((err) => {
            console.log("error al descargar pdf :", err)
            setLoadingPdf(false)
        })
    }

    useEffect(() => {
        setLoading(true)
        Promise.all([
            getQuotesByRange(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
            getServicesSubType(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
            getQuotationsBysubtype(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"), ),
            getQuotationsKpis(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
            getPricingTimes(user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")),
            getCommercialKpis( user.token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd"))
            
        ]).then(async res => {
            const requestsData = await res[0].json()
            const servicesSubTypeData=await res[1].json()
            const quotationsBySubtypeData=await res[2].json()
            const quotationKpisData = await res[3].json();
            const pricingTimes = await res[4].json();
            const commercialKpisData = await res[5].json();

            const commercialKpisCards = {
                total_profit :  commercialKpisData.total_profit,
                total_quotations: commercialKpisData.total_quotations,
            }
            setCardsData(commercialKpisCards)
            console.log("commercialKpisData :",commercialKpisData)
            let importacionTotal = 0;
            let exportacionTotal = 0;
            let otrosTotal = 0;
            let aereosTotal=0;
            let maritimosTotal=0;
            let otrosTotalMethod=0;
            servicesSubTypeData.forEach(item => {
                if (item.name.toLowerCase().includes('importacion')) {
                    importacionTotal += item.value;
                } else if (item.name.toLowerCase().includes('exportacion')) {
                    exportacionTotal += item.value;
                } else {
                    otrosTotal += item.value;
                }
                if(item.name.toLowerCase().includes("maritima")){
                    maritimosTotal += item.value;
                }
                else if(item.name.toLowerCase().includes('aerea')){
                    aereosTotal+= item.value;
                }else{
                    otrosTotalMethod+= item.value;
                }
            });
            setLoading(false)
            const agrupado = [
                { "name": "Importacion", "value": importacionTotal },
                { "name": "Exportacion", "value": exportacionTotal },
                { "name": "Otros", "value": otrosTotal }
            ];
            const agrupadoMethod = [
                { "name": "Maritimo", "value": maritimosTotal },
                { "name": "Aéreo", "value": aereosTotal },
                { "name": "Otros", "value": otrosTotalMethod }
            ];
            let mesesImportacion=[]
            let mesesExportacion=[]
            quotationsBySubtypeData.forEach((item)=>{
                if(item.name.toLowerCase().includes('importacion')){
                    item.value.forEach((month)=>{
                        mesesImportacion.push(month)
                    })
                }
                else if(item.name.toLowerCase().includes('exportacion')){
                    item.value.forEach((month)=>{
                        mesesExportacion.push(month)
                    })
                }
            })
            const mergedDataImports = mesesImportacion.reduce((acc, item) => {
                const existingItem = acc.find(element => element.name === item.name);
                if (existingItem) {
                    existingItem.impos += item.solicitudes;
                } else {
                    acc.push({ name: item.name, impos: item.solicitudes });
                }
                return acc;
            }, []);
            const mergedDataExports = mesesExportacion.reduce((acc, item) => {
                const existingItem = acc.find(element => element.name === item.name);
                if (existingItem) {
                    existingItem.expos += item.solicitudes;
                } else {
                    acc.push({ name: item.name, expos: item.solicitudes });
                }
                return acc;
            }, []);
            setdataOperations(agrupado)
            setKpis({
                requests:requestsData,
                operations:agrupado,
                method:agrupadoMethod,
                expo:mergedDataExports,
                impo:mergedDataImports,
                ...quotationKpisData,
            })
        })
    }, [user.token,dates]);

    const transformData = (kpis) => {
        const months = kpis.requests.map(req => req.name);
        const data = months.map(month => {
          const requestData = kpis.requests.find(req => req.name === month);
          const impoData = kpis.impo.find(impo => impo.name === month) || { impos: 0 };
          const expoData = kpis.expo.find(expo => expo.name === month) || { expo: 0 };
          return {
            label: month,
            expo: expoData.expo,
            impo: impoData.impos,
            requests: requestData.solicitudes,
          };
        });
        return data;
    };

    const data = kpis && transformData(kpis)

    function formatKpi(data){
        const formated = Object.keys(data).map(key=>{
            return {
                name:key,
                quotations:data[key],
            }
        })
        return formated;
    }
    function formatKpiPie(data){
        const formated = Object.keys(data).map(key=>{
            return {
                name:key,
                value:data[key],
            }
        })
        return formated;
    }
    
    const quotationsByClient =  kpis?.quotations_by_client && formatKpi(kpis.quotations_by_client)?.sort((a,b)=>b.quotations - a.quotations)?.slice(0,15)
    const quotationsByIncoterm = kpis?.quotations_by_incoterm && formatKpiPie(kpis.quotations_by_incoterm)
    const quotationsByOffice = kpis?.quotations_by_office && formatKpiPie(kpis.quotations_by_office)
    const quotationsByService = kpis?.quotations_by_service && formatKpiPie(kpis.quotations_by_service)
    const quotationsByShipping = kpis?.quotations_by_shipping && formatKpiPie(kpis.quotations_by_shipping)
    const quotationsByStatus = kpis?.quotations_by_status && formatKpiPie(kpis.quotations_by_status)
    const quotationsBySubtype = kpis?.quotations_by_subtype && Object.keys(kpis?.quotations_by_subtype).map(key=>{
        return {
            label:key,
            quotations:kpis?.quotations_by_subtype[key],
        }
    })
    let currency = "";
    if (cardsData?.total_profit){
      let matchCurrency = cardsData?.total_profit.match(/([A-Z]{3}|None)$/);
      if (matchCurrency) {
        currency = matchCurrency[0];
      }
    }
    return (
        <>  
            <div style={{ margin: '20px', display:'flex', justifyContent:'space-between', alignItems:'center' }}>
                <div onClick={() => {setOpenDates(true)}} style={{ display: "flex", gap: "20px", width: "fit-content" }}>
                    <Input label={"desde"} value={format(dates.startDate, "dd/MM/yyyy")}/>
                    <Input label={"hasta"} value={format(dates.endDate, "dd/MM/yyyy")}/>
                </div>
                <Button text={loadingPdf ? 'Cargando...' : 'Descargar pdf'} disabled={loadingPdf} handler={() => downloadPDF()} outline />
            </div>
            <DatesRangePickerModal
                aria-labelledby="modal-date-range"
                setDates={setDates}
                open={openDates}
                setOpen={setOpenDates}
            />
            <Container ref={targetRef}>
                <div style={{display:'flex', justifyContent:'space-between', gridColumn:'span 4'}}>
                    <Card title={translate("quotations")} number={cardsData?.total_quotations &&
                    formatNumber(cardsData?.total_quotations?.toFixed(2) ?? 0)} color='#3D509A' icon='icon' aditional={translate("quotations_legend")} loading={loading}/>
                    <Card title='$ Profit' number={cardsData?.total_profit && `${formatNumber(stringToNumber(cardsData?.total_profit))} ${currency}`} color='#292E68' icon='icon' aditional={translate("profit_legend")} loading={loading}/>
                    <Card title='$ Promedio' number={`${formatNumber(stringToNumber(cardsData?.total_profit) / cardsData?.total_quotations.toFixed(2))} ${currency}`} color='#3D509A' icon='icon' aditional='Profit promedio por cotizacion' loading={loading}/>
                </div>
                <ChartContainer style={{gridColumn:'span 4', minHeight:'500px'}}>
                    <p style={{ margin: '15px', color: 'grey' }}>Importaciones, exportaciones y solicitudes</p>
                    <UltraHorizontalBarChart
                        data={data}
                        barDataKeys={[
                            { key: 'expo', color: '#EB9D3A' },
                            { key: 'impo', color: '#3D509A' },
                        ]}
                        lineDataKeys={[{ key: 'requests', color: '#81D393' }]}
                    />
                </ChartContainer>
                <ChartContainer style={{gridColumn:'span 3'}}>
                    <p style={{ margin: '15px', color: 'grey' }}>Cotizaciones por cliente (top 15 clientes)</p>
                    <DashboardHorizontalBarChart height={300} color={'#3D509A'} dataKey={"quotations"} data={quotationsByClient} marginLeft={210}/>
                </ChartContainer>
                <ChartContainer>
                    <p style={{ margin: '15px', color: 'grey' }}>Liquidaciones</p>
                    <ColoredPieChart colors={colors} data={dataOperations} radius={80} legend={true} />
                </ChartContainer>

                <ChartContainer style={{gridColumn:'span 2'}}>
                    <p style={{ margin: '15px', color: 'grey' }}>Cotizaciones por subtipo</p>
                    {/* <DashboardHorizontalBarChart  height={300} color={'#3D509A'} dataKey={"hitRate"} data={dataHitRate} /> */}
                    <UltraHorizontalBarChart
                        data={quotationsBySubtype}
                        barDataKeys={[{ key: 'quotations', color: '#EB9D3A' }]}
                        lineDataKeys={[]}
                        angle={-45}
                    />
                </ChartContainer>
                <ChartContainer style={{gridColumn:'span 2'}}>
                    <p style={{ margin: '15px', color: 'grey' }}>Cotizaciones por servicio</p>
                    <ColoredPieChart colors={colors} data={quotationsByService} radius={100} legend={true} />
                </ChartContainer>
                <PieCharts>
                    <div className='chart'>
                        <p style={{ margin: '15px', color: 'grey' }}>Por incoterm : </p>
                        <ColoredPieChart colors={colors} radius={60} legend={true} data={quotationsByIncoterm}/>
                    </div>
                    <div className='chart'>
                        <p style={{ margin: '15px', color: 'grey' }}>Por oficina : </p>
                        <ColoredPieChart colors={colors} data={quotationsByOffice} radius={60} legend={true} />
                    </div>
                    <div className='chart'>
                        <p style={{ margin: '15px', color: 'grey' }}>Por estado : </p>
                        <ColoredPieChart colors={colors} data={quotationsByStatus} radius={60} legend={true} />
                    </div>
                </PieCharts>
            </Container>
        </>
    )
}