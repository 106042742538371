import React from 'react'
import { styled,Tooltip } from '@nextui-org/react'

export default function Circle({state,tooltip, firstCircle=false}) {

    const color = state==='error' ?'$delete': state === 'completed'? '$excel' : state === 'started'? '#ffff33' : '#dcdcdc'

    const StyledCircle = styled("div",{
        height:'20px',
        width:'20px',
        backgroundColor:color,
        borderRadius:'50%',
        ['&:hover']:{
            cursor:'pointer',
        },
    })
    const Line = styled("div",{
        height:'4px',
        backgroundColor:color,
        width:'35px',
        position:'absolute',
        top:'9px',
        right:0,
    })
    return (
        <div style={{position:'relative'}}>
            <Tooltip content={tooltip}>
                <StyledCircle/>
                {!firstCircle && <Line/>}
            </Tooltip>
        </div>
    )
}
