import React, { useState, useEffect, useRef } from "react";
import theme from "../../theme";
import {
  Table as Tab,
  Dropdown,
  styled,
  Badge,
  Tooltip,
} from "@nextui-org/react";
import ReactStars from "react-stars";
import * as XLSX from "xlsx";
import { debounce } from "lodash";
import ReactCountryFlag from "react-country-flag";
// Components
import Input from "../Input/Input";
import Icon from "../Icon/Icon";
import Button from "../Button/Button";
import Movements from "../Movements/Movements";
import useDraggableScroll from "use-draggable-scroll";
import { useTranslation } from "react-i18next";

const Table = ({
  columns,
  items,
  actions,
  excel = true,
  searchBar = true,
  pagination = true,
}) => {
  const { t: translate } = useTranslation("Components");

  const [limitPage, setLimitPage] = useState(10);

  const [displayItems, setDisplayItems] = useState([]);
  const [generalSearch, setGeneralSearch] = useState("");

  const [sortDirection, setSortDirection] = useState("ascending");
  const [sortColumn, setSortColumn] = useState("");

  const tableRef = useRef(null);
  const ref = useRef("div");
  const { onMouseDown } = useDraggableScroll(ref, { direction: "horizontal" });

  const getCurrentDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // Enero es 0
    const yyyy = today.getFullYear();
    return dd + mm + yyyy;
  };

  const exportToExcel = (data) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const currentDate = getCurrentDate();
    const fileName = `VALexport-${currentDate}.xlsx`;
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  useEffect(() => {
    setDisplayItems(items);
  }, [items]);

  const Draggable = styled("div", {
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      height: "13px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#5E6EAB",
      borderRadius: "50px",
    },
  });

  const StyledTable = styled(Tab, {
    [`.${theme} &`]: {
      borderCollapse: "separate",
      borderSpacing: "0px 10px",
      fontSize: "12px",
      ["th"]: {
        color: "$primary_300",
        background: "white",
        borderBottom: "4px solid $primary_300",
        borderRadius: 0,
        fontWeight: "600",
        fontSize: "13px",
        // minWidth: "110px",
        padding: "0px",
        margin: "0px",
      },
      ["tbody > tr"]: {
        backgroundColor: "$neutral_50",
        boxShadow: "$elevation_2",
        borderRadius: "8px",
      },
      ["td:first-child"]: {
        borderRadius: "8px 0px 0px 8px",
      },
      ["td:last-child"]: {
        borderRadius: "0px 8px 8px 0px",
      },
      ["td:all-childs"]: {
        border: "1px solid black",
      },
      [".nextui-table-hidden-row"]: {
        marginTop: "0rem",
        height: "0rem",
      },
      [".nextui-pagination-item"]: {
        backgroundColor: "$neutral_50",
        color: "$primary_300",
      },
      [".nextui-pagination-highlight--active"]: {
        backgroundColor: "$primary_300",
      },
      [".nextui-badge-root > span"]: {
        fontWeight: "400",
      },
      ["td"]: {
        // maxWidth: "70px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "normal",
      },
      "@media (max-width: 1200px)": {
        fontSize: "10px",
        ["th"]: {
          fontSize: "12px",
          minWidth: "110px",
          margin: "auto",
        },
      },
    },
  });
  const StyledColumn = styled("div", {
    position: "relative",
    width: "100%",
    display: "flex",
    // justifyContent: "space-between",
    transition: "all 300ms",
    ".headerContainer": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },
    margin: 0,
    padding: 0,
  });
  const IconContainer = styled("div", {
    display: "flex",
    justifyContent: "center",
    background: "$primary_200",
    borderRadius: "50px",
    width: "20px",
    height: "20px",
    position: "absolute",
    right: "8px",
    top: "12px",
    transition: "transform 300ms",
    transform: sortDirection === "ascending" ? "rotate(180deg)" : "",
  });
  const StyledActions = styled("div", {
    [`.${theme} &`]: {
      backgroundColor: "$primary_50",
      color: "$primary_400",
      minWidth: "fit-content",
      width: "50%",
      position: "unset",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      padding: "0.5rem",
      borderRadius: "30px",
      [".nextui-button-text"]: {
        gap: "0.4rem",
      },
    },
  });

  const ExcelButton = styled("button", {
    display: "flex",
    background: "white",
    color: "$excel",
    gap: "5px",
    borderRadius: "8px",
    padding: "10px",
    transition: "300ms",
    ["&:hover"]: {
      cursor: "pointer",
      background: "$neutral_100",
    },
  });

  const handlerAction = (key, item) => {
    actions[key].handle(item);
  };

  const renderCell = (item, columnKey) => {
    try {
      const column = columns.find((element) => element.uid === columnKey);
      switch (column.type) {
        case "id":
        case "text":
          return item[columnKey];
        case "textCenter":
          return (
            <p
              style={{ width: "fit-content", margin: "auto", fontSize: "14px" }}
            >
              {item[columnKey]}
            </p>
          );
        case "value":
          return `${new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(item[columnKey])}`;
        case "date":
          return item[columnKey].toLocaleDateString("en-GB");
        case "hour":
          return item["date"].toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
          });
        case "badge":
          return (
            <Badge
              css={{
                backgroundColor: `${item[columnKey].bgColor}`,
                color: `${item[columnKey].textColor}`,
              }}
              size="lg"
            >
              {item[columnKey].name}
            </Badge>
          );
        case "object":
          return item[columnKey].name;
        case "button":
          return (
            <Button
              text={item[columnKey].text}
              handler={item[columnKey].handler}
              disabled={item[columnKey].disabled}
              bgColor="primary_400"
              textColor="neutral_50"
            />
          );
        case "action":
          return (
            <Dropdown placement="bottom-left">
              <Dropdown.Trigger>
                <StyledActions className="theme">
                  <Icon name="MenuVertical" size="14"></Icon>
                </StyledActions>
              </Dropdown.Trigger>
              <Dropdown.Menu
                onAction={(key) => handlerAction(key, item)}
                aria-label="Table actions"
                css={{ minWidth: "100px" }}
              >
                {actions.map((element, i) => (
                  <Dropdown.Item
                    key={i}
                    onClick={() => element.handle(item)}
                    icon={<Icon name={element.icon}></Icon>}
                  >
                    {element.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          );
        case "rate":
          return (
            <ReactStars
              value={3}
              count={5}
              isHalf={true} // Permite medias estrellas
              edit={true} // Desactiva la interacción
              size={30}
              activeColor="#ffd7d0"
              onChange={ratingChanged}
            />
          );
        case "stepper":
          return <Movements data={item} />;
        case "country":
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Tooltip content={item[columnKey].name} color="primary">
                <ReactCountryFlag
                  countryCode={item[columnKey].code}
                  svg
                  style={{
                    width: "2.3em",
                    height: "2em",
                    borderRadius: "6px",
                  }}
                  title={item[columnKey].code}
                />
              </Tooltip>
            </div>
          );
        case "icon":
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                overflowX: "auto",
              }}
            >
              <Tooltip content={item[columnKey]?.text} color="primary">
                <Icon
                  name={item[columnKey]?.name}
                  color={item[columnKey]?.color}
                  size="25px"
                />
              </Tooltip>
            </div>
          );
        default:
          break;
      }
    } catch (error) {}
  };

  const handlerPageChange = () => {
    setTimeout(() => {
      const lastRow = tableRef.current.tBodies[0].lastChild.children;
      if (lastRow.length === 1) {
        lastRow[0].style.height = "0rem";
        lastRow[0].style.padding = "0rem";
      }
    }, 1);
  };

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  };

  const compareDates = (dateA, dateB) => {
    return parseDate(dateA) - parseDate(dateB);
  };
  function isDate(item) {
    const regex = /^\d{2}\/\d{2}\/\d{4}$/;
    return regex.test(item);
  }
  function onSort(column) {
    const columnKey = column.column;
    if (columnKey !== "actions") {
      let newSortDirection = "ascending";
      if (columnKey === sortColumn) {
        newSortDirection =
          sortDirection === "ascending" ? "descending" : "ascending";
      }
      setSortDirection(newSortDirection);
      setSortColumn(columnKey);
    }
  }

  useEffect(() => {
    if (sortDirection && sortColumn) {
      let sortedData = [...displayItems].sort((a, b) => {
        if (isDate(a[sortColumn])) {
          return compareDates(a[sortColumn], b[sortColumn]);
        }
        return a[sortColumn] > b[sortColumn] ? 1 : -1;
      });

      if (sortDirection === "descending") {
        sortedData = sortedData.reverse();
      }

      setDisplayItems(sortedData);
    }
  }, [sortDirection, sortColumn]);

  function handleSearchByColumn(columnKey, e) {
    const searchResults = items.filter((item) =>
      item[columnKey.uid].toLowerCase().includes(e.target.value.toLowerCase())
    );
    setDisplayItems(searchResults);
  }

  function handleGeneralSearch(input) {
    const filtered = items.filter((item) => {
      for (let key in item) {
        if (item[key]?.toString().toLowerCase().includes(input.toLowerCase())) {
          return true;
        }
      }
      return false;
    });
    setDisplayItems(filtered);
  }
  const debouncedSearch = debounce((input) => {
    handleGeneralSearch(input);
  }, 300);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "15px",
        }}
      >
        {searchBar && (
          <Input
            name="table searchbar"
            placeholder={"buscar..."}
            onChange={(e) => debouncedSearch(e.target.value)}
          />
        )}
        {excel && (
          <ExcelButton
            onClick={() => {
              exportToExcel(displayItems);
            }}
          >
            {" "}
            {translate("export_excel")} <Icon name="Excel" size="26" />
          </ExcelButton>
        )}
      </div>
      {/* Busqueda por columna */}
      {/* <div style={{display:'flex',flexWrap:'wrap' , justifyContent:'space-around', gap:'10px', maxWidth:'100%', margin:'auto'}}>
                {
                    columns.filter(columnKey=>columnKey.type!=='action').map(columnKey => {
                        return <input onChange={(e)=>handleSearchByColumn(columnKey, e)} placeholder={columnKey.name + '...'} style={{border:'1px solid grey', maxWidth:'100px'}}/>
                    })
                }
            </div> */}
      <Draggable ref={ref} onMouseDown={onMouseDown}>
        <StyledTable
          sortable
          // ref={ref}

          sortDescriptor={sortDirection}
          onSortChange={(column) => onSort(column)}
          shadow={false}
          className="theme"
          ref={tableRef}
          aria-label="General table"
          containerCss={{
            height: "100%",
            overflow: "visible",
          }}
          css={{
            height: "auto",
            minWidth: "100%",
            padding: "0rem",
            overflow: "hidden",
          }}
        >
          <Tab.Header columns={columns}>
            {(column) => (
              <Tab.Column
                allowsSorting
                align="center"
                key={column.uid}
                width={
                  column.type === "action" ||
                  column.type === "id" ||
                  column.type === "button"
                    ? 10
                    : 200
                }
                hideHeader={column.type === "action"}
              >
                <StyledColumn>
                  <div className={"headerContainer"}>{column.name}</div>
                  {column.uid === sortColumn && (
                    <IconContainer sortDirection={sortDirection}>
                      <Icon size="16px" name="ArrowUp" color="white" />
                    </IconContainer>
                  )}
                </StyledColumn>
              </Tab.Column>
            )}
          </Tab.Header>

          <Tab.Body items={displayItems}>
            {(item) => (
              <Tab.Row>
                {(columnKey) => (
                  <Tab.Cell className="break-word">
                    {renderCell(item, columnKey)}
                  </Tab.Cell>
                )}
              </Tab.Row>
            )}
          </Tab.Body>
          {displayItems.length > limitPage && pagination ? (
            <Tab.Pagination
              rounded
              align="center"
              rowsPerPage={limitPage}
              onPageChange={() => handlerPageChange()}
            />
          ) : (
            ""
          )}
        </StyledTable>
      </Draggable>
    </div>
  );
};

export default Table;
