import React, { useEffect, useState } from 'react'
import Table from '../../components/Table/Table'
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';
import { format, startOfMonth } from 'date-fns';
import Input from '../../components/Input/Input';
import DatesRangePickerModal from '../../components/DateRangePicker/DateRangePicker';
import { getTrackingsByUser } from '../../services/tracking.services';
import toast from '../../components/Toast/ToastTypes';
import { getServiceSplitRadication } from '../../services/quotation.services';
import SubscriptionModal from '../Services/TableServices/SubscriptionModal';
import { useNavigate } from 'react-router-dom';

const columns = [
    {name:'', uid:'actions', type:'action'},
    {name:'PO', uid:'po', type:'text'},
    {name:'Ref', uid:'ref', type:'text'},
    {name:'Incoterm', uid:'incoterm', type:'text'},
    {name:'Origin', uid:'origin', type:'text'},
    {name:'Current location', uid:'currentLocation', type:'text'},
    {name:'Destination', uid:'destination', type:'text'},
    {name:'Consignee', uid:'consignee', type:'text'},
    {name:'Shipper', uid:'shipper', type:'text'},
    {name:'ETD', uid:'etd', type:'text'},
    {name:'ETA', uid:'eta', type:'text'},
]

export default function AirShipmentsTable() {

    const token = useSelector((state) => state.user.token);
    const navigate = useNavigate()

    const [openDates, setOpenDates] = useState(false)
    const [dates, setDates] = useState({
        startDate: startOfMonth(new Date()),
        endDate: new Date(),
    });
    const [open, setOpen] = useState(false)
    const [currentService, setCurrentService] = useState({})

    const [items, setItems] = useState([])

    const actions = [
      {
          name: 'Rastreo',
          icon: 'Tracking',
          handle: (item) => {
            if (item.radication){
              navigate(`/tracking/service/?radication=${item.radication}&tab=chat`)
            } else {
              toast("error","El rastreo no tiene tag o radicado")
            }
          }
        },
        {
          name: "Subscribir",
          icon: "Mail",
          handle: (item) => {
            if (!item.radication){
              toast("error","El rastreo no tiene tag o radicado") 
            } else {
              setCurrentService({
                idRadication:item.radication,
                purchaseOrder:item.po,
              })
              setOpen(true)
            }
          },
        },
    ]

    useEffect(()=>{
        getTrackingsByUser(token, format(dates.startDate, "yyyy-MM-dd"), format(dates.endDate, "yyyy-MM-dd")).then(async res => {
            const trackingsData = await res.json();
            console.log("trackingsData :",trackingsData)
            const filteredDataAir = trackingsData.filter(item => item?.tracking?.awb_number)
            console.log("filteredDataAir :",filteredDataAir)
            const tableData = filteredDataAir?.map(e => {
                const tracking = e.tracking
                const key = 'awb_number'
                const item = tracking[key]
                console.log("tracking :",tracking)
                const lastApiResponse = tracking.last_api_response ? tracking.last_api_response[0] : ""
                const origin = lastApiResponse?.shipmentLegs?.portToPort?.originPort
                const destination = lastApiResponse?.shipmentLegs?.portToPort?.destinationPort
                const currentLocation = lastApiResponse?.currentLocationName
                return {
                    id:item.id,
                    po:tracking.purchase_order,
                    ref: item.containerNumber || tracking.mlbNumber || tracking.bookingNumber,
                    incoterm: item.incoterm,
                    origin,
                    destination,
                    currentLocation,
                    consignee:item.consignee,
                    shipper:item.shipper,
                    eta:item.promisedEta,
                    etd:item.promisedEtd,
                    radication:item.shipmentTags,
                }
            })
            setItems(tableData)
        })
    },[dates])
    console.log("items :",items)
    return (
    <div>
        <div>
          <div
            onClick={() => {setOpenDates(true)}}
            style={{ display: "flex", gap: "20px", width: "fit-content" }}
          >
            <Input label='Desde' value={format(dates.startDate, "dd/MM/yyyy")}/>
            <Input label='Hasta' value={format(dates.endDate, "dd/MM/yyyy")}/>
          </div>
          <DatesRangePickerModal
            aria-labelledby="modal-date-range"
            setDates={setDates}
            open={openDates}
            setOpen={setOpenDates}
          />
        </div>
        <Table columns={columns} items={items} actions={actions} searchBar={false} excel={false}/>
        <SubscriptionModal open={open} setOpen={setOpen} service={currentService}/>
    </div>
  )
}

