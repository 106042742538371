import React, { useEffect, useState } from 'react';
import { Radio, styled } from '@nextui-org/react';
import theme from '../../theme';

const RadioGroup = ({ options, onChange, value = '', orientation = 'vertical', label, required }) => {
    const [checked, setChecked] = useState('')

    const StyledRadioGroup = styled(Radio.Group, {
        [`.${theme} &`]: {
            ['.nextui-radio-label:not(:first-child)']: {
                marginTop: `${orientation === 'horizontal' ? '0rem' : '0.5rem'}`,
            },
            ['.nextui-radio-text']: {
                color: '$primary_500',
            },
            ['.nextui-radio--checked .nextui-radio-point:after']: {
                borderColor: '$primary_500'
            },
        }
    })

    const StyledLabel = styled('div', {
        [`.${theme} &`]: {
            color: '$primary_500',
            fontSize: '0.875rem',
            paddingLeft: '0.25rem',
            marginBottom: '0.375rem',
            ['span']: {
                color: '#ff676c'
            }
        }
    })

    const handleChange = (check) => {
        setChecked(check)
        onChange(check)
    }

    useEffect(() => {
        setChecked(value)
    }, [value]);

    return (
        <div>
            <StyledRadioGroup
                value={checked}
                onChange={handleChange}
                label={label && <StyledLabel>{label} {required ? <span>*</span> : ''}</StyledLabel>}
                orientation={orientation}
                aria-label='radio-group'
            >
                {
                    options.map((option, i) =>
                        <Radio key={i} value={option.value} size='sm'>{option.name}</Radio>
                    )
                }
            </StyledRadioGroup>
        </div>
    );
}

export default RadioGroup;
