import React, { useEffect } from "react";
import { useState } from "react";
import InputTable from "../../../../../components/InputTable/InputTable";
import { useFormContext } from "react-hook-form";


export default function NetAirFreight(){
    const methods = useFormContext()
    const {getValues, setValue} = useFormContext()
    const [tableTotal, setTableTotal] = useState(0)
    const [tableProfit, setTableProfit] = useState(0)

    const tableName = 'net_air_freight'
    const relatedTable = 'air_freight'

    function calculateTotal(kgCW , min) {
        const [weight, chargeableVol, currency] = getValues(['shipping_information.weight','shipping_information.chargeable_vol',`${relatedTable}.trm`])
        const parseWeight = weight ? Number(weight) : 0
        const parseChargeableVol = chargeableVol ? Number(chargeableVol) : 0
        const parseCurrency = currency ? Number(currency) : 0
        const parseKgCW = kgCW ? Number(kgCW) : 0
        let result = Math.max(parseWeight, parseChargeableVol) * parseKgCW
        return Math.max(result / parseCurrency, (min || 0) / parseCurrency);
    }
    function getIndex(str){
        const regex = /\[(.*?)\]/;
        const match = regex.exec(str); 
        if (match && match.length > 1) {
        const valorEntreCorchetes = match[1];
        return valorEntreCorchetes;
        } else {
        return null
        }
    }

    const columns = [
      {name:"Concepto", type:'text'},
      {name:"Minima", type:'number'},
      {name:"KgCW", type:'number'},
      {name:"Total", type:'number'},
      {name:"Profit", type:'number'},
    ]

    const defaultData = {
        itemId:1,
        tableName,
        Minima: '',
        "KgCW": 'Kg',
        Total: '',
        Profit: '',
    }

    useEffect(()=>{
        const items = methods.getValues(tableName)
        setTotalValues(items, 0)
        setTotalValues(items, 1)
    })

    function setTotalValues(items,index){
        const {Concepto, KgCW, Minima } = items[index]
        const rowTotal = calculateTotal(KgCW , Minima)
        let rowProfit = 0;
        if (Concepto === 'Flete'){
            rowProfit = getValues(`${relatedTable}.freight_total`) - rowTotal
        } else if (Concepto === 'FS'){
            rowProfit = getValues(`${relatedTable}.surcharge_total`) - rowTotal
        }
        setValue(`${tableName}[${index}].Total`, rowTotal>0? rowTotal?.toFixed(2) : 0)
        setValue(`${tableName}[${index}].Profit`, rowProfit?.toFixed(2))
        
        let tableTotalCount = 0;
        let tableTotalProfit = 0;
        items?.map(row => {
            const rowTotal = calculateTotal(row['KgCW'] , row.Minima)
            tableTotalCount += rowTotal>0? rowTotal : 0
            tableTotalProfit += Number(row.Profit)
        })
        setTableTotal(tableTotalCount)
        setTableProfit(tableTotalProfit)
        setValue(tableName + 'Total', tableTotalCount)
    }

    React.useEffect(() => {
        const subscription = methods.watch((value, { name, type }) => {
            if(name?.startsWith(relatedTable)){
                const relatedItems = methods.getValues(relatedTable)
                const tableItems = methods.getValues(tableName)
                const netValues = tableItems?.map((item)=>{
                    return {
                        ...item,
                        currency:relatedItems.currency,
                        freight_total:relatedItems.freight_total,
                        surcharge_total:relatedItems.surcharge_total,
                    }
                })
                methods.setValue(tableName, netValues)
            }

            if (name.startsWith(tableName) || name.startsWith(relatedTable)){
                const items = methods.getValues(tableName)
                let index = getIndex(name)
                if (
                    name === `${tableName}[${index}].KgCW` ||
                    name === `${tableName}[${index}].Minima` ||
                    name === `${relatedTable}.freight_total` ||
                    name === `${relatedTable}.surcharge_total`
                ){
                    if (name === `${relatedTable}.freight_total`){ index = 0}
                    if (name === `${relatedTable}.surcharge_total`){ index = 1}
                    setTotalValues(items,index)
                }
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    return <div style={{marginTop:'20px'}}>
        <h3>Netas carga aerea :</h3>
        <InputTable tableName={tableName} itemType={tableName} columns={columns} defaultData={defaultData} enableAddDelete={false}/>
        <div style={{display:'flex',gap:'20px'}}>
            <h4>Total en USD : {tableTotal?.toFixed(2)}</h4>
            <h4>Total Profit : {isNaN(tableProfit)? 0.000 :tableProfit?.toFixed(2)}</h4>
        </div>
    </div>

}