import React, { useEffect, useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../../../theme";
import { useFormContext, Controller } from "react-hook-form";
//Componentes
import PageTitle from "../../../../components/PageTitle/PageTitle";
import RadioGroup from "../../../../components/RadioGroup/RadioGroup";
import { useTranslation } from "react-i18next";

const StyledNegotiation = styled("div", {
  [`.${theme} &`]: {
    margin: "3rem 8rem 0rem 8rem",
    padding: "3rem 8rem",
    backgroundColor: "$neutral_50",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    span: {
      color: "$primary_200",
    },
    [".textSelect"]: {
      span: {
        color: "$primary_500",
      },
    },
    [".customCheckboxGroup"]: {
      [".nextui-checkbox-mask::after"]: {
        backgroundColor: "$primary_300",
      },
    },
    ["main"]: {
      [".nextui-radio-container"]: {
        width: "100%",
      },
      [".nextui-radio-text"]: {
        width: "100%",
      },
    },
  },
});

function Negotiation({
  negotiation,
  commerceType,
  businessTerms,
  setDisabledContinue,
}) {
  const [_businessTerms, _setBusinessTerms] = useState([]);
  const { t: translate } = useTranslation("Quotation");

  const nameForm = "negotiation";
  const { control, getValues, watch } = useFormContext({
    defaultValues: {
      [nameForm]: {
        businessTerm: negotiation?.businessTerm || 1,
      },
    },
  });

  useEffect(() => {
    const commerceType = getValues("commerce.commerceType");
    const shippingMethod = getValues("commerce.shippingMethod");
    // 1:aereo
    // 2:maritimo
    // 3:terrestre
    let allowedTerms = [];
    let filteredBT = businessTerms;

    if (commerceType === "import" && [1, 2, 3].includes(shippingMethod.value)) {
      switch (shippingMethod.value) {
        case 1:
          allowedTerms = ["EXW", "FCA", "CPT", "CIP", "DAP", "DDP"];
          break;
        case 2:
          allowedTerms = [
            "EXW",
            "FCA",
            "FAS",
            "FOB",
            "CIF",
            "CFR",
            "CPT",
            "CIP",
            "DEQ",
            "DDP",
          ];
          break;
        case 3:
          allowedTerms = ["EXW", "FCA", "CPT", "CIP", "DAF", "DDU", "DDP"];
          break;
      }
      filteredBT = businessTerms.filter((bt) => allowedTerms.includes(bt.name));
    } else if (
      commerceType === "export" &&
      [1, 2, 3].includes(shippingMethod.value)
    ) {
      switch (shippingMethod.value) {
        case 1:
          allowedTerms = ["EXW", "FCA", "CPT", "CIP", "DAP", "DDP"];
          break;
        case 2:
          allowedTerms = ["EXW", "FCA", "FAS", "FOB", "CFR", "CIF"];
          break;
        case 3:
          allowedTerms = ["FCA", "CPT", "CIP", "DAF", "DDU", "DDP"];
          break;
      }
      filteredBT = businessTerms.filter((bt) => allowedTerms.includes(bt.name));
    } else if (commerceType === "customs") {
      allowedTerms = ["EXW", "DAP", "DDP", "CIF"];
      filteredBT = businessTerms.filter((bt) => allowedTerms.includes(bt.name));
    }

    const data = filteredBT.map((businessTerm) => {
      return {
        value: businessTerm.id,
        name: (
          <span>
            <b>{businessTerm.name}: </b>
            {businessTerm.description}
          </span>
        ),
      };
    });

    _setBusinessTerms(data);
    // setValue(`${nameForm}.businessTerm`, data[0]?.value)
  }, [businessTerms]);

  useEffect(() => {
    if (!getValues("negotiation.businessTerm")) {
      setDisabledContinue(true);
    } else {
      setDisabledContinue(false);
    }
  }, [watch()]);

  return (
    <StyledNegotiation>
      <PageTitle>{translate("business-term")}</PageTitle>

      <span>
      {translate("business_alternatives")}{" "}
        <b>
          {translate("business_terms_for")}{" "}
          {commerceType === "import" ? "importación" : "exportación"}
        </b>{" "}
      {translate("business_terms_for_details")}
      </span>

      <div className="textSelect">
        <span>{translate("select_negotiation_term")}</span>
      </div>

      <main>
        <Controller
          control={control}
          name={`${nameForm}.businessTerm`}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              onChange={(value) => {
                onChange(value);
              }}
              name={`${nameForm}.businessTerm`}
              value={value}
              options={_businessTerms}
            ></RadioGroup>
          )}
        ></Controller>
      </main>
    </StyledNegotiation>
  );
}
export default Negotiation;
