import { getUserAuth, login_post } from "../services/auth.services"

/**
 * Constants
*/
const initialData = {
    loggedIn: false,
    fetching: false,
    token: "",
    error: "",
    roles: [],
}

/**
 * Action Types
 */

const LOGIN = "LOGIN"
const LOGIN_SUCCESS = "LOGIN_SUCCESS"
const LOGIN_ERROR = "LOGIN_ERROR"
const LOG_OUT = "LOG_OUT"

/**
 * Reducer
 */
const reducer = (state = initialData, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, fetching: true }
        case LOGIN_SUCCESS:
            return { ...state, ...action.payload, fetching: false, loggedIn: true, error: "", roles: action.payload.roles }
        case LOGIN_ERROR:
            return { ...state, fetching: false, error: action.payload }
        case LOG_OUT:
            return { ...initialData, fetching: false, loggedIn: false, error: "" }
        default:
            return state;
    }
};

export default reducer;

/**
 * Actions
 */
export const loginAction = (credentials) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LOGIN,
        });
        const user = await login_post(credentials);
        const data = await getUserAuth(user.token)
        if (user.token) {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: {
                    token: user.token,
                    idUser: user.id_user,
                    roles: [data.user.role_id]
                }
            })
        } else {
            dispatch({
                type: LOGIN_ERROR,
                payload: user.message
            })
        }
        //console.log('state :',getState())
        saveStorage(getState());
    } catch (error) {
        dispatch({
            type: LOGIN_ERROR,
            payload: error
        })
    }
}


export const restoreSessionAction = () => (dispatch, getState) => {
    let storage = localStorage.getItem("storage");
    storage = JSON.parse(storage);
    if (storage && storage.user.loggedIn) {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: storage.user,
        });
    }
};

export const loginWithTokenAction = (user) => (dispatch, getState) => {
    dispatch({
        type: LOGIN,
    });
    if (user.token) {
        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                token: user.token,
                idUser: user.id_user,
                roles: [user.role_id]
            }
        })
    } else {
        dispatch({
            type: LOGIN_ERROR,
            payload: user.message
        })
    }
    // console.log('state :',getState())
    saveStorage(getState());
};

export const logOutAction = () => (dispatch, getState) => {
    dispatch({
        type: LOG_OUT,
    });
    localStorage.removeItem("storage");
};


/**
 * Aux Functions
 */

const saveStorage = (storage) => {
    localStorage.storage = JSON.stringify(storage);
};