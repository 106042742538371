import React from 'react'
import Table from '../../../../components/Table/Table'

export default function ExceptionsTable() {

  const columns = [
    { name: 'Latest update', uid: 'last_update', type: 'action' },
    { name: 'Exception', uid: 'exception', type: 'text' },
    { name: 'Detail', uid: 'detail', type: 'text' },
    { name: 'Location', uid: 'location', type: 'text' },
    { name: 'Tracking #', uid: 'tracking', type: 'text' },
  ]
  const mockData = [
    {
      key:1,
      last_update:"04/04 05:51 AM",
      exception:"Vessel ETD changed",
      detail:"ETD changed from 04/07/2024 to 04/09/2024",
      location:"Veracruz",
      tracking:"TS-5M528V",
    },
    {
      key:1,
      last_update:"04/04 11:01 AM",
      exception:"Extended container storage time",
      detail:"Discharged from vessel on 03/19/2024 ,still in port",
      location:"Buenaventura",
      tracking:"TS-5M2U3S",
    },
  ]
  return (
    <Table columns={columns} items={mockData} excel={false} searchBar={false}/>
  )
}