
import { URL } from "../constants";

export const getOperations = async ({ token, id }) => {
    console.log("token :",token)
    const getURL = id ? `${URL}operations/operations/${id}/` : `${URL}operations/operations/`
    const response = await fetch(getURL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token "+ token,
        }
    })
    return response
}
export const getOperationsSplit= async (token, page, page_size, search) => {
    let endpoint = `${URL}operations/operations/?page=${page}&page_size=${page_size}`
    if (search){
        endpoint = `${URL}operations/operations/?page=${page}&page_size=${page_size}&search=${search}`
    }
    const response = await fetch(endpoint, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}


export const postOperation = async (data, token) => {
    const response = await fetch(`${URL}operations/operations/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status !== 201) {
        throw error
    }
    return response;
}
export const editOperation = async (id,data, token) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    return response;
}

export const deleteOperation = async (id, token) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    if (response.status !== 204) {
        throw error
    }
    return response;
}

export const getOperationTypes = async (token) => {
    const response = await fetch(`${URL}operations/operation_types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getRoles = async (token) => {
    const response = await fetch(`${URL}roles/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getActivities = async (token) => {
    const response = await fetch(`${URL}operations/activities/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getOperationActivities = async (token) => {
    const response = await fetch(`${URL}operations/operation_activities/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getStatuses = async (token) => {
    const response = await fetch(`${URL}operations/statuses/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getOperationStatuses = async (token,id) => {
    let url = `${URL}operations/operation_statuses/`
    if(id){
        url = `${URL}operations/operation_statuses/${id}/`
    }
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const createOperationStatus = async (token,data) => {
    const response = await fetch(`${URL}operations/operation_statuses/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body:JSON.stringify(data)
    })
    return response
}

export const editState = async (token, id, data) => {
    const response = await fetch(`${URL}operations/operation_statuses/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    return response;
}

export const deleteOperationStatus = async (token,id) => {
    const response = await fetch(`${URL}operations/operation_statuses/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSubStatuses = async (token) => {
    const response = await fetch(`${URL}operations/operation_sub_statuses/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const createSubStatus = async (token,data) => {
    const response = await fetch(`${URL}operations/operation_sub_statuses/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body:JSON.stringify(data)
    })
    return response
}

export const deleteSubStatus = async (token,id) => {
    console.log("id :",id)
    const response = await fetch(`${URL}operations/operation_sub_statuses/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response
}

export const setSubState = async (token, operationId,radication, subStateId, date, emails,emailsCopy, subject, notes, files) => {
    const formData = new FormData();
    formData.append("operation", operationId);
    formData.append("radication", radication);
    formData.append("operation_sub_status", subStateId);
    formData.append("body", notes);
    formData.append("date", date);
    formData.append("subject", subject);
    console.log("emails :",emails)
    emails?.forEach((item, i) => {
        if (item){
            formData.append('to', item?.value)
        }
    });
    emailsCopy?.lenght && emailsCopy?.forEach((item, i) => {
        if (item){
            formData.append('cc', item?.value)
        }
    });
    files.forEach((file, index) => {
        formData.append(`attached`, file);
    });
    const headers = new Headers();
    headers.append('Authorization', 'Token ' + token);

    const response = await fetch(`${URL}operations/operation_sub_statuses_change/`, {
        method: "POST",
        headers: headers,
        body: formData
    });
    return response;
}

export const sendEmail = async (data, token) => {
    const formData = new FormData();
    console.log("data :",data)
    Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
            data[key].forEach(item => {
                if (item){
                    formData.append(key, item)
                }
            });
        } 
        else {
            if (data[key]){
                formData.append(key, data[key]);
            }
        }
    });
    const headers = new Headers();
    headers.append('Authorization', 'Token ' + token);
    const response = await fetch(`${URL}operations/send-email/`, {
        method: "POST",
        headers: headers,
        body: formData
    });
    if (response.status === 200) {
        return response;
    } else {
        throw new Error("Ha habido un error");
    }
}


export const editSubState = async (token, relationId, data) => {
    const response = await fetch(`${URL}operations/operation_sub_statuses_change/${relationId}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    return response;
}

export const getSubStatusesOperations = async (token,id) => {
    let endpoint = `${URL}operations/operation_sub_statuses_change/`
    if (id){
        endpoint = `${URL}operations/operation_sub_statuses_change/${id}/`
    }
    const response = await fetch(endpoint, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getAgents = async (token) => {
    const response = await fetch(`${URL}operations/agents/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getCoordinators = async (token) => {
    const response = await fetch(`${URL}operations/coordinators/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getLogEntries = async (token) => {
    const response = await fetch(`${URL}operations/log_entries/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const postLogEntries = async (data, token) => {
    const response = await fetch(`${URL}operations/log_entries/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}
export const editLogEntry = async (data, id, token) => {
    const response = await fetch(`${URL}operations/log_entries/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteLogEntry = async (id, token) => {
    const response = await fetch(`${URL}operations/log_entries/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    return response;
}

export const addOperationActivity = async ({ data, id, token }) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}
export const editOperationActivity = async ({ data, id, token }) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "PATCH",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.status === 201 || response.status === 200) {
        return response.json();
    } else if (response.status === 400) {
        const errorData = await response.json();
        throw new Error(errorData);
    } else {
        throw new Error("Ha habido un error");
    }
}

export const deleteActivity = async (id, token) => {
    const response = await fetch(`${URL}operations/operations/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    if (response.status !== 204) {
        throw error
    }
    return response;
}


export const getEmails = async (token, radication) => {
    const response = await fetch(`${URL}operations/email-records/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getSubject = async (token, radication) => {
    const response = await fetch(`${URL}operations/generate-subject/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getDOs = async (token) => {
    const response = await fetch(`${URL}quotation/delivery-order/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const deleteDO = async (id, token) => {
    const response = await fetch(`${URL}quotation/delivery-order/${id}/`, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    });
    if (response.status !== 204) {
        throw error
    }
    return response;
}

export const getLeadTimes = async (token) => {
    const response = await fetch(`${URL}service-times/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token "+ token,
        }
    })
    return response
}
export const postOrEditLeadTimes = async (token, data) => {
    console.log("data :",data)
    const submitData = {...data}
    delete submitData.id;
    if (data.id){
        console.log("PATCH")
        const response = await fetch(`${URL}service-times/${data.id}/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token "+ token,
            },
            body: JSON.stringify(submitData)
        })
        return response
    } else {
        console.log("POST")
        const response = await fetch(`${URL}service-times/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Token "+ token,
            },
            body: JSON.stringify(submitData)
        })
        return response
    }
    
}