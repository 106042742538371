import React from 'react'
import { useState, useEffect } from 'react';
import { useFetch } from '../../hooks/useFetch';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ViewsHandler from '../../components/ViewsHandler/ViewsHandler';
import { Collapse } from '@nextui-org/react';
import { styled } from '@nextui-org/react';
import QuotationsTable from './QuotationsTable';

import { URL } from '../../constants';
import QuoteForm from './QuoteForm/QuoteForm';
import PreLiquidation from './PreLiquidation/PreLiquidation';
import OfferRequirements from './OfferRequirements/OfferRequirements';
import VersionControl from './VersionControl/VersionControl';
import Notes from './Notes';
import Instructions from './Instructions/Instructions';

import { getUsers } from '../../services/user.services';
import { getSolutions, getSubTypes, getOffices, getReasons, getStatuses, getQuoteData } from '../../services/quotations.services';
import { getPreliquidations } from '../../services/preliquidation.services';
import PricingDashboard from './PricingDashboard/PricingDashboard';

import { getPreliquidationCosts } from '../functions';
import toast from '../../components/Toast/ToastTypes';
import { useTranslation } from "react-i18next";
import { parseISO } from 'date-fns';


const StyledContainer = styled('div', {
    marginBottom: '50px',
    ['header']: {
        backgroundColor: '$neutral_50',
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        ['.title']: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    },
    ['.css-1a4cg4j-MuiButtonBase-root-MuiTab-root']: {
        fontFamily: 'inherit',
        color: '$primary_300',
        textTransform: 'none'
    },
    ['.Mui-selected']: {
        fontWeight: '600',
        color: '$primary_300 !important'
    },
    ['.MuiTabs-indicator']: {
        backgroundColor: '$secondary_300'
    }
})



const StyledCollapse = styled(Collapse, {
    padding:'20px',
    backgroundColor:"$neutral_100",
    borderRadius:'8px',
    [".nextui-collapse-title"]: {
      color: "$primary_300",
    },
  });

export default function ManageQuotes() {
    const [searchParams] = useSearchParams()
    const { t: translate } = useTranslation("ManageQuotes");

    const tabs = [
        {
            id: 'form',
            value: 'form',
            label:translate("quotation-form")
        },
        {
            id: 'pre-liquidation',
            value: 'pre-liquidation',
            label: translate("preliquidation"),
        },
        {
            id: 'offer requirements',
            value: 'offer requirements',
            label: translate("offer_requirements"),
        },
        {
            id: 'version-control',
            value: 'version-control',
            label: translate("version-control"),
        },
        {
            id: 'notes',
            value: 'notes',
            label: translate("notes"),
        },
        {
            id: 'instructions',
            value: 'instructions',
            label: translate("shipment_instructions"),
        },
    ]

    
    const [tabValue, setTabValue] = useState(searchParams.get('tab'))
    const [tabId, setTabId] = useState(tabs.findIndex(tab => tab.value === searchParams.get('tab')));
    const [currentQuote, setCurrentQuote] = useState('')
    const [currentQuoteData, setCurrentQuoteData] = useState('')

    const [showTable, setShowTable] = useState(false)

    const [items, setItems] = useState([])
    const token = useSelector(state => state.user.token)
    const { data, loading } = useFetch(URL + 'quotation/quotations/', { 'Authorization': 'Token ' + token });

    const navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
        setTabId(tabs.findIndex(tab => tab.value === newValue))
        navigate(`/manageQuotes/?tab=${newValue}`)
    };

    useEffect(() => {
        const selectId = searchParams.get("selectId")
        if (selectId && !currentQuote) {
            getQuoteData(token, selectId).then(async res => {
                const quoteData = await res.json();
                setCurrentQuote({
                    radication:quoteData?.radication,
                    id:selectId,
                    preliquidationId:quoteData?.preliquidation_ids.length ? quoteData.preliquidation_ids[0] : ""
                })
            }).catch(()=> toast("warn", `Error al seleccionar la liquidacion de id : ${selectId}`))
        }
    }, [])
    useEffect(()=>{
        if (currentQuote.id){
            getQuoteData(token, currentQuote.id).then(async res => {
                const quoteData = await res.json();
                setCurrentQuoteData(quoteData)
            })
        } else {setCurrentQuoteData({})}
    },[currentQuote])

    const getData = () => {
        const asyncTasks = [
            getSubTypes(token),
            getSolutions(token),
            getOffices(token),
            getUsers(token),
            getStatuses(token),
            getReasons(token),
            getPreliquidations(token),
        ];
        Promise.all(asyncTasks)
            .then(async responses => {
                const subtypesData = await responses[0].json();
                const servicesData = await responses[1].json();
                const officesData = await responses[2].json();
                const clientsData = await responses[3].json();
                const statusesData = await responses[4].json();
                const reasonsData = await responses[5].json();
                const preliquidationsData = await responses[6].json();

                const formatData = await data?.map(quote => {
                    const lastPreliquidationId = Math.max(quote.preliquidation_ids)
                    const lastPreliquidation = preliquidationsData.find(prel => prel.id === lastPreliquidationId)
                    
                    const {totalCost, totalProfit} = getPreliquidationCosts(lastPreliquidation)

                    const client = clientsData.find(e => e.id === quote.client)
                    return {
                        id: quote.id,
                        radication: quote.radication,
                        client: client?.name + ' ' + client?.last_name,
                        service: servicesData.find(e => e.id === quote.service)?.name,
                        subtype: subtypesData.find(e => e.id === quote.subtype)?.name,
                        office: officesData.find(e => e.id === quote.office)?.name,
                        reason: reasonsData.find(e => e.id === quote.reason)?.name,
                        cost: '$' + totalCost?.toFixed(2),
                        profit: '$' + totalProfit?.toFixed(2),
                        status: statusesData.find(e => e.id === quote.status)?.name,
                        prel: quote.preliquidation_ids?.length ? {name: "CheckSquare", color:'green'} :{name: "Close", color:'grey'},
                        preliquidationId: quote.preliquidation_ids ? quote.preliquidation_ids[0] : '',
                        created_at: quote.created_at,
                    };
                });

                const uniqueData = []

                formatData?.forEach((item => {
                    const sameRadication = formatData.filter(quote => quote.radication === item.radication);
                    sameRadication.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    if (!uniqueData.find(item => item.radication === sameRadication[0].radication)) {
                        sameRadication.forEach((version, index) => {
                            version.versionNumber = index === 0 ? 'Opción 1' : `Versión ${index + 1}`;
                        });
                        sameRadication[0].versions = sameRadication;
                        sameRadication[0].versionsAmount = sameRadication.length;
                        sameRadication[0].latestVersion = sameRadication[sameRadication.length - 1];
                        uniqueData.push(sameRadication[0]);
                    }
                }));
                const compareDates = (a, b) => {
                    const dateA = parseISO(a.created_at);
                    const dateB = parseISO(b.created_at);
                    if (dateA < dateB) {
                      return 1;
                    }
                    if (dateA > dateB) {
                      return -1;
                    }
                    return 0;
                  };
                const sortedUniqueData = uniqueData.sort(compareDates);
                setItems(sortedUniqueData);
            })
            .catch(error => console.error('Error:', error));
    }
    useEffect(() => {
        getData()
    }, [loading, data]);

    return (
        <StyledContainer>
            <header>
                <StyledCollapse orientation="vertical" title={translate("view_liquidations")} expanded={showTable} onChange={() => showTable?setShowTable(false):setShowTable(true)}>
                    <QuotationsTable currentQuote={currentQuoteData} setCurrentQuote={setCurrentQuote} setShowTable={setShowTable} items={items} loading={loading} />
                </StyledCollapse>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    textColor="secondary"
                    indicatorColor="secondary"
                    aria-label="service"
                >
                    {
                        tabs.map((tab, i) =>
                            <Tab key={i} value={tab.value} label={tab.label} />
                        )
                    }
                </Tabs>
            </header>
            <div style={{ padding: '30px' }}>
                {
                    currentQuote ?
                        <p style={{ margin: '10px', fontSize: '16px' }}>{translate("select_liquidation")} {currentQuote?.radication} (ID : {currentQuote.id})</p> :
                        <p style={{ margin: '10px', fontSize: '16px' }}>{translate("no_liquidation_selected")}</p>
                }
                <ViewsHandler
                    activeView={tabId}
                >
                    <QuoteForm currentQuote={currentQuote} setCurrentQuote={setCurrentQuote} items={items} setItems={setItems} />
                    <PreLiquidation currentQuote={currentQuote.id} setShowTable={setShowTable}/>
                    <OfferRequirements currentQuote={currentQuote} setCurrentQuote={setCurrentQuote} />
                    <VersionControl currentQuote={currentQuote} setCurrentQuote={setCurrentQuote} />
                    <Notes currentQuote={currentQuote} setCurrentQuote={setCurrentQuote}/>
                    <Instructions currentQuote={currentQuote.id} />
                </ViewsHandler>
            </div>
        </StyledContainer>
    )
}
