import React, {useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import Table from '../../components/Table/Table'
import { getWaitingOp } from '../../services/quotations.services';
import { getPreliquidations } from '../../services/preliquidation.services';
import { getOffers } from '../../services/quotations.services';
import { getBusinessTerms } from '../../services/businessTerms.services';
import format from 'date-fns/format';
import { Loading } from '@nextui-org/react';
import { getUsers } from '../../services/user.services';
import { getPreliquidationCosts } from '../functions';
import { parseISO } from 'date-fns';

export default function PendingTable({setRadication, setShowPending}) {
    const token = useSelector(state=>state.user.token)
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(true)

    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'ID radicado', uid: 'idRadication', type: 'text' },
        { name: 'Fecha de creacion', uid: 'created_at_date', type: 'text' },
        { name: 'Cliente', uid: 'client', type: 'text' },
        { name: 'Origen', uid: 'origin', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'Incoterm', uid: 'incoterm', type: 'text' },
        { name: 'Costo', uid: 'value', type: 'text' },
    ];

    const actions = [
        {
            name: 'Iniciar Operacion',
            icon: 'Tracking',
            handle: (item) => { 
                setRadication(item.idRadication);
                setShowPending(false);
            }
        },
    ]

    useEffect(()=>{
        setLoading(true)
        const asyncTasks = [getWaitingOp(token),  getPreliquidations(token), getBusinessTerms(token), getUsers(token)]
        Promise.all(asyncTasks).then(async res => {
            const waitingOpData = await res[0].json();
            const preliquidationsData = await res[1].json();
            const incotermsData = await res[2].json();
            const usersData = await res[3].json();
            console.log("waitingOpData :",waitingOpData)
            waitingOpData.forEach((op,i) => {
                const lastPreliquidationId = Math.max(op.preliquidation_ids)
                const lastPreliquidation = preliquidationsData.find(prel => prel.id === lastPreliquidationId)
                const {totalCost} = getPreliquidationCosts(lastPreliquidation)
                waitingOpData[i].cost = totalCost;
            })
            
            const compareDates = (a, b) => {
                const dateA = parseISO(a.created_at);
                const dateB = parseISO(b.created_at);
                if (dateA < dateB) {
                  return 1;
                }
                if (dateA > dateB) {
                  return -1;
                }
                return 0;
              };
            const sortedTableData = waitingOpData.sort(compareDates);

            const tableData = sortedTableData.map(op => {
                const fecha = parseISO(op.created_at);
                const fechaFormateada = format(fecha, 'dd/MM/yyyy');
                return ({
                    id: op.id.toString(),
                    idRadication: op.radication,
                    created_at_date:fechaFormateada,
                    client:usersData.find(user => user.id === op.client)?.name + ' ' + usersData.find(user => user.id === op.client)?.last_name,
                    incoterm:incotermsData.find(term => term.id===op.incoterm)?.name,
                    origin: op.shipping.origin_boarding_terminal,
                    destination: op.shipping.destination_boarding_terminal,
                    value: '$' + op.cost.toFixed(2)
                });
            })
            setItems(tableData)
            setLoading(false)
        }).catch(err => {console.log('error :',err);setLoading(false)})
    },[])

    return (
        <>
            {
                loading?<Loading/>:
                items.length?
                <Table columns={columns} items={items} actions={actions} excel={false}></Table>:
                <p style={{color:'green', margin:'20px'}}>No hay operaciones pendientes!</p>
            }
        </>
    )
}