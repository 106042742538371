
import React, { useState, useEffect } from 'react';
import Icon from '../../Icon/Icon';
import { URL } from "../../../constants";
import toast from '../../Toast/ToastTypes';

const modalContainer = {
    backgroundColor: "#FFF",
    width: "400px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "400",
    borderRadius: "10px",
    boxShadow: "1px 1px 7px #3D509A",
    border: "1px solid #3D509A"
};

const header = {
    backgroundColor: "#3D509A",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopRightRadius: "6px",
    borderTopLeftRadius: "6px",
    padding: "10px",
    color: "#FFF"
};

const title = {
    textAlign: "center",
    width: "100%"
};

const icon = {
    display: "contents",
    cursor: "pointer"
};

const form = {
    margin: "20px",
    display: "flex",
    flexDirection: "column",
    gap: "20px",
};

const rolContainer = {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    border: "1px solid #CCC",
    padding: "10px",
    height: "232px",
    overflowY: "auto"
};

const rol = {
    backgroundColor: "#EEE",
    borderRadius: "5px",
    display: "flex",
    gap: "5px"
};

const radio = {
    visibility: "hidden"
};

const button = {
    width: "60%",
    margin: "auto",
    borderRadius: "5px",
    padding: "5px 0px",
    border: "none",
    backgroundColor: "#3D509A",
    cursor: "pointer",
    color: "#FFF"
};

const label = {
    width: "100%",
    cursor: "pointer",
    padding: "5px 0px"
};

const noData = {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #CCC",
    height: "232px",
    padding: "10px",
};

function DataModal(props) {

    const { data, titleText, buttonText, close, id, endpoint, methodType, headers } = props;
    const [roles, setRoles] = useState(null);
    const [option, setOption] = useState(null);

    const selectRol = (element) => {
        [...element].map(item => {
            if (item.querySelector("input[type=radio]").checked) {
                item.style.backgroundColor = "#3D509A"
                item.style.color = "#FFF"
                setOption(item.querySelector("input[type=radio]").value)
            }
            else {
                item.style.backgroundColor = "#EEE"
                item.style.color = "#3D509A"
            }
        })
    };

    const send = (form) => {
        form.preventDefault();

        if (option != null) {

            fetch(`${URL}${endpoint}`, {
                method: methodType,
                headers: headers,
                body: JSON.stringify({ id: id, optionSelected: option })
            })
                .then(response => response.json())
                .then(response => toast('success', response.message));

            close();
        }
    };

    const handleOptionClick = (event) => {
        event.stopPropagation();
        selectRol(event.currentTarget.parentElement.children);
    };

    useEffect(() => {
        if (data != null) {
            setRoles(
                <div style={rolContainer}>
                    {data.map(value => (
                        <div style={rol} onClick={element => handleOptionClick(element)}>
                            <input type="radio" name="rol" id={`rol${value.id}`} style={radio} value={value.id} />
                            <label htmlFor={`rol${value.id}`} style={label}>{value.name}</label>
                        </div>
                    ))}
                </div>
            );
        }
        else {
            setRoles(
                <div style={noData}>
                    <p>No hay datos disponibles</p>
                </div>
            );
        }
    }, [data]);

    return (
        <div style={modalContainer}>
            <div style={header}>
                <p style={title}>{titleText}</p>
                <div style={icon} onClick={close} title="Cerrar">
                    <Icon name="CloseModal" color="#FFF" />
                </div>
            </div>
            <form method="post" style={form} onSubmit={form => send(form)}>
                {roles}
                <input type="submit" value={buttonText} style={button} />
            </form>
        </div>
    );
}

export default DataModal;   