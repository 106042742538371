import React, { useEffect , useState} from 'react'
import { styled } from '@nextui-org/react'
import SmallField from './Fields/SmallField'
import { useFormContext, Controller } from 'react-hook-form'
import SmallSelect from './Fields/SmallSelect'
import { ROLES } from '../../../../constants'
import Icon from '../../../../components/Icon/Icon'
import logo from "../../../../assets/img/val-big.png"
import InputFileCard from '../../../../components/InputFileCard/InputFileCard'
import { uploadPreliquidationExcel } from '../../../../services/preliquidation.services'
import { useSelector } from 'react-redux'

const Container = styled("div",{
    margin:'20px',
    display:'grid',
    gap:'20px 60px',
    gridTemplateColumns:'repeat(2, 1fr)',
    'h3':{
        color:'#3D509A'
    },
    'field':{
        display:'flex',
    },
    'input':{
        fontSize: '13px',
        padding: '4px',
        margin:'5px',
        border: '1px solid #ccc',
        '&:focus':{
            outline: '1px solid #ccc',
            border: '1px solid #ccc',
        }
    },
    '.subtitle':{
        display: "flex",
        justifyContent: "space-between",
        gridColumn:'span 2',
        alignItems:'center',
        padding:'20px 0',
        borderBottom: "3px solid $primary_300",
    }
})

export default function General({usersData, rfqData, trigger}) {
    const token = useSelector(state=>state.user.token)
    const { getValues,setValue,control,register,watch} = useFormContext()
    const clients = usersData.filter(user => [ROLES.Client,ROLES.User].includes(user.role_id))
    const operators = usersData.filter(user => [ROLES.Admin,ROLES.Commercial,ROLES.Pricing,ROLES.Operations].includes(user.role_id))
    const salesUsers = usersData.filter(user => [ROLES.Commercial].includes(user.role_id))

    const nameForm = "general"

    useEffect(() => {
        const subscription = watch((value, { name, type }) =>{
            // console.log("name :",value)
            if (name === `${nameForm}.preparedFor`){
                const client = clients?.find(user => user.id === value.general?.preparedFor?.value)
                setValue(`${nameForm}.clientEmail`,client?.email)
                setValue(`${nameForm}.clientNumber`,client?.phone)
                trigger()
            }
            if (name === `${nameForm}.preparedBy`){
                const operator = operators.find(user=>user.id === value.general?.preparedBy?.value)
                setValue(`${nameForm}.preparedByPhone`, operator?.phone)
                setValue(`${nameForm}.preparedByEmail`, operator?.email)
                trigger()
            }
            if (name === `${nameForm}.salesContact`){
                const salesUser = salesUsers.find(user=>user.id === value.general?.salesContact?.value)
                setValue(`${nameForm}.salesPhone`, salesUser?.phone)
                setValue(`${nameForm}.salesEmail`, salesUser?.email)
                trigger()
            }
        });
        return () => subscription.unsubscribe();
    }, [watch,usersData]);

    useEffect(()=>{
        trigger()
    },[])


    return (
    <Container>
        <div className="subtitle">
            <img src="/logo_sidebar.svg" className='logo' height='70px' />
            <div style={{ display: "flex", gap: "10px" }}>
                <h3>{`${rfqData?.shipping?.origin_boarding_terminal.city.country.name} -> ${rfqData?.shipping?.destination_boarding_terminal.city.country.name}`}</h3>
                <Icon
                    name={
                    getValues("shipping_method") === "air"?"Aereo" :
                    getValues("shipping_method") === "ocean"?"Maritimo" :
                    getValues("shipping_method") === "ground"?"Terrestre":
                    getValues("shipping_method") === "bbk"?"Bbk":
                    getValues("shipping_method") === "ro-ro"?"Roro":
                    "Containers"
                    }
                    size="31"
                    color="#3D509A"
                />
            </div>
        </div>
        
        <div style={{borderBottom:'3px solid #3D509A',paddingBottom:'15px'}}>
            
            <SmallField register={register} name={`${nameForm}.radication`} label="Radicado :"/>
            <SmallField name={`${nameForm}.date`} label="Fecha :"/>
        </div>
        <div style={{borderBottom:'3px solid #3D509A',paddingBottom:'15px'}}>
            {/* <p style={{fontWeight:'bold'}}>Cliente :</p> */}
            <SmallSelect name={`${nameForm}.preparedFor`} label="Preparado para :" options={
                clients.map(user=>{
                    return {
                        label:`${user.name} ${user.last_name} - ${user.email}`,
                        value:user.id,
                    }
                })
            }/>
            <SmallField name={`${nameForm}.clientEmail`} label="Email del cliente :"/>
            <SmallField name={`${nameForm}.clientNumber`}label="Numero del cliente :"/>
        </div>
        <div style={{gridRow:'span 2'}}>
            <h3>Val logistics Colombia</h3>
            <SmallField name={`${nameForm}.address`} label="Direccion :"/>
            <SmallField name={`${nameForm}.city`} label="Localidad :"/>
            <SmallField name={`${nameForm}.country`} label="Pais :"/>
        </div>
        <div style={{borderBottom:'3px solid #3D509A',paddingBottom:'15px'}}>
            <SmallSelect name={`${nameForm}.preparedBy`} label="Preparado por :" options={
                operators.map(user=>{
                    return {
                        label:`${user.name} ${user.last_name}`,
                        value:user.id,
                    }
                })
            }/>
            <SmallField name={`${nameForm}.preparedByPhone`} label="Telefono :"/>
            <SmallField name={`${nameForm}.preparedByEmail`} label="Email :"/>
        </div>
        <div>
            <SmallSelect name={`${nameForm}.salesContact`} label="Contacto de ventas :" options={
                salesUsers.map(user=>{
                    return {
                        label:`${user.name} ${user.last_name}`,
                        value:user.id,
                    }
                })
            }/>
            <SmallField name={`${nameForm}.salesPhone`} label="Teléfono :"/>
            <SmallField name={`${nameForm}.salesEmail`} label="Email :"/>
        </div>
    </Container>

  )
}
