import React, { useEffect, useState } from 'react';
import { styled } from '@nextui-org/react';
import { useFormContext } from 'react-hook-form';
import SmallSelectTable from '../../pages/ManageQuotes/PreLiquidation/CreatePreliquidation/Fields/SmallSelectTable';
import Icon from '../Icon/Icon';
import AutocompleteInput from './AutocompleteInput';  // Asegúrate de importar el componente
import { useSelector } from 'react-redux';

const Container = styled("div", {
  width: '100%',
  margin: '0 auto',
  fontFamily: 'Arial, sans-serif',
  fontSize:'13px',
});

const Table = styled("table", {
  width: '100%',
  borderCollapse: 'collapse',
  marginBottom: '20px',
  '& th, & td': {
    border: '1px solid $neutral_200',
    textAlign: 'left',
    margin:0,
  },
  '& th': {
    backgroundColor: '#f0f0f0',
    padding:'10px',
    paddingLeft:'15px',
    fontWeight: 'bold',
    border:'1px solid $neutral_200',
  },
  '& tr:nth-child(even)': {
    backgroundColor: '#f9f9f9',
  },
});

const Input = styled("input", {
  width: '100%',
  padding: '8px',
  borderRadius: '4px',
  background:'transparent',
  border: 'none',
  transition: 'border-color 0.3s ease',
  '&:focus': {
    outline: 'none',
    borderColor: '#4CAF50',
  },
});

const Button = styled("button", {
  padding: '2px',
  width:'30%',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: '#3c529c',
  color: '#fff',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: '#4f619d',
  },
});

const DeleteButton = styled("button", {
  padding: '5px 5px 0px 5px',
  margin:'5px',
  marginLeft:'22px',
  borderRadius: '4px',
  border: 'none',
  backgroundColor: '$neutral_300',
  color: '#fff',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor:'$delete',
  },
});

export default function InputTable({ tableName, columns, defaultData ,addRowText='Agregar Concepto +', enableAddDelete=true, errors={}, setUnit}) {
  const { getValues, setValue } = useFormContext();
  const methods = useFormContext();

  const [data, setData] = useState(getValues(tableName));

  useEffect(() => {
    const subscription = methods.watch((value, { name }) => {
        if (name?.startsWith(tableName)){
          const newData = value[tableName];
          setData([...newData]);
        }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  const onChangeInput = (e, itemId) => {
    const { name, value } = e.target;
    const editData = data.map((item) =>
      item.itemId === itemId && name ? { ...item, [name]: value } : item
    );
    if (editData[itemId-1]) {
      setValue(`${tableName}[${itemId-1}].${name}`, editData[itemId-1][name]);
    }
  };

  const onChangeAutocomplete = (e, itemId) => {
    const { name, value, unit } = e.target;
    console.log('Selected concept:', value, 'with unit:', unit);
    setUnit(unit, itemId)
    const editData = data.map((item) =>
      item.itemId === itemId && name ? { ...item, [name]: value } : item
    );
    if (editData[itemId-1]) {
      setValue(`${tableName}[${itemId-1}].${name}`, editData[itemId-1][name]);
    }
    // Maneja los valores aquí según tus necesidades
  };

  const onChangeSelect = (value, name, itemId) => {
    const editData = data.map((item) =>
      item.itemId === itemId && name ? { ...item, [name]: value } : item
    );
    setValue(`${tableName}[${itemId-1}].${name}`, editData[itemId-1][name]);
  };

  function addRow() {
    setValue(tableName,[
      ...data,
      {
        ...defaultData,
        itemId: data.length + 1,
      },
    ]);
  }

  function deleteRow(itemId) {
    const newData = data.filter((item) => item.itemId !== itemId);
    const formattedIds = newData.map((item,i) => ({...item, itemId:i+1 }))
    setValue(tableName, formattedIds);
  }

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th>ItemID</th>
            {columns.map((header) => (
              <th key={header.name}>{header.name}</th>
            ))}
            <th>Acción</th>
          </tr>
        </thead>
        <tbody>
          {data?.map(({ itemId, ...rest }) =>  {
            return <tr key={itemId}>
              <td>
                {itemId}
              </td>
              {columns.map((column) => {
                return (
                  <td key={column.name}>
                    {column.type === "conceptAutocomplete" ? (
                      <AutocompleteInput
                        name={column.name}
                        value={rest[column.name]}
                        itemId={itemId}
                        onChange={onChangeAutocomplete}
                        placeholder={`${column.name}...`}
                      />
                    ) : column.type !== 'select' ? (
                      <Input
                        name={column.name}
                        value={rest[column.name]}
                        type={column.type}
                        onChange={(e) => onChangeInput(e, itemId)}
                        placeholder={`${column.name}...`}
                        onWheel={(e) => e.target.blur()}
                        style={errors[tableName] && errors[tableName][itemId] && errors[tableName][itemId][column.name] ? {border:'1px solid red'} : {}}
                      />
                    ) : (
                      <SmallSelectTable
                        name={column.name}
                        itemId={itemId}
                        value={rest[column.name]}
                        type="text"
                        onChange={onChangeSelect}
                        options={column.options}
                      />
                    )}
                  </td>
                );
              })}
              <td>
                {enableAddDelete && <DeleteButton type='button' onClick={() => deleteRow(itemId)}> <Icon name='Close' color='white' size='16px'/> </DeleteButton>}
              </td>
            </tr>
          })}
        </tbody>
      </Table>
      {enableAddDelete && <Button type='button' onClick={() => addRow()}>{addRowText}</Button>}
    </Container>
  );
}
