import React, { useEffect, useState } from 'react'
import { styled } from '@nextui-org/react'
import Circle from './Circle'

export default function Movements({data}) {
    
    const [movements,setMovements] = useState()

    useEffect(()=>{
        const movementsData = data?.movements
        setMovements({
            RFQ:true,
            quotation: movementsData?.quotation,
            operation: movementsData?.operation,
        })
    },[data])

    return (
        movements && <div style={{display:'flex', gap:'15px',margin:'10px 30px'}}>
            <Circle 
                state={"completed"}
                tooltip="Petición de cotización realizada con exito"
                firstCircle={true}
            />
            <Circle
                state={movements.quotation? "completed": "started"}
                tooltip={movements.quotation? 'Cotización : La cotización se realizo con exito': 'Cotización : La cotización esta en proceso'}
            />
            <Circle
                state={movements.quotation? movements.operation? "completed": "started" : "not-started"}
                tooltip={movements.quotation? movements.operation? "Operación : La operación se inicializo con exito": "El inicio de la operación esta en proceso" : "Esperando cotización"}
            />
        </div>
    )
}
