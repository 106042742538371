import { globalCss } from '@nextui-org/react';
import theme from './theme'

const globalStyles = globalCss({
  body: { margin: 0, color: theme.colors.primary_500.value },
  h1: { fontSize: 48, lineHeight: "48px", fontWeight: 700 },
  h2: { fontSize: 32, lineHeight: "36px", fontWeight: 700 },
  h3: { fontSize: 24, lineHeight: "30px", fontWeight: 600 },
  h4: { fontSize: 16, lineHeight: "22px", fontWeight: 600 },
  p: { lineHeight: "22px", fontWeight: 400, letterSpacing: 'normal' },
  a: { fontSize: 16, lineHeight: "22px", fontWeight: 600, textDecoration: "underline" },
  td: { color: theme.colors.primary_500.value },
  input: { color: theme.colors.primary_500.value },
  ".only-text-avatar span.nextui-avatar-bg": { backgroundColor: theme.colors.primary_300.value },
  ".only-text-avatar span.nextui-avatar-text": { color: 'white' },
  ".bold": { fontWeight: 600 },
  ".d-flex": { display: 'flex' },
  ".d-block": { display: 'block' },
  ".justify-content-center": { justifyContent: 'center' },
  ".justify-content-around": { justifyContent: 'space-around' },
  ".justify-content-between": { justifyContent: 'space-between' },
  ".align-items-start": { alignItems: 'start' },
  ".align-items-center": { alignItems: 'center' },
  ".align-items-end": { alignItems: 'end' },
  ".align-self-start": { alignSelf: 'start' },
  ".align-self-end": { alignSelf: 'end' },
  ".break-word": { wordBreak: 'break-word' },
  ".ellipsis": { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block' },
  ".nextui-input-main-container": { marginRight: "0 !important" },
  ".text-center": { textAlign: 'center' },
  ".flex-column": { flexDirection: 'column' },
  ".flex-wrap": { flexWrap: 'wrap' },
  ".flex-1": { flexGrow: 1 },
  ".p-absolute": { position: 'absolute' },
  ".p-relative": { position: 'relative' },
  ".w-100": { width: '100%' },
  ".w-50": { width: '50%' },
  ".h-100": { height: '100%' },
  ".h-50": { height: '50%' },
  ".border-rounded": { borderRadius: '8px' },
  ".overflow-h": { overflow: 'hidden' },
  ".label_dropdown": { fontSize: 14, marginBottom: "0.375rem" },
  ".gap-1": { gap: "1rem" },
});

export default globalStyles