import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Loading } from "@nextui-org/react";
import { useNavigate } from "react-router-dom";
import {
  getPurchaseOrders,
  getQuotations,
  getServiceSplit,
} from "../../../services/quotation.services";

// Components
import Table from "../../../components/Table/Table";

import { useTranslation } from "react-i18next";
import { getOperations } from "../../../services/operations.services";
import {
  getSubStatusesOperations,
  getSubStatuses,
} from "../../../services/operations.services";
import Pagination from "../../../components/Pagination/Pagination";
import { ROLES } from "../../../constants";
import { getCountriesNew } from "../../../services/countries.services";
import toast from "../../../components/Toast/ToastTypes";
import SubscriptionModal from "./SubscriptionModal";
import { getQuoteData } from "../../../services/quotations.services";
import { getPreliquidations } from "../../../services/preliquidation.services";
import { getPreliquidationCosts } from "../../functions";

const TableServices = ({ filterType, filterSearch, loading, setLoading }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const token = user.token;
  const { t: translate } = useTranslation("Services");

  const [open, setOpen] = useState(false)
  const [currentService, setCurrentService] = useState()
  const [data, setData] = useState([]);
  const [items, setItems] = useState([]);
  //paginado
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  console.log("services :", data);

  const downloadQuotation = (item) => {
    //log('download')
  };

  let columns = [
    { name:translate("actions"), uid: "actions", type: "action" },
    {
      name:translate("radication_id"),
      uid: "idRadication",
      type: "text",
      style: { width: "auto" },
    },
    { name:translate("po"), uid: "purchaseOrder", type: "text" },
    { name:translate("load"), uid: "load", type: "text" },
    { name:translate("date"), uid: "date", type: "date" },
    { name:translate("hour"), uid: "hour", type: "hour" },
    { name:translate("origin"), uid: "origin", type: "country" },
    { name:translate("method"), uid: "method", type: "icon" },
    { name:translate("destination"), uid: "destination", type: "country" },
    { name: "Costo", uid: "cost", type: "text" },
    { name:translate("status"), uid: "subStatus", type: "text" },
    { name:translate("advisor"), uid: "advisor", type: "text" },
  ];

  let isClient = false;
  user.roles.forEach((role) => {
    if ([ROLES.User || ROLES.Client].includes(role)) {
      isClient = true;
    }
  });

  if (isClient) {
    columns = [
      { name: "Acciones", uid: "actions", type: "action" },
      {
        name: "Referencia",
        uid: "ref",
        type: "text",
        style: { width: "auto" },
      },
      { name: "Carga", uid: "load", type: "text" },
      { name: "Fecha", uid: "date", type: "date" },
      { name: "Hora", uid: "hour", type: "hour" },
      { name: "Origen", uid: "origin", type: "country" },
      { name: "Destino", uid: "destination", type: "country" },
      { name: "Costo", uid: "cost", type: "text" },
      { name: "Estado", uid: "subStatus", type: "text" },
      { name: "Asesor", uid: "advisor", type: "text" },
    ];
  }

  const actions = [
    {
      name: "Ver detalle",
      icon: "View",
      handle: (item) =>
        navigate(
          `/services/detail/chat/?radication=${item.idRadication}&tab=chat`
        ),
    },
    {
      name: 'Rastreo',
      icon: 'Tracking',
      handle: (item) => navigate(`/tracking/service/?radication=${item.idRadication}&tab=chat`)
    },
    {
      name: "Subscribir",
      icon: "Mail",
      handle: (item) => {setCurrentService(item), setOpen(true)},
    },
  ];
  useEffect(() => {
    setCurrentPage(1);
  }, [filterSearch]);


  useEffect(() => {
    setLoading(true);
    const asyncTasks = [
      getServiceSplit(token, currentPage, pageSize, filterSearch),
      getOperations({ token: token }),
      getSubStatuses(token),
      getSubStatusesOperations(token),
      getPurchaseOrders(token),
      getCountriesNew(token),
      getQuoteData(token),
      getPreliquidations(token),
    ];
    Promise.all(asyncTasks)
      .then(async (res) => {
        const servicesData = await res[0].json();
        const operationsData = await res[1].json();
        const subStatusesData = await res[2].json();
        const subStatusesOperationsData = await res[3].json();
        const purchaseOrdersData = await res[4].json();
        const countriesData = await res[5].json();
        const liquidationData = await res[6].json();
        const preliquidationData = await res[7].json();

        setCount(servicesData?.count);
        const filteredServices = servicesData?.results;
        // .filter(service => service.users.find(e=>e.id===99))

        const tableData = filteredServices?.map((service) => {
          const operation = operationsData.results?.find(
            (op) => op.radication === service.radication
          );
          const purchaseOrder = purchaseOrdersData.find(
            (po) => po.service === service.id
          )?.purchaseNumber;
          let lastSubStatus = "Pending";
          if (operation) {
            const subStatuses = subStatusesOperationsData.filter(
              (sub) => sub.operation == operation.id
            );
            const sortedsubStatuses = subStatuses.sort(
              (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
            );
            const lastSubStatusId = sortedsubStatuses[0]?.operation_sub_status;
            if (lastSubStatusId) {
              lastSubStatus = subStatusesData.find(
                (sub) => sub.id === lastSubStatusId
              )?.name;
            }
          }

          const methodId = service?.shipping?.shipping_method
          
          const method = methodId === 1? {name:"Aereo", text:"Aereo"} :
            methodId === 2? {name:"Maritimo", text:"Maritimo"} :
            methodId === 3? {name:"Terrestre", text:"Terrestre"} :
            methodId === 4? {name:"Bbk", text:"Bbk"} :
            methodId === 5? {name:"Roro", text:"Roro"} :
            methodId === 6? {name:"Containers", text:"Contenedores especiales"} :
            {name:"Close", text:"No especificado"}

          const originCountry = countriesData.find(country=>country.name.toLowerCase() === service?.shipping?.origin_boarding_terminal?.city?.country?.name.toLowerCase())
          const destinationCountry = countriesData.find(country=>country.name.toLowerCase() === service?.shipping?.destination_boarding_terminal?.city?.country?.name.toLowerCase())

          const approvedLiquidations = liquidationData.filter(liquidation => liquidation.customer_approval)
          const serviceLiquidation = approvedLiquidations.find(liquidation => liquidation.radication == service.radication)
          console.log("serviceLiquidation :",serviceLiquidation)
          let cost = 0
          if (serviceLiquidation?.preliquidation_ids){
            const prelId = serviceLiquidation.preliquidation_ids[0]
            const prel = preliquidationData.find(prel=>prel.id === prelId)
            
            const {totalCost} = getPreliquidationCosts(prel)
            cost = totalCost
          }   
          console.log("cost :",cost)
          return { 
            ...service,
            originCountry,
            method,
            destinationCountry,
            purchaseOrder,
            subStatus: lastSubStatus,
            cost,
          };
        });
        setData(tableData);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [currentPage, filterSearch]);

  useEffect(() => {
    if (!loading) {
      let dataTable;
      if (data?.length > 0) {
        dataTable = data.map((service) => {
          const user =
            service.users.find((user) =>
              user.user?.groups?.includes("Asesores")
            )?.user ||
            service.users.find((user) => user.user?.groups?.includes("Pricing"))
              ?.user ||
            service.users.find((user) =>
              user.user?.groups?.includes("Operations")
            )?.user;
          const purchaseOrder = service.purchaseOrder;
          return {
            id: service.id,
            purchaseOrder,
            ref:
              purchaseOrder && purchaseOrder !== "Ninguna"
                ? purchaseOrder
                : service.radication,
            idRadication: service.radication,
            load:
              service.loads.length > 0 ? service.loads[0].load_type.name : "",
            date: new Date(service.created_at),
            method:service.method,
            origin: service.originCountry,
            destination: service.destinationCountry,
            advisor: `${user?.first_name || ""} ${user?.last_name || ""}`,
            subStatus: service.subStatus,
            cost:service.cost? service.cost?.toFixed(2) + ' USD' : '',
          };
        });
        // if (filterSearch) {
        //     dataTable = dataTable?.filter(service => {
        //         let isIncluded = false
        //         Object.keys(service).map(key => {
        //             if (service[key]?.toString().toLowerCase().includes(filterSearch.toLowerCase())) {
        //                 isIncluded = true
        //             }
        //         })
        //         return isIncluded;
        //     })
        // }
      } else {
        dataTable = data;
      }
      setItems(dataTable);
    }
  }, [loading, filterType]);

  return (
    <div className="h-100">
      <SubscriptionModal open={open} setOpen={setOpen} service={currentService}/>
      {loading ? (
        <div className="d-flex justify-content-center">
          <Loading></Loading>
        </div>
      ) : items?.length > 0 ? (
        <Table
          columns={columns}
          items={items}
          actions={actions}
          searchBar={false}
        ></Table>
      ) : (
        <div className="d-flex justify-content-center">
          No se encontraron servicios
        </div>
      )}
      <Pagination
        page={currentPage}
        setPage={setCurrentPage}
        itemsPerPage={pageSize}
        setItemsPerPage={setPageSize}
        totalItems={count}
      />
    </div>
  );
};

export default TableServices;
