
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@nextui-org/react';
import TextEditor from '../../../components/TextEditor/TextEditor'
import Button from '../../../components/Button/Button'
import InputSelect from '../../../components/Select/inputSelect';
import toast from '../../../components/Toast/ToastTypes';
import CreateExample, { example } from './templates';

import { editQuotation, getQuoteData } from '../../../services/quotations.services';
import  {getQuotation, getServiceSplitRadication } from '../../../services/quotation.services'

const StyledDiv = styled('div', {
  bgColor: '$neutral_50',
  borderRadius: '8px',
  border: '1px solid grey',
})

export default function OfferRequirements({ currentQuote, setCurrentQuote }) {
  const [offerRequirements, setOfferRequirements] = useState('')
  const [readOnly, setReadOnly] = useState(true)
  const token = useSelector(state => state.user.token)
  const [fillData, setFillData] = useState()
  const [versions, setVersions] = useState()
  const [selectedVersion, setSelectedVersion] = useState()
  const handleSubmit = () => {
    setReadOnly(true)
    editQuotation({ terms: offerRequirements }, currentQuote.id, token).then(async res => {
      setReadOnly(true)
      toast('success', 'Requisitos actualizados')
    }).catch(err => console.log('err :', err))
  }

  useEffect(() => {
    if (currentQuote.radication && currentQuote.id){
    const asyncTasks = [getServiceSplitRadication(currentQuote.radication,token), getQuoteData(token, currentQuote.id), getQuoteData(token)]
      Promise.all(asyncTasks).then (async res => {
        const RFQData = await res[0].json()
        const liquidationData = await res[1].json()
        const allLiquidationsData = await res[2].json()
        const versionsData = allLiquidationsData.filter(liquidation => liquidation.radication === liquidationData.radication)
        const sortedVersions = versionsData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
        
        const rfq = RFQData?.results ? RFQData.results[0] : null
        setVersions(sortedVersions)
        const selectedVersionIndex = sortedVersions.findIndex(version => version.id === currentQuote.id)

        setSelectedVersion({
          label:'Versión '+ (selectedVersionIndex+1),
          value: currentQuote?.id
        })
        console.log("liquidationData :",liquidationData)
        setFillData({
          ...rfq,
          ...liquidationData,
        })
        setReadOnly(true)
        setOfferRequirements(liquidationData.terms || '')
      })
      .catch(err => console.log('err :', err))
    }
  }, [currentQuote])
  
  function handleChangeVersion(e){
    setCurrentQuote({
      radication:currentQuote.radication,
      id:e.value,
    })
    setSelectedVersion(e)
  }

  return (
    <StyledDiv>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', gap: '15px', margin: '10px' }}>
          <Button text='Modificar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setReadOnly(false)} disabled={!readOnly || !currentQuote} endIcon={{name:'EditSquare', color:'white'}}/>
          <Button text='Guardar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => handleSubmit()} disabled={readOnly || !currentQuote} endIcon={{name:'CheckSquare', color:'white'}}/>
        </div>
        <div style={{display:'flex',gap:'10px'}}>
            <div style={{ display: 'flex', margin: '10px' }}>
              <label style={{ margin: '10px' }}>Versiones :</label>
              <InputSelect
                disableClear
                isDisabled={!currentQuote}
                onChange={(e)=>handleChangeVersion(e)}
                value={selectedVersion}
                options={versions?.map((ver,i) => {
                  return {
                    label:'Versión ' + (i+1),
                    value:ver.id,
                  }
                })}
              />
            </div>
            <div style={{ display: 'flex', margin: '10px' }}>
              <label style={{ margin: '10px' }}>Plantillas :</label>
              <InputSelect
                isDisabled={readOnly}
                disableClear={true}
                onChange={(e) => setOfferRequirements(e.value || '')}
                options={[
                  { label: 'Ninguno', value: '' },
                  { label: 'Ejemplo', value: fillData && CreateExample(fillData)},
                ]}
              />
            </div>
        </div>
      </div>
      <TextEditor state={offerRequirements} setState={setOfferRequirements} readOnly={readOnly} />
    </StyledDiv>
  )
}
