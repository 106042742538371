import React from 'react'
import { styled } from '@nextui-org/react'
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import Select from 'react-select'

const customStyles = {
    control: () => ({
        display:'flex',
        fontSize: '14px',
        padding: '0',
        cursor: 'pointer',
        margin: '5px',
        border: '1px solid #ccc',
        borderRadius: '4px',
        transition: 'box-shadow 0.3s ease',
        background: '#f7f7f7',
        minWidth: '190px',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    option: (provided) => ({
        ...provided,
        cursor: 'pointer',
        fontSize:'13px',
        display:'flex',
    }),
  };

export default function SmallSelect({name, label, options, required=true}) {

    const Container = styled("div",{
        // border:'1px solid #ccc',
        padding:'0 5px',
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        ['label']:{
            fontSize:'14px',
        },
        ['select']:{
            minWidth:'190px',
            fontSize: '14px',
            padding: '4px',
            margin:'5px',
            border: '1px solid #ccc',
            ['&:focus']:{
                outline: '1px solid #ccc',
                border: '1px solid #ccc',
            }
        },
        '.inputError':{
            display:'flex',
            flexDirection:'column',
            'span':{
                fontSize:'12px'
            }
        }
    })

    const { control } = useFormContext();
    const errors = useFormState()?.errors
    const splitError = name.split('.')
    const thisFieldError = errors[splitError[0]]? errors[splitError[0]][splitError[1]] : ''

    return (
    name ? <Controller
        name={name}
        defaultValue=""
        control={control}
        rules= {required ? { required: "Este campo es obligatorio" } : {}}
        render={({ field }) => (
        <Container>
            <label for="radicado">{label}</label>
            <div className='inputError'>
            <Select
                {...field}
                options={options}
                isSearchable={true}
                placeholder="Select an option"
                styles={customStyles}
                hideSelectedOptions
            />
                {thisFieldError && <span style={{color:'red'}}>{thisFieldError?.message}</span>}
            </div>
        </Container>
        )}
    />:
    <Container>
        <label>{label}</label>
        <Select
            options={options}
            isSearchable={true}
            placeholder="Select an option"
            styles={customStyles}
            hideSelectedOptions
        />
    </Container>
  )
}