import { URL } from "../constants"
//no se usa?
export const sendMessage = async (token, message, radication) => {
    const response = await fetch(`${URL}send/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            user: token,
            message: message,
            radication: radication,
        })
    })
    return response.json()
}

export const deleteParticipant = async (data, token) => {
    const response = await fetch(`${URL}services/delete-advisor/`, {
        method: 'DELETE',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    })
    return response;
}

export const getMessages = async (token,radication) => {
    console.log('radication :',radication)
    const response = await fetch(`${URL}chat/messages/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}