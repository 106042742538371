import { Progress, styled, Tooltip } from "@nextui-org/react";
import React, { useState } from "react";
import theme from "../../theme";
import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon/Icon";
import { useTranslation } from "react-i18next";

function CardTop({ title, objects, icon }) {
  const [active, setActive] = useState(false);

  const { t: translate } = useTranslation("Home");

  const changeTopHandler = (estado) => {
    setActive(estado);
  };

  const StyledCardTop = styled("div", {
    [`.${theme} &`]: {
      borderRadius: "8px",
      background: "white",
      padding: "2rem",
      display: "flex",
      flexDirection: "column",
      color: "$primary_300",
      boxShadow:'$elevation_1',
      [".boldText"]: {
        fontWeight: "600",
      },
      [".secondaryText"]: {
        color: "$secondary_300",
      },
    },
  });

  const StyledDflex = styled("div", {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ["&.styledPadding"]: {
      padding: "1rem",
    },
    [".styledTitleIcon"]: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      color: "$primary_300",
    },
  });

  const StyledProgress = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    marginBottom: "1.5rem",
    [`.styledNumber`]: {
      backgroundColor: "$primary_50",
      borderRadius: "2rem",
      padding: "8px",
      width: 30,
      height: 30,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      h4: {
        margin: 0,
      },
    },
    [`.styledInfoProgress`]: {
      display: "flex",
      alignItems: "center",
      gap: "0.5rem",
      p: {
        fontWeight: "600",
        color: "red",
      },
    },
  });

  const StyledTop = styled("p", {
    [`.${theme} &`]: {
      cursor: "pointer",
      ["&.origenes"]: {
        fontWeight: active ? "500" : "600",
        color: active ? "$primary_200" : "$primary_300",
        borderBottom: active ? null : "2px solid $primary_300",
      },
      ["&.destinos"]: {
        fontWeight: active ? "600" : "500",
        color: active ? "$primary_300" : "$primary_200",
        borderBottom: !active ? null : "2px solid $primary_300",
      },
    },
  });

  const StyledProgressBar = styled(Progress, {
    [`.${theme} &`]: {
      [".nextui-progress-wrapper-enter"]: {
        backgroundColor: "$primary_200",
      },
    },
  });

  let destinos = objects.destinos?.sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    }
    return -1;
  });
  destinos = objects.destinos?.slice(0, 5);

  let origenes = objects.origenes?.sort((a, b) => {
    if (a.value < b.value) {
      return 1;
    }
    return -1;
  });
  origenes = origenes?.slice(0, 5);

  return (
    <StyledCardTop>
      <StyledDflex>
        <div className="styledTitleIcon">
          <Icon name={icon} color={theme.colors.secondary_200} />
          <p className="boldText">{title}</p>
        </div>
        {/* <Dropdown options={[{ key: 'año', name: 'Año' }, { key: 'mes', name: 'Mes' }, { key: 'día', name: 'Día' }]} /> */}
      </StyledDflex>
      <StyledDflex className="styledPadding">
        <StyledTop className="origenes" onClick={() => changeTopHandler(false)}>
          {translate("origins")}
        </StyledTop>
        <StyledTop className="destinos" onClick={() => changeTopHandler(true)}>
          {translate("destinations")}
        </StyledTop>
      </StyledDflex>
      {active ? (
        destinos?.length > 0 ? (
          destinos.map((destino, index) => {
            return (
              <StyledProgress key={index}>
                <StyledDflex>
                  <div className="styledInfoProgress">
                    <div className="styledNumber">
                      <h4>{index + 1}</h4>
                    </div>
                    <p className="boldText">{destino.country}</p>
                  </div>
                  <Tooltip
                    css={{
                      "--nextui--tooltipTextColor": theme.colors.primary_300,
                      "--nextui--tooltipColor": theme.colors.primary_50,
                      boxShadow:
                        "0px 32px 64px rgb(79 94 113 / 7%), 0px 16px 32px rgb(79 94 113 / 8%), 0px 8px 16px rgb(79 94 113 / 9%), 0px 4px 8px rgb(79 94 113 / 10%), 0px 2px 4px rgb(79 94 113 / 11%), 0px 0px 2px rgb(79 94 113 / 12%)",
                      width: 111,
                    }}
                    content="Total de viajes"
                    placement="bottom"
                  >
                    <p className="secondaryText bold">{destino.value}</p>
                  </Tooltip>
                </StyledDflex>
                <StyledProgressBar size="sm" value={destino.percentage} />
              </StyledProgress>
            );
          })
        ) : (
          <p className="d-flex justify-content-center">{translate("no-data")}</p>
        )
      ) : origenes?.length > 0 ? (
        origenes.map((origen, index) => {
          return (
            <StyledProgress key={index}>
              <StyledDflex>
                <div className="styledInfoProgress">
                  <div className="styledNumber">
                    <h4>{index + 1}</h4>
                  </div>
                  <p className="boldText">{origen.country}</p>
                </div>
                <Tooltip
                  css={{
                    "--nextui--tooltipTextColor": theme.colors.primary_300,
                    "--nextui--tooltipColor": theme.colors.primary_50,
                    boxShadow:
                      "0px 32px 64px rgb(79 94 113 / 7%), 0px 16px 32px rgb(79 94 113 / 8%), 0px 8px 16px rgb(79 94 113 / 9%), 0px 4px 8px rgb(79 94 113 / 10%), 0px 2px 4px rgb(79 94 113 / 11%), 0px 0px 2px rgb(79 94 113 / 12%)",
                    width: 111,
                  }}
                  content="Total de viajes"
                  placement="bottom"
                >
                  <p className="secondaryText bold">{origen.value}</p>
                </Tooltip>
              </StyledDflex>
              <StyledProgressBar
                color="primary"
                size="sm"
                value={origen.percentage}
              />
            </StyledProgress>
          );
        })
      ) : (
        <p className="d-flex justify-content-center">{translate("no-data")}</p>
      )}
    </StyledCardTop>
  );
}

export default CardTop;
