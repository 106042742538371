export const InitMap = (coordinatesArray, mapId) => {
  console.log('coordinatesArray :',coordinatesArray)
    // Calculo centro
    let centerLat = 0;
    let centerLng = 0;
    for (const coord of coordinatesArray) {
      centerLat += coord.lat;
      centerLng += coord.lng;
    }
    centerLat /= coordinatesArray.length;
    centerLng /= coordinatesArray.length;
  
    // Calculate total distance for zoom level
    let totalDistance = 0;
    for (let i = 0; i < coordinatesArray.length - 1; i++) {
      const origin = coordinatesArray[i];
      const destination = coordinatesArray[i + 1];
      totalDistance += google.maps.geometry.spherical.computeDistanceBetween(
        new google.maps.LatLng(origin.lat, origin.lng),
        new google.maps.LatLng(destination.lat, destination.lng)
      );
    }
  
    const zoom = Math.floor(16 - Math.log2(totalDistance / 900));
  
    const mapOptions = {
      zoom: zoom,
      center: { lat: centerLat, lng: centerLng },
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    };
  
    const map = new google.maps.Map(document.getElementById(mapId), mapOptions);
  
    const svgMarker = {
      path: 'M0 0m-2.906 0a2.906 2.906 0 1 1 5.812 0a2.906 2.906 0 1 1 -5.812 0z',
      fillColor: '#3D509A',
      fillOpacity: 1.0,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(0, 20),
    };
  
    // Add markers for each coordinate
    for (const coord of coordinatesArray) {
      new google.maps.Marker({
        position: coord,
        map: map,
        label: coord.label || '', // Add label if provided in the coordinate object
      });
    }
  
    // Create and set flight path
    const flightPath = new google.maps.Polyline({
      path: coordinatesArray,
      geodesic: true,
      strokeColor: '#303C90',
      strokeOpacity: 1.0,
      strokeWeight: 3,
      strokePattern: [5, 5],
    });
    flightPath.setMap(map);
  };
  