import React from "react";
import { styled } from "@nextui-org/react";
import { Table } from "@nextui-org/react";
import theme from "../../theme";
import Icon from "../Icon/Icon";


const StyledCardTable = styled("div", {
    [`.${theme} &`]: {
        backgroundColor: "$neutral_50",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        minWidth: "400px",
        color: "$primary_100",
        boxShadow: "5px 5px 10px #EBEDF0",
        td: {
            color: "$primary_300",
            fontSize: "small",
            borderSpacing: "20px 20px",
        },
        th: {
            color: "$primary_100",
            fontSize: "small",
            fontWeight: "normal",
        },
        table: {
            borderCollapse: "separate",
            borderSpacing: "10px",
        },
        span: {
            color: "$primary_300",
            padding: "0rem 0.8rem",
        },
        padding: "1rem 0"
    }, ['.linea']: {
        borderTop: "1px solid #3D509A",
        maxWidth: "200px",
        padding: "0",
    }
});

function CardTable({ textTitle, iconf2, iconf3, iconf4, iconf5, f1c1, f1c2, f1c3, f1c4, f2c1, f2c2, f2c3, f2c4,
    f3c1, f3c2, f3c3, f3c4, f4c1, f4c2, f4c3, f4c4, f5c1, f5c2, f5c3, f5c4 }) {

    return (
        <StyledCardTable className="theme">
            <span><b>{textTitle}</b></span>
            <table>
                <thead>
                    <tr>
                        <th colSpan="2">{f1c1}</th>
                        <th>{f1c2}</th>
                        <th>{f1c3}</th>
                        <th>{f1c4}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width={'fit-content'}><Icon {...iconf2}></Icon></td>
                        <td><b>{f2c1}</b></td>
                        <td>{f2c2}</td>
                        <td>{f2c3}</td>
                        <td>{f2c4}</td>
                    </tr>
                    <tr>
                        <td><Icon {...iconf3}></Icon></td>
                        <td><b>{f3c1}</b></td>
                        <td>{f3c2}</td>
                        <td>{f3c3}</td>
                        <td>{f3c4}</td>
                    </tr>
                    <tr>
                        <td><Icon {...iconf4}></Icon></td>
                        <td><b>{f4c1}</b></td>
                        <td>{f4c2}</td>
                        <td>{f4c3}</td>
                        <td>{f4c4}</td>
                    </tr>
                    <tr>
                        <td><Icon {...iconf5}></Icon></td>
                        <td><b>{f5c1}</b></td>
                        <td>{f5c2}</td>
                        <td>{f5c3}</td>
                        <td>{f5c4}</td>
                    </tr>
                </tbody>
            </table>
        </StyledCardTable>
    );
}

export default CardTable;
