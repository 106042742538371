import React from 'react'
import FormInput from '../../../components/FormInput/FormInput'
import { FormProvider, useForm } from 'react-hook-form';
import { styled } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import FormInputSelect from '../../../components/FormInputSelect/FormInputSelect';
import toast from '../../../components/Toast/ToastTypes';
import { postByAWB } from '../../../services/cargoes.services';
import { useSelector } from 'react-redux' 

const StepContainer = styled("div",{
    display:'flex',
    flexDirection:'column',
    margin:'20px 0',
    paddingBottom:'20px',
    borderBottom:'4px solid $primary_300',
    'form':{
        'div':{
            display:'flex',
            gap:'0 20px'
        }
    }
})
const defaultShipment = {
    awb: "",
    visibility: "",
    reference: "",
    shipper: "",
    consignee: "",
    total_weight: "",
    weight_UOM: ""
}

export default function AddAirShipment({selectedOperation, disableForm, optionalRadication, hasPurchaseOrder=false}) {
    const token = useSelector(state=>state.user.token)
    
    const { formState: { errors, isValid } ,watch, trigger , getValues, reset, handleSubmit, ...methods } = useForm({
        defaultValues:{
            0: defaultShipment
        }
    })

    const formValues = watch()

    function handleAddShipment(){
        const key = Object.keys(formValues).length
        reset({...formValues, [key]: defaultShipment})
    }

    function handleDelete(key){
        let newFormValues = getValues()
        delete newFormValues[key]
        
        const sortedShipments = {}
        const shipmentsArray = Object.keys(newFormValues).map((key,i) => {
            return newFormValues[key]
        })
        shipmentsArray.forEach((shipment, i) => {
            sortedShipments[i]=shipment
        })
        reset (sortedShipments)
    }
    function onSubmit(submitData){
        if (!selectedOperation?.radication && !optionalRadication){
            toast("warn","No se ha añadido el rastreo","No hay operacion seleccionada")
        } else {
            const dataArray = Object.keys(submitData).map(key=>{
                const item = submitData[key]
                const formattedItem = {
                    shipmentTags: selectedOperation?.radication,
                    radication: item.radication || selectedOperation?.radication,
                    awbNumber: Number(item.awb?.split('-').join("")),
                    purchaseOrder : item.purchase_order,
                    shipmentReference: item.reference,
                    shipper: item.shipper,
                    consignee: item.consignee,
                    totalWeight:item.total_weight,
                    weightUom:item.weight_UOM,
                }
                return formattedItem
            })

            const asyncTasks = dataArray.map((item, index) => {
                const delay = index * 3000; 
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        postByAWB(token, item)
                            .then(() => resolve())
                            .catch(err => reject(err));
                    }, delay);
                });
            });
            Promise.all(asyncTasks).then(() => {
                toast("success", "Envíos añadidos con éxito");
                reset([defaultShipment]);
            }).catch(err => toast("error", "Error:", err.message));
        }
    }
    return (
        <FormProvider {...methods} >
            <form style={{minHeight:'65vh'}} onSubmit={handleSubmit(onSubmit)}>
            {
                Object.keys(formValues).map((key,i) => {
                    return(
                    <StepContainer>
                        <form>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                <h3>Envio {Number(i) + 1}</h3>
                                <Button handler={()=>{handleDelete(i)}} startIcon={{name:'Trash',color:'white'}} bgColor={'primary_300'}/>
                            </div>
                            <div style={{display:'flex', justifyContent:'space-between'}}>
                                {(hasPurchaseOrder && !optionalRadication) && <FormInput name={i + ".purchase_order"} label="Orden de compra:" required/>}
                                {optionalRadication && <FormInput name={i + ".radication"} label="Referencia de envio:" required/>}
                                <FormInput type='number' name={i + ".awb"} label="AWB #:" required/>
                                {/* <FormInputSelect name={i + ".visibility"} label="Visibilidad" options={[{label:'Visible to all', value:1},{label:'Private', value:2}]} required/> */}
                            </div>
                            <h4 style={{margin:'20px 0'}}>Informacion adicional</h4>
                            <div>
                                <FormInput name={i + ".reference"} label="Referencia de envio"/>
                                <FormInput name={i + ".shipper"} label="Shipper"/>
                                <FormInput name={i + ".consignee"} label="Consignee"/>
                                <FormInput name={i + ".total_weight"} label="Peso total"/>
                                <FormInput name={i + ".weight_UOM"} label="Peso UOM"/>
                            </div>
                        </form>
                    </StepContainer>)
                })
            }
            <div style={{display:'flex', justifyContent:'end', gap:'30px'}}>
                {/* <Button handler={()=>{handleAddShipment()}} text={"Añadir envio"} bgColor={'primary_300'} textColor={'neutral_50'} outline/> */}
                <Button text={"Subir envios a rastreo"} disabled={disableForm} type="submit" bgColor={'primary_300'} textColor={'neutral_50'} outline/>
            </div>
            </form>
        </FormProvider>
    )
}
