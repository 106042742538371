import React, { useEffect, useState } from "react";
import { Avatar, Loading, styled, Dropdown } from "@nextui-org/react";
import theme from "../../../../../theme";
import { sendCodeParticipant } from "../../../../../services/quotation.services";
import { useSelector } from "react-redux";
import toast from "../../../../../components/Toast/ToastTypes";
import { ROLES } from "../../../../../constants";
import { useSearchParams } from "react-router-dom";
import { getQuotation } from "../../../../../services/quotation.services";
// Components
import Icon from "../../../../../components/Icon/Icon";
import FormModal from "../../../../../components/Modal/FormModal/FormModal";
import DeleteConfirmationModal from "../../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import { deleteParticipant } from "../../../../../services/chat.services";
import { useTranslation } from "react-i18next";

const StyledActions = styled("div", {
  height: "30px",
  width: "20px",
  margin: "10px",
  borderRadius: "10px",
  position: "absolute",
  right: 0,
  top: 0,
  transition: "300ms",
  ["&:hover"]: {
    backgroundColor: "$primary_50",
    cursor: "pointer",
  },
});

const Participants = ({ service, setService }) => {
  const user = useSelector((state) => state.user);
  const { t: translate } = useTranslation("Services");

  // console.log('user :',user)
  const token = user.token;
  const userRoles = useSelector((state) => state.user.roles);

  const [searchParams] = useSearchParams();
  const radication = searchParams.get("radication");

  const [serviceData, setServiceData] = useState();
  const [openModalParticipants, setOpenModalParticipants] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  function getData() {
    getQuotation(radication, token).then(async (res) => {
      const data = await res.json();
      // setService(data);
      setServiceData(data);
      // Guarda el estado en el almacenamiento local cada vez que se actualiza
      // localStorage.setItem('service', JSON.stringify(data));
    });
  }

  const StyledParticipants = styled("div", {
    [`.${theme} &`]: {
      padding: "2rem",
      [".container"]: {
        display: "grid",
        gridTemplateColumns: `repeat(auto-fit, minmax(400px, ${
          service?.users?.length > 2 ? "1fr" : "400px"
        }))`,
        gridAutoFlow: "dense",
        gridGap: "1rem",
        [".card"]: {
          position: "relative",
          backgroundColor: "$neutral_50",
          borderRadius: "8px",
          padding: "1.25rem",
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          ["&.add"]: {
            cursor: "pointer",
          },
          ["h5"]: {
            marginBottom: "0rem",
          },
          ["span.role"]: {
            fontSize: "0.875rem",
          },
          [".nextui-avatar-text"]: {
            color: "$neutral_50",
            fontWeight: "600",
            fontSize: "1.5rem",
          },
          [".info"]: {
            width: "calc(100% - 64px)",
            display: "flex",
            flexDirection: "column",
            alignSelf: "center",
          },
          [".user-add-icon"]: {
            borderRadius: "50px",
            width: "3rem",
            height: "3rem",
            backgroundColor: "$secondary_300",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          },
        },
      },
    },
  });

  const sendCode = (form) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(form.email)) {
      toast("error", "El email ingresado no es valido");
      return;
    }
    sendCodeParticipant(
      {
        email: form.email,
        radication: service.radication,
      },
      token
    ).then((res) => {
      res.ok
        ? toast(
            "info",
            <>
              Hemos enviado un correo a <b>{form.email}</b> con el código de
              verificación para que pueda ingresar al chat.
            </>
          )
        : toast(
            "error",
            "Ha ocurrido un error al enviar el código a tu correo"
          );
      setOpenModalParticipants(false);
      getData()
    });
  };

  const havePermissions = (allowedRoles) => {
    return allowedRoles.find((role) => userRoles.includes(role));
  };
  const handleDelete = () => {
    deleteParticipant(
      {
        id: service.id,
        optionSelected: selectedUser,
      },
      token
    )
      .then(() => {
        toast("success", "Participante eliminado del servicio");
        getData();
      })
      .catch((err) => console.log("err :", err));
  };

  useEffect(() => {
    getData();
  }, [service]);
  console.log("serviceData :",serviceData)
  return (
    <StyledParticipants>
      {serviceData && Object.entries(serviceData).length === 0 ? (
        <Loading css={{ width: "100%" }}></Loading>
      ) : (
        <div className="container">
          {serviceData?.users?.map((user, i) => (
            <div key={i} className="card">
              {(havePermissions([ROLES.Admin]) || (havePermissions([ROLES.User, ROLES.Client]) && ['Invitado','Proveedores'].includes(user?.role[0]))) && (
                <Dropdown placement="bottom-left">
                  <Dropdown.Trigger>
                    <StyledActions className="theme">
                      <Icon name="MenuVertical" size="18"></Icon>
                    </StyledActions>
                  </Dropdown.Trigger>
                  <Dropdown.Menu
                    onAction={() => {
                      setSelectedUser(user.id);
                      setOpenDeleteModal(true);
                    }}
                    aria-label="actions"
                    css={{ minWidth: "100px" }}
                  >
                    <Dropdown.Item
                      key={i}
                      onClick={() => element.handle(item)}
                      icon={<Icon name="Trash" />}
                    >
                      Eliminar participante
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Avatar
                text={`${user.name.toUpperCase()}${user.last_name.toUpperCase()}`}
                size="lg"
              ></Avatar>
              <div className="info">
                <h5 className="ellipsis">
                  {user.name} {user.last_name}
                </h5>
                <span className="role ellipsis">{user.role[0]}</span>
                <a href={`mailto:${user.email}`} style={{ fontSize: "12px" }}>
                  {user.email}
                </a>
              </div>
            </div>
          ))}
          {havePermissions([ROLES.Admin, ROLES.User, ROLES.Client, ROLES.Advisor]) && (
            <div
              className="card add"
              onClick={() => setOpenModalParticipants(true)}
            >
              <div className="user-add-icon">
                <Icon name="UserAdd" color="white"></Icon>
              </div>
              <div className="info">
                <h5 className="ellipsis">{translate("add_participants")}</h5>
              </div>
            </div>
          )}

          <FormModal
            open={openModalParticipants}
            setOpen={setOpenModalParticipants}
            title={translate("add_participants")}
            inputs={[
              {
                name: "email",
                label: translate("email"),
                placeholder: "Introduce correo electrónico",
              },
            ]}
            button={{
              submit: sendCode,
              text: translate("invite"),
              bgColor: "secondary_50",
              textColor: "secondary_300",
              outline: true,
            }}
          ></FormModal>
        </div>
      )}
      <DeleteConfirmationModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        handleDelete={handleDelete}
        text="Desea eliminar a este participante?"
      />
    </StyledParticipants>
  );
};

export default Participants;
