import { URL } from "../constants"

export const getLoadTypes = async (token) => {
    const response = await fetch(`${URL}load-types/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })

    return response;
}