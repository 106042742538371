import React, { useEffect, useState } from 'react'
import airSrc from '../../../assets/sendMethods/aerial.jpg'
import oceanSrc from '../../../assets/sendMethods/maritime.jpg'
import landSrc from '../../../assets/sendMethods/land.jpg'
import { styled, Loading, Modal } from '@nextui-org/react'
import Button from '../../../components/Button/Button'
import { approveQuotation } from '../../../services/quotations.services'
import toast from '../../../components/Toast/ToastTypes';
import { useSelector } from 'react-redux'

const Info = styled("div", {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
})

const StyledModal = styled(Modal, {
    display: 'flex',
    padding: '20px',
    color: '$primary_500 !important',
    background: '$neutral_50',
})

export default function OfferCard({ clientChose, data, getData, shippingMethod }) {

    function getImgSrc(){
        switch(shippingMethod){
            case 'land': return landSrc;
            case 'ocean':return oceanSrc;
            default: return airSrc
        }
    }
    const imgSrc = getImgSrc()

    const Container = styled("div", {
        dislpay: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        background: 'white',
        borderRadius: '8px',
        padding: '0px',
        maxWidth: data.suggested ? '260px' : '250px',
        boxShadow: '$elevation_1',
        transition: '300ms',
        ['.footer']: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: '20px',
        },
        ['&:hover']: {
            cursor: 'pointer',
            transform: 'scale(1.02)',
            boxShadow: '$elevation_2',
        }
    })
    console.log("data :",data)
    const token = useSelector(state => state.user.token)
    const [openModal, setOpenModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)

    const handleApprove = (id) => {
        approveQuotation(id, true, token).then(() => {
            setOpenModal(false)
            toast('success', 'Cotizacion aprobada')
            getData()
        }).catch(err => {
            toast('error', 'Error, no se pudo aprobar')
        })
    }
    const handleReject = (id) => {
        approveQuotation(id, false, token).then(() => {
            setOpenRejectModal(false)
            toast('success', 'Cotizacion rechazada')
            getData()
        }).catch(err => {
            toast('error', 'Error, no se pudo rechazar')
        })
    }
    const itemsTranslation = {
        "ORIGIN": "Gastos en Origen",
        "DESTINATION": "Gastos en Destino",
        "INSURANCE": "Gastos en Seguro",
        "OCEAN": "Gastos Maritimos",
    };

    return (

        <div>
            {data && <Container onClick={() => { !clientChose && setOpenModal(true) }}>
                <img src={imgSrc} alt="card-image" />
                <Info>
                    <p>Opción {data?.option}</p>
                    <h2>{data?.cost}</h2>
                    <p>{data.customer_approval && <span style={{ color: 'green' }}>Ya elegiste esta opción</span>}</p>
                    <p>{data.suggested && <span style={{ color: 'green' }}>Opción recomendada</span>}</p>
                    <div>
                        <ul>
                            {Object.entries(data?.totalsByItemType).map(([itemType, total]) => (
                                itemType !== 'PROFIT-SHARE' &&
                                <li key={itemType} style={{ fontSize: "10px", color: "grey" }}>
                                    <b>{itemsTranslation[itemType] || itemType}</b> ${total.toFixed(2)}
                                </li>
                            ))}
                            {
                                data.air_cargo && 
                                <li style={{ fontSize: "10px", color: "grey" }}>
                                    <b>{'Gastos flete aereo :'}</b> ${data.air_cargo.total_usd}
                                </li>
                            }
                        </ul>
                    </div>
                    <div className='footer'>
                        {/* <Button handler={() => setOpenRejectModal(true)} startIcon={{name:'CloseModal', color:'white'}} bgColor='delete' size={'sm'} textColor='neutral_50'/> */}
                        <Button disabled={clientChose} text={'Elegir y confirmar'} handler={() => setOpenModal(true)} startIcon={{ name: 'CheckModal', color: 'white' }} bgColor='excel' size={'sm'} textColor='neutral_50' />
                    </div>
                </Info>
            </Container>}

            <StyledModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                closeButton
                aria-label="modal-title"
                width="fit-content"
            >
                <h3 style={{ margin: '20px' }}>Confirma que desea elegir la opcion {data.option}</h3>
                <Modal.Footer>
                    <Button text="Cancelar" outline handler={() => { setOpenModal(false) }} />
                    <Button text="Confirmar" handler={() => handleApprove(data.id)} startIcon={{ name: 'CheckModal', color: 'white' }} bgColor='excel' textColor='neutral_50' />
                </Modal.Footer>
            </StyledModal>

            <StyledModal
                open={openRejectModal}
                onClose={() => setOpenRejectModal(false)}
                closeButton
                aria-label="modal-title"
                width="fit-content"
            >
                <h3 style={{ margin: '20px' }}>Confirma que desea rechazar la opcion {data.option}</h3>
                <Modal.Footer>
                    <Button text="Cancelar" bold outline handler={() => { setOpenRejectModal(false) }} />
                    <Button text="Confirmar" textColor={'neutral_50'} bold outline bgColor={'delete'} handler={() => handleReject(data.id)} />
                </Modal.Footer>
            </StyledModal>
        </div>
    )
}