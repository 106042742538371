import React, { useState } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
// import logo from '../../assets/img/logo.webp'
import logo from '../../assets/img/otrologo.jpg'
import { connect } from "react-redux";
import { loginAction } from "../../store/sessionDuck";
import { styled } from "@nextui-org/react";
import theme from "../../theme";
import { Link } from "react-router-dom";
import { useForm , Controller} from "react-hook-form";

function Login({ loginAction }) {
  const { register, handleSubmit, watch,getValues,setValue, control } = useForm();
  // console.log("form :",watch())
  const doLogin = (credentials) => {
    loginAction(credentials);
  };

  const Container = styled("div",{
    display:'flex',
    justifyContent:'center',
    height:'100vh',
    alignItems:'center',
    background:'$primary_500',
  })

  const StyledForm = styled("form", {
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.3)',
    background:'white',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding:'50px',
    borderRadius:'8px',
  })

  const StyledRastreo = styled("div", {
    [`.${theme} &`]: {
      display: "flex",
      flexDirection: "column",
      padding: "2rem 4rem",
      h2: {
        color: "$primary_300",
      },
      label: { color: "$primary_500" },
      [".rastreo"]: {
        display: "grid",
        gridTemplateColumns: "3.5fr 1fr",
        alignItems: "end",
        gap: "1rem",
      },
    },
  });
  //console.info("API BACKEND:", import.meta.env.VITE_BACKEND_URL)
  return (
      <Container>
        <StyledForm onSubmit={handleSubmit(doLogin)}>
          <img src={logo} className='logo' width="90%" alt="" style={{marginX:'auto',marginBottom:'10px' }} />
          {/* <img src="/maersk_logo.jpg" className="logo" alt="" width="90%" style={{marginX:'auto',marginBottom:'10px' }}/> */}
          <h2>¡Bienvenido de nuevo!</h2>
          <p>Ingresa tus datos para iniciar sesión</p>
          <Input
            register={register}
            name="email"
            label="Correo"
            labelColor="neutral_50"
            placeholder="Ingresar el correo"
            selectBorderColor="secondary_200"
          />
          <Input
            register={register}
            name="password"
            password
            label="Contraseña"
            labelColor="neutral_50"
            placeholder="Ingresar la contraseña"
            selectBorderColor="secondary_200"
          />
          <Link to="/forgot-password">Olvidé mi contraseña</Link>
          <Button
            type="submit"
            bgColor="primary_500"
            text="Iniciar sesión"
            bold
            textColor="white"
            width={'100%'}
          />
        </StyledForm>
        {/* <div className={styles.provider_login}>
          <p>¿No tienes una cuenta con nosotros?</p>
          <Link to="/register">Registrate</Link>
        </div>
        <div className={styles.provider_login}>
          <p>¿Eres proveedor?</p>
          <Link to="/provider-chat">Ingresa al chat</Link>
        </div> */}
      </Container>
  );
}

const mapState = ({ user: { } }) => {
  return {};
};

export default connect(mapState, { loginAction })(Login);
