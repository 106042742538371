import React, { useState, useEffect } from 'react';
import { useFetch } from '../../../hooks/useFetch';
import { URL } from '../../../constants';
import { useSelector } from "react-redux";
import { Loading } from '@nextui-org/react';
import ConfirmationModal from '../../../components/Modal/ConfirmationModal/ConfirmationModal';
import DataModal from '../../../components/Modal/DataModal/DataModal';
import moment from 'moment';
import Table from '../../../components/Table/Table';
import { getUsers } from '../../../services/user.services';
import { useTranslation } from "react-i18next";
import RegisterModal from '../../Quotes/NewQuotation/Additional/RegisterModal';
import Button from '../../../components/Button/Button';


const TableServices = () => {
    const token = useSelector((state) => state.user.token);
    const { t: translate } = useTranslation("Admin");

    const headers = {
        "Content-Type": "application/json",
        'Authorization': 'Token ' + token
    }
    const [modalStyle, setModalStyle] = useState({ display: "none" });
    const [modal, setModal] = useState(null);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(true);
    const roles = useFetch(`${URL}roles/`, headers);

    const [items, setItems] = useState([])

    const closeModal = () => {
        setModal(null);
        setModalStyle({ display: "none" });
    };

    const style = {
        display: "block",
        backgroundColor: "rgba(0, 0, 0, .7)",
        position: "absolute",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        zIndex: "300"
    }

    const columns = [
        { name: translate("actions"), uid: 'actions', type: 'action' },
        { name: translate("name"), uid: 'name', type: 'text' },
        { name: translate("document_type"), uid: 'document_type', type: 'text' },
        { name: translate("document"), uid: 'document', type: 'text' },
        { name: translate("email"), uid: 'email', type: 'text' },
        { name: translate("phone"), uid: 'phone', type: 'text' },
        { name: translate("industry"), uid: 'industry', type: 'text' },
        { name: translate("company"), uid: 'company', type: 'text' },
        { name: translate("last_login"), uid: 'last_login', type: 'text' },
        { name: translate("role"), uid: 'role', type: 'object' },
    ];

    const rolModal = userData => {
        const userRoles = roles.data.filter(rol => rol.id != userData.role_id);
        setModalStyle(style);
        setModal(
            <DataModal
                data={userRoles}
                titleText={translate("select_rol")}
                buttonText={translate("change_rol")}
                close={closeModal}
                id={userData.id}
                endpoint={`user/${userData.id}`}
                methodType="PATCH"
                headers={headers}
            />
        )
    };
    const deleteUserModal = userData => {
        setModalStyle(style);
        setModal(
            <ConfirmationModal
                titleText="Eliminar usuario"
                buttonText={translate("delete_user")}
                contentText="¿Esta seguro que desea eliminar este usuario?"
                close={closeModal}
                id={userData.id}
                endpoint={`user/${userData.id}`}
                methodType="DELETE"
                headers={headers}
            />
        );
    }

    const actions = [
        {
            name: translate("edit"),
            icon: 'Edit',
            type: 'button',
            handle: rolModal
        },
        {
            name: translate("delete"),
            icon: 'Trash',
            type: 'button',
            handle: deleteUserModal
        }
    ];

    function getData(){
        setLoading(true)
        getUsers(token).then(async res=>{
            const usersData = await res.json();
            let dataTable;
            if (usersData.length > 0) {
                dataTable = usersData.map((user) => {
                    return {
                        id: user.id,
                        name: user.name,
                        document_type: user.document_type_id,
                        document: user.document,
                        email: user.email,
                        phone: user.phone,
                        industry: user.industry,
                        company: "co",
                        last_login: user.last_login
                            ? moment(user.last_login).format("YYYY-MM-DD HH:mm:ss")
                            : 'Sin inicio',
                        role: user.role ? user.role : translate("no_role"),
                    }
                });
            }
            setLoading(false)
            setItems(dataTable);
        })
    }

    useEffect(()=>{
        getData()
    },[])

    return (
        <div className='h-100'>
            <Button text={'Registrar usuario'} bgColor={'primary_300'} textColor={"white"} handler={()=>setOpen(true)}/>
            <RegisterModal open={open} setOpen={setOpen} getData={getData}/>
            {loading ? (
                <div className='d-flex justify-content-center'>
                    <Loading></Loading>
                </div>
            ) : items.length > 0 ? (
                <Table columns={columns} items={items} actions={actions}></Table>
            ) : (
                <div className='d-flex justify-content-center'>No hay</div>
            )}
            <div style={modalStyle}>{modal}</div>
        </div>
    );
}

export default TableServices;