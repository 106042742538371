export function validateAllLoad(values, shippingMethod) {  //Para habilitar o deshabilitar el boton Continuar    
    let isValid = []
    Object.keys(values.load).forEach(key => {
        
        const currentLoad = values.load[key]
        console.log("currentLoad :",currentLoad)
        if (currentLoad.loadName &&
            currentLoad.loadValue &&
            Number(currentLoad.loadValue) > 0 &&
            currentLoad.amount &&
            Number.isInteger(Number(currentLoad.amount)) &&
            Number(currentLoad.amount) > 0 &&
            currentLoad.insurance !== undefined &&
            currentLoad.unitWeight &&
            Number(currentLoad.unitWeight) > 0 && Number(currentLoad.unitWeight) < 10000 &&
            currentLoad.totalWeight &&
            Number(currentLoad.totalWeight) > 0 &&
            (shippingMethod !== 2 || (currentLoad.containerSize || !currentLoad.contenedorCompleto)) &&
            ((currentLoad.width && currentLoad.width > 0 && currentLoad.width  < 9999) || currentLoad.contenedorCompleto ) &&
            ((currentLoad.long && currentLoad.long > 0 && currentLoad.long  < 9999) || currentLoad.contenedorCompleto ) &&
            ((currentLoad.height && currentLoad.height > 0 && currentLoad.height  < 9999) || currentLoad.contenedorCompleto )
        ) {
            isValid.push(true);
        } else {
            isValid.push(false)
        }
    })
    return !isValid.includes(false)
}

export function validateCurrent(values, expandedForm, shippingMethod) {
    const currentForm = values.load[expandedForm];
    if (!currentForm) {
        return {
            ok: false,
            message: 'El formulario actual no existe.'
        };
    }
    if (!currentForm.loadName) {
        return {
            ok: false,
            message: 'El nombre de la carga es requerido.'
        };
    }
    if (currentForm.loadName.length > 63) {
        return {
            ok: false,
            message: 'El nombre de la carga es demasiado largo, maximo 63 caracteres.'
        };
    }
    if (!currentForm.loadValue || Number(currentForm.loadValue) <= 0 || Number(currentForm.loadValue) > 999999999) {
        return {
            ok: false,
            message: 'El valor de la carga debe ser mayor a 0 y menor de 9 digitos.'
        };
    }
    if (!currentForm.amount || !Number.isInteger(Number(currentForm.amount)) || Number(currentForm.amount) <= 0) {
        return {
            ok: false,
            message: 'La cantidad debe ser un número entero mayor a 0.'
        };
    }
    if (currentForm.insurance === undefined) {
        return {
            ok: false,
            message: 'El seguro es requerido.'
        };
    }
    if (!Number(currentForm.unitWeight) || Number(currentForm.unitWeight) <= 0 || Number(currentForm.unitWeight) >= 10000) {
        return {
            ok: false,
            message: 'El peso unitario debe ser mayor a 0 y menor a 10000.'
        };
    }
    if (!currentForm.totalWeight || Number(currentForm.totalWeight) <= 0) {
        return {
            ok: false,
            message: 'El peso total debe ser mayor a 0.'
        };
    }
    if (shippingMethod === 2 && (!currentForm.containerSize && currentForm.contenedorCompleto)) {
        return {
            ok: false,
            message: 'El tamaño del contenedor es requerido para el método de envío seleccionado.'
        };
    }
    if (!currentForm.contenedorCompleto && (!currentForm.width || currentForm.width <= 0 || currentForm.width >= 9999)) {
        return {
            ok: false,
            message: 'El ancho debe ser mayor a 0 y menor a 9999.'
        };
    }
    if (!currentForm.contenedorCompleto &&(!currentForm.long || currentForm.long <= 0 || currentForm.long >= 9999)) {
        return {
            ok: false,
            message: 'El largo debe ser mayor a 0 y menor a 9999.'
        };
    }
    if (!currentForm.contenedorCompleto &&(!currentForm.height || currentForm.height <= 0 || currentForm.height >= 9999)) {
        return {
            ok: false,
            message: 'La altura debe ser mayor a 0 y menor a 9999.'
        };
    }
    if (expandedForm + 1 !== Object.keys(values.load).length) {
        return {
            ok: false,
            message: 'Debe estar en el último formulario.'
        };
    }
    return {
        ok: true
    };
}

export function calculateTotalTons(values) {
    let allLoadsWeight = 0;
    Object.keys(values.load).forEach(key => {
        let totalWeight = values.load[key].totalWeight;
        let weightUnit = values.load[key].totalWeightUnit;
        switch (weightUnit) {
            case 'kg':
                totalWeight = totalWeight / 1000;
                break;
            case 'lb':
                totalWeight = totalWeight / 2205;
                break;
            default:
                totalWeight = totalWeight / 1000;
        }
        allLoadsWeight = allLoadsWeight + totalWeight;
    });
    return allLoadsWeight;
}

export function calculateTotalPieces(values) {
    let piecesCount = 0;
    Object.keys(values.load).forEach(key => {
        piecesCount += Number(values?.load[key]?.amount) || 0;
    });
    return piecesCount;
}

export function calculateCBM(values) {
    let allLoadsCBM = 0;

    Object.keys(values.load).forEach(key => {
        let length = values.load[key].long;
        let length_unit = values.load[key].longUnit;
        let width = values.load[key].width;
        let width_unit = values.load[key].widthUnit;
        let height = values.load[key].height;
        let height_unit = values.load[key].heightUnit;

        if (!length_unit) { length_unit = 'cm'; }
        if (!width_unit) { width_unit = 'cm'; }
        if (!height_unit) { height_unit = 'cm'; }

        const conversionFactors = {
            cm: 0.01,
            m: 1,
            in: 0.0254
        };

        const lengthInMeters = length * conversionFactors[length_unit];
        const widthInMeters = width * conversionFactors[width_unit];
        const heightInMeters = height * conversionFactors[height_unit];
        const cbm = lengthInMeters * widthInMeters * heightInMeters * values.load[key]?.amount;
        allLoadsCBM = allLoadsCBM + cbm;
    });

    return allLoadsCBM;
}