import React from 'react'
import { styled, Image } from '@nextui-org/react'
import PageTitle from '../../components/PageTitle/PageTitle'
import ship from '../../assets/sendMethods/maritime.jpg'
import Card3 from '../../components/Cards/Card3/Card3'
import video from '../../assets/tools/video.jpg'
import YoutubeEmbed from '../../components/Youtube/EmbedYoutube'
import TutorialsData from './TutorialsData'
const StyledContainer = styled("div", {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: '40px',
    // gridTemplateRows: '160px 500px 450px',
})
const StyledMain = styled("div", {
    backgroundColor: '$neutral_50',
    borderRadius: '8px',
    gridColumn: 'span 3',
    padding: '50px',
    margin: '20px',
    ['img']: {
        margin: '40px 0',
        borderRadius: '8px',
    }
})
const OtherTutorials = styled("div", {
    backgroundColor: '$neutral_50',
    padding: '50px 20px',
    margin: '20px 0',
})

const tutorials = TutorialsData;


export default function ExportTutorial() {
    return (
        <StyledContainer>
            <StyledMain>
                <PageTitle>¿Cómo exportar?</PageTitle>
                <YoutubeEmbed embedId="u31qwQUeGuM" />
                <h3>Preparando el proceso de exportación</h3>
                <p>
                    Para iniciar el proceso de exportación, es fundamental comprender las especificaciones del container que utilizaremos. En el siguiente video, te guiaremos a través de los pasos necesarios y te proporcionaremos información detallada sobre las características clave que debes tener en cuenta al exportar.
                </p>
                <p>
                    Exploraremos los detalles del container, desde sus dimensiones hasta cualquier requisito especial. Asegúrate de prestar atención a cada paso para garantizar un proceso de exportación eficiente y sin contratiempos.
                </p>
            </StyledMain>
            <OtherTutorials>
                <PageTitle>Explorar mas</PageTitle>
                {
                    tutorials.map(((tutorial, i) => {
                        return <Card3 key={i} title={tutorial.title} image={tutorial.image} to={tutorial.url} iconVideo />
                    }))
                }
            </OtherTutorials>
        </StyledContainer>
    )
}
