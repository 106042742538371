import React, { useState } from 'react'
import { Modal, styled } from '@nextui-org/react';
import Button from '../../../components/Button/Button';
import { approveQuotation, finishQuotation } from '../../../services/quotations.services';
import toast from '../../../components/Toast/ToastTypes';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

const StyledFormModal = styled(Modal, {
    padding: '1.5rem',
    color: '$primary_500 !important',
})

export default function FinishQuotationModal({ open, setOpen, quoteData, getData }) {
    const token = useSelector(state => state.user.token)
    const [disableButton, setDisableButton] = useState(false)
    
    const handleFinish = () => {
        if (quoteData?.preliquidation_ids?.length > 0) {
            finishQuotation(quoteData?.id, token).then(() => {
                toast("success", "Cotización finalizada")
                setOpen(false)
                if (getData){
                    getData()
                }
            }).catch(err => console.log("ERR :", err))
        }else{
            toast("error", "No hay ninguna preliquidación")
        }
    }
    const handleFinishAndApprove = () => {
        if (quoteData?.preliquidation_ids.length > 0) {
            setDisableButton(true)
            finishQuotation(quoteData?.id, token).then(() => {
                toast("success", "Cotización finalizada")
                approveQuotation(quoteData?.id, true, token).then(() => {
                    toast('success', 'Cotización aprobada')
                    if (getData){
                        getData()
                    }
                    setOpen(false)
                    setDisableButton(false)
                    setOpen(false)
                })
            }).catch(err => console.log("ERR :", err))
        }else{
            toast("error", "No hay ninguna preliquidación")
        }

    }

    return (
        <StyledFormModal
            closeButton
            aria-label="modal-title"
            open={open}
            onClose={() => setOpen(false)}
            width="fit-content"
        >
            <Modal.Body>
                <h3><b>{quoteData?.radication}</b></h3>
                <p>Desea finalizar la liquidación de radicado {quoteData?.radication}?</p>
                <p>Puedes enviar la oferta al cliente para su aprobación o aprobarla automaticamente</p>
            </Modal.Body>
            <Modal.Footer>
                <Button text='Cancelar'
                    bold
                    outline
                    handler={() => setOpen(false)}
                />
                <Button text='Finalizar y aprobar'
                    bold
                    bgColor={'primary_300'}
                    textColor='neutral_50'
                    disabled={!quoteData?.id || disableButton}
                    handler={() => handleFinishAndApprove()}
                    endIcon={{name:'CheckModal', color:'white'}}
                />
                <Button text='Finalizar y enviar al cliente'
                    bold
                    bgColor={'primary_300'}
                    textColor='neutral_50'
                    disabled={!quoteData?.id}
                    handler={() => handleFinish()}
                    endIcon={{name:'Send', color:'white'}}
                />
            </Modal.Footer>
        </StyledFormModal>
    );
}
