import React, { useEffect, useState } from 'react'
import { Tooltip, styled } from '@nextui-org/react';
import Icon from '../../../components/Icon/Icon';
import logo from '../../../assets/img/val-big.png'
import { getIndustries } from '../../../services/industries.services';
import { useSelector } from 'react-redux';
import { getShippingMethods } from '../../../services/shippingMethods.services';
import { getBusinessTerms } from '../../../services/businessTerms.services';
import { useTranslation } from "react-i18next";

const Container = styled("div",{
    fontSize:'14px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    '.header':{
        padding:' 0 10px',
        marginBottom:'15px',
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    '.shipment':{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderRadius:'8px',
        padding:'10px',
        marginBottom:'20px',
        backgroundColor:'$primary_300',
        color:'white',
        'div':{
            display:'flex',
            flexDirection:'column',
            maxWidth:'30%',
            'span':{fontSize:'23px'},
        },
    },
    '.row':{
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px solid $neutral_300',
    },
})

const StyledTable = styled("table", {
    borderCollapse: 'collapse',
    width: '100%',
    margin:'25px 0',
    fontSize: '13px',
    overflowX:'scroll',
    'th, td': {
        border: '1px solid $neutral_200',
        padding: '6px',
        textAlign: 'left',
    },
    'th': {
        backgroundColor: '#f2f2f2',
        fontWeight:'$normal',
        wordWrap:'break-word'
    },
});

export default function DisplayData({ data, radication }) {
    const token = useSelector(state=>state.user.token)

    const [shippingMethods, setShippingMethods] = useState([])
    const [industries ,setIndustries] = useState([])
    const [incoterms ,setIncoterms] = useState([])

    useEffect(()=>{
        if (data){
            Promise.all([getShippingMethods(token), getIndustries(token), getBusinessTerms(token)]).then(async res => {
                const shippingMethodsData = await res[0].json();
                const industriesData = await res[1].json();
                const incotermsData = await res[2].json();
                setShippingMethods(shippingMethodsData)
                setIndustries(industriesData)
                setIncoterms(incotermsData)
            })
        }
        
    },[data])

    let totalWeight = 0;
    data?.loads?.forEach( load => {
        totalWeight += load.total_weight
    })

    function calculateCBM(length, length_unit, width, width_unit, height, height_unit) {
        if (!length_unit){length_unit='cm'}
        if (!width_unit){width_unit='cm'}
        if (!height_unit){height_unit='cm'}
        const conversionFactors = {
            cm: 0.01,
            m: 1,
            in: 0.0254
        };

        const lengthInMeters = length * conversionFactors[length_unit];
        const widthInMeters = width * conversionFactors[width_unit];
        const heightInMeters = height * conversionFactors[height_unit];

        const cbm = lengthInMeters * widthInMeters * heightInMeters;
        //RETORNA EN METROS CUBICOS
        return cbm;
    }

    function formatCBM(cubicMeters){
        if (cubicMeters < 0.09999999){
            return `${(cubicMeters*100000).toFixed(2)} cm³`
        } else {
            return cubicMeters.toFixed(2) + 'm³'
        }
    }

    let totalCBM = 0;

    data?.loads?.forEach(load => {
        totalCBM += calculateCBM(load.long,load.long_unit,load.width,load.width_unit,load.height,load.height_unit)
    })
    return (
        !data.error && <Container>
            <div className='header'>
                <h3>{radication}</h3>
                <img src={logo} alt="" width='60px'/>
            </div>
            <div className='shipment'>
                <div>
                    <span>{data.shipping?.origin_boarding_terminal.city.country.name}</span>
                    <small>{data.shipping?.origin_boarding_terminal.city.name}</small>
                    <small>Direccion : {data.shipping?.pickup_address}</small>
                </div>
                <Icon name='SimpleArrowRight' color='white' size='30px'/>
                <div>
                    <Icon 
                        color='white'
                        size='35px'
                        name={
                            data.shipping?.shipping_method === 1?'Aereo':
                            [2,4,5,6].includes(data.shipping?.shipping_method)?'Maritimo':
                            'Terrestre'
                        }
                    />
                    {data.business_terms}
                </div>
                <Icon name='SimpleArrowRight' color='white' size='30px'/>
                <div>
                    <span>{data.shipping?.destination_boarding_terminal.city.country.name}</span>
                    <small>{data.shipping?.destination_boarding_terminal.city.name}</small>
                    <small>Direccion : {data.shipping?.delivery_address}</small>
                </div>
            </div>
            <strong>General</strong>
            <div className='row'>
                <span>Tipo de operación : </span>
                <span>{data.business_type}</span>
            </div>
            <div className='row'>
                <span>Industria : </span>
                <span>{industries?.find(industry => industry.id === data.industry)?.name || industries?.find(industry => industry.id === data.other_industry)?.name}</span>
            </div>
            <div className='row'>
                <span>Incoterm : </span>
                <span>{incoterms?.find(incoterm => incoterm.id === data.business_term)?.name}</span>
            </div>

            <strong>Cargas :</strong>
            <div className='row'>
                <span>Peso total : </span>
                <span>{totalWeight.toFixed(2)} Kg</span>
            </div>
            <div className='row'>
                <span>CBM total : </span>
                <span>{formatCBM(totalCBM)}</span>
            </div>
            {
                data.shipping?.shipping_method==="air" &&
                <div className='row'>
                    <span>Peso cargable aereo : </span>
                    <span>{(totalCBM * 166.67).toFixed(2)}</span>
                </div>
            }
            {
                data.shipping?.shipping_method==="ocean" &&
                <div className='row'>
                    <span>Toneladas totales : </span>
                    <span>{(totalWeight/1000).toFixed(2)}</span>
                </div>
            }
            <StyledTable>
                <thead>
                    <tr style={{fontSize:'13px'}}>
                        <th>Nombre</th>
                        <th>Valor (USD)</th>
                        <th>Nro. piezas</th>
                        <th>Alto</th>
                        <th>Ancho</th>
                        <th>Largo</th>
                        <th>CMB</th>
                        <th>Peso total</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.loads?.map((load, index) => (
                        <tr key={index}>
                            <td>{load.name}</td>
                            <td>{`${load.value} USD`}</td>
                            <td>{load.amount}</td>
                            <td>{`${load.height}${load.height_unit}`}</td>
                            <td>{`${load.width}${load.width_unit}`}</td>
                            <td>{`${load.long}${load.long_unit}`}</td>
                            <td>{formatCBM(calculateCBM(load.long,load.long_unit,load.width,load.width_unit,load.height,load.height_unit))}</td>
                            <td>
                                <Tooltip content="peso x cantidad" color={"primary"}>
                                    {`${load.total_weight} ${load.total_weight_unit}`}
                                </Tooltip>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </StyledTable>

            <strong>Adicional : </strong>
            {data.service_options?.temperature_control === 1 && <span>Requiere control de temperatura :  Si</span>}
            {data.service_options?.data_logger === 1 && <span>Requiere Data logger  :  Si</span>}
            {data.service_options?.remountable === 1 && <span>La carga es remontable :  Si</span>}
            {data.service_options?.arancelary_position === 1 && <span>Requiere posicion arancelaria :  Si</span>}
            <span>Especificaciones adicionales: {data.service_options?.additional_specifications || 'Ninguna'}</span>
        </Container>
    )
}
