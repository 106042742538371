import React, { useState } from "react";
import { styled } from "@nextui-org/react";
import theme from "../../theme";
import { useTranslation } from "react-i18next";

// Components
import PageTitle from "../../components/PageTitle/PageTitle";

const Tariff = () => {
  const { t: translate } = useTranslation("Tariff");
  const StyledTariff = styled("div", {
    [`.${theme} &`]: {
      padding: "3rem 2rem",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      section: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "85%",
      },
      aside: {
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        width: "30%",
      },
      [".content"]: {
        display: "flex",
        gap: "1rem",
      },
    },
  });

  return (
    <StyledTariff>
      <PageTitle>{translate("title")}</PageTitle>
      <div className="content">
        <section>
          <div className="tariff">
            <iframe
              src="https://muisca.dian.gov.co/WebArancel/DefMenuConsultas.faces"
              width="100%"
              height="500px"
              title="Tariff Page"
            ></iframe>
          </div>
        </section>
      </div>
    </StyledTariff>
  );
};

export default Tariff;
