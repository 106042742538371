import { URL } from "../constants"

export const getHome = async (token, dateStart, dateFinish) => {
    const response = await fetch(`${URL}home/statistics/dated/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,

        },
        body: JSON.stringify({
            date_start: dateStart,
            date_finish: dateFinish
        })
    })
    return response.json()
}

export const getStatisticsSplit = async (token) => {
    const response = await fetch(`${URL}home/statisticssplit/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response;
}
export const getStatisticsSplitDated = async (token, dateStart, dateFinish) => {
    const response = await fetch(`${URL}home/statisticssplit/dated/${dateStart}/${dateFinish}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,

        },
    })
    return response
}
