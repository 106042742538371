import React, { useEffect, useState } from 'react';
import { Collapse, styled } from '@nextui-org/react';
import theme from '../../../theme';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import toast from '../../../components/Toast/ToastTypes';
import { getProfileDocuments, GetDocumentData } from '../../../services/user.services';
import { useSelector } from 'react-redux';
import { downloadDocument, getDocuments, handleDownloadDocument, generateVal170 } from '../../../services/documents.services';
import Pdf from '../../../assets/Files/pdf.png'
import Table from "../../../components/Table/Table";

// Components
import Summary from '../Summary/Summary';
import Button from '../../../components/Button/Button';
import InputSelect from '../../../components/Select/inputSelect';
import TextEditor from '../../../components/TextEditor/TextEditor';
import TableDocuments from './TableDocuments/TableDocuments';
import { PDFViewer } from '@react-pdf/renderer';
import RenderDocument from './RenderDocument';

//templates 
import createCircular170 from './Templates/createCircular170';
import createCompas from './Templates/createCompas';
import Val170 from './Templates/Val170';

const StyledCollapse = styled(Collapse, {
    [".nextui-collapse-title"]: {
        color: '$primary_300',
    }
})

const StyledDocuments = styled('div', {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
})

const columns = [
    { name: 'Acciones', uid: 'actions', type: 'action' },
    { name: 'No.', uid: 'id', type: 'id' },
    { name: 'Documento', uid: 'name', type: 'text' },
    { name: 'Tipo', uid: 'type', type: 'text' },
    { name: 'Fecha de vencimiento', uid: 'dueDate', type: 'date' },
]


const Documents = ({ user }) => {
    const navigate = useNavigate()
    const token = useSelector(state => state.user.token)
    const iduser = useSelector(state => state.user.idUser)
    const [searchParams] = useSearchParams()
    const [documents, setDocuments] = useState([])
    const [document, setDocument] = useState('')
    const [HTML, setHTML] = useState("")
    const [circularData, setcircularData] = useState({})
    const actionDocuments = [
        {
            name: 'Descargar',
            icon: 'Edit',
            type: 'button',
            handle: (e) => download(e)/* (roles.data, "Seleccione el nuevo rol del usuario", "Cambiar rol") */
        },
        {
            name: 'Actualizar',
            icon: 'Trash',
            type: 'button',
            handle: () => download(document)
        }
    ];

    useEffect(() => {
        getProfileDocuments(token)
            .then(res => res.json())
            .then(data => {
                setDocuments(data)
            })

        setTimeout(() => {
            let message;

            if (searchParams.get('created') === 'documents') {
                const title = '¡Formulario exitoso!'
                message = 'Tu información ha sido cargada a la plataforma.'
                toast('success', message, title)
            }
        }, 1000)
    }, []);
    useEffect(() => {
        const asyncTasks = [
            GetDocumentData([], token, iduser, 'general'),
            GetDocumentData([], token, iduser, 'accountants'),
            GetDocumentData(['name', 'last_name', 'document_number', 'total_shares'], token, iduser, 'partnerships'),
            GetDocumentData(['name', 'last_name', 'position', 'document_number', 'document_type'], token, iduser, 'representatives'),
            GetDocumentData(['entity', 'account_type', 'account_number', 'branch', ], token, iduser, 'bankreferences'),
            GetDocumentData(['company', 'contact', 'phone'], token, iduser, 'beneficiaries'),
            GetDocumentData(['email','name', 'phone', 'last_name'], token, iduser, 'exteriors'),
            GetDocumentData(['company', 'contact', 'address', 'phone'], token, iduser, 'tradeReferences'),
            GetDocumentData(['email','name', 'phone', 'last_name'], token, iduser, 'accounts'),
        ]
        Promise.all(asyncTasks).then(async res => {
            const circularDataConstructor = {}
            const general = await res[0].json()
            const accountants = await res[1].json()
            const partnerships = await res[2].json()
            const representatives = await res[3].json()
            const bankreferences = await res[4].json()
            const beneficiaries = await res[5].json()
            const exteriors = await res[6].json()
            const tradeReferences = await res[7].json()
            const accounts = await res[8].json()
            circularDataConstructor.general = general
            circularDataConstructor.accountants = accountants
            circularDataConstructor.socios = []
            partnerships.forEach(element => {
                circularDataConstructor.socios.push(
                    {
                        name: `${element.name} ${element.last_name}`,
                        nit: element.document_number,
                        total_shares: element.total_shares
                    }

                )
            });
            circularDataConstructor.representantes_legales = []
            representatives.forEach(element => {
                circularDataConstructor.representantes_legales.push(
                    {
                        name: `${element.name} ${element.last_name}`,
                        document: element.document_number,
                        position: element.position,
                        document_type:element.document_type
                    }

                )
            });
            circularDataConstructor.referencias_bancarias = []
            bankreferences.forEach(element => {
                circularDataConstructor.referencias_bancarias.push(
                    {
                        entity: element.entity,
                        account_type: element.account_type,
                        account_number: element.account_number,
                        branch:element.branch
                    }

                )
            });
            circularDataConstructor.referencias_comerciales = []
            tradeReferences.forEach(element => {
                circularDataConstructor.referencias_comerciales.push(
                    {
                        address: element.address,
                        company: element.company,
                        contact: element.contact,
                        phone:element.phone
                    }

                )
            });
            circularDataConstructor.beneficiarios = []
            beneficiaries.forEach(element => {
                circularDataConstructor.beneficiarios.push(
                    {
                        company: element.company,
                        contact: element.contact,
                        phone:element.phone
                    }

                )
            });
            circularDataConstructor.exteriors = []
            exteriors.forEach(element => {
                circularDataConstructor.exteriors.push(
                    {
                        email: element.email,
                        name: `${element.name} ${element.last_name}`,
                        phone:element.phone
                    }

                )
            });
            circularDataConstructor.accounts = []
            accounts.forEach(element => {
                circularDataConstructor.accounts.push(
                    {
                        email: element.email,
                        name: `${element.name} ${element.last_name}`,
                        phone:element.phone
                    }

                )
            });
            setcircularData(circularDataConstructor)
        })
    }, [token,iduser])
    const download = (document) => {
        downloadDocument(document.route, token)
            .then(res => {
                !res.ok && toast('error', 'Hubo un error al intentar descargar el documento')
                return res.json()
            })
            .then(data => {
                handleDownloadDocument(data.document, document.name)
            })
    }

    // const circularData = {
    //     "socios": ["Socio1", "Socio2*"],
    //     "representantes_legales": ["RepresentanteLegal1", "RepresentanteLegal2*"],
    //     "referencias_bancarias": "BancoX*",
    //     "informacion_general": "InformacionGeneral",
    //     "referencias_comerciales": ["ReferenciaComercial1", "ReferenciaComercial2"],
    //     "proceso_comercio_exterior": "ProcesoComercioExterior",
    //     "contacto_cuentas_por_pagar": "ContactoCuentasPorPagar",
    //     "razonSocial": "NombreComercial",
    //     "documento": "c.c. 1193231633",
    //     "ciudad": "NombreCiudad",
    //     "municipio": "NombreMunicipio",
    //     "telefono": "NumeroTelefono",
    //     "correo": "CorreoElectronico",
    //     "celularOFax": "NumeroCelularOFax",
    //     "paginaWeb": "www.ejemplo.com",
    //     "antiguedadEnMercado": "10 años",
    //     "fechaConstitucion": "2022-01-01",
    //     "retencionICA": "15%",
    //     "retencionFuente": "RetencionFuenteEjemplo",
    //     "regimenComun": "Sí",
    //     "granContribuyente": "Sí",
    //     "regimenSimplificado": "No",
    //     "autoRetenedor": "No",
    //     "totalActivos": "1000000",
    //     "totalPasivos": "500000",
    //     "totalPatrimonios": "500000"
    // }

    const compasData = {
        "ciudad": "NombreCiudad*",
        "fecha": "FechaEjemplo",
        "razonSocialPoderante": "NombreRazonSocialPoderante",
        "nitPoderante": "NITPoderanteEjemplo",
        "tipoDeDocumentoPoderante": "TipoDocumentoPoderanteEjemplo",
        "razonSocialApoderado": "NombreRazonSocialApoderado",
        "nitApoderado": "NITApoderadoEjemplo",
        "nombreRLApoderado": "NombreRLApoderadoEjemplo",
        "tipoDeDocumentoApoderado": "TipoDocumentoApoderadoEjemplo",
        "numeroDocumentoApoderado": "NumeroDocumentoApoderadoEjemplo",
        "x1": "ValorX1",
        "x2": "ValorX2",
        "x3": "ValorX3",
        "x4": "ValorX4",
        "x5": "ValorX5",
        "ccPoderante": "CCPoderanteEjemplo",
        "ccPoderado": "CCPoderadoEjemplo",
        "nitPoderado": "NITPoderadoEjemplo"
    }



    return (
        <StyledDocuments className='theme'>
            {/* <StyledCollapse title='Generar documento'> */}
            <div style={{ margin: '15px' }}>
                <label style={{ margin: '10px' }}>Seleccione un documento para generar con sus datos :</label>
                <InputSelect options={[
                    { label: 'Ninguno', value: '' },
                    { label: 'Val 170', value: 'Val170' },
                ]}
                    disableClear={true}
                    onChange={e => setDocument(e.value)}
                />
            </div>
            {document === 'Val170' && <Val170 data={circularData} />}

            {/* <div style={{ margin: '15px' }}>
                <label style={{ margin: '10px' }}>Seleccione la plantilla :</label>
                <InputSelect options={[
                    { label: 'Ninguno', value: '' },
                    { label: 'Val circular 170', value: createCircular170(circularData) },
                    { label: 'C-170', value: 'C-170' },
                    { label: 'Compas', value: createCompas(compasData) },
                ]}
                    disableClear={true}
                    onChange={e => setHTML(e.value)}
                />
            </div> */}
            {/* <TextEditor state={HTML} setState={setHTML} readOnly={true}/> */}
            {/* <div style={{ all: 'initial', border: '1px solid black', background: 'white', padding: '20px' }} className='html-container' dangerouslySetInnerHTML={{ __html: HTML }} /> */}
            {/* </StyledCollapse> */}
            {/* <button onClick={()=>generate170()}>generar val 170</button> */}
            {/* <Summary user={user}></Summary> */}
            {
                documents.length != 0 ?
                    <Table columns={columns} items={documents} actions={actionDocuments} />
                    :
                    <>
                        Para subir tus documentos por primera vez, te sugerimos realizar el siguiente formulario.
                        <Button handler={() => navigate('/profile/forms')} text='Completar formulario' bgColor='primary_400' textColor='neutral_50'></Button>
                    </>
            }
        </StyledDocuments >
    );
}

export default Documents;