import toast from '../../components/Toast/ToastTypes'
import Table from '../../components/Table/Table'
import { deleteOperation } from '../../services/operations.services'
import { useEffect, useState } from 'react'
import { Modal, styled } from '@nextui-org/react'
import Button from '../../components/Button/Button'
import { useSelector } from 'react-redux';

export default function OperationsTable({ items, setSelectedOperation, setShowTable, updateTable, setUpdateTable, loading }) {

    const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
    const [selectedItemId, setSelectedItemId] = useState('')
    const token = useSelector(state => state.user.token)
    const StyledFormModal = styled(Modal, {
        padding: '1.5rem',
        color: '$primary_500 !important',
    })
    const columns = [
        { name: 'Acciones', uid: 'actions', type: 'action' },
        { name: 'Operacion', uid: 'radication', type: 'text' },
        { name: 'Tipo de operacion', uid: 'operation_type', type: 'text' },
        { name: 'Origen', uid: 'origin', type: 'text' },
        { name: 'Destino', uid: 'destination', type: 'text' },
        { name: 'Incoterm', uid: 'incoterm', type: 'text' },
        { name: 'Fecha de creacion', uid: 'created_at_date', type: 'text' },
        { name: 'ETD', uid: 'etd', type: 'text' },
        { name: 'ETA', uid: 'eta', type: 'text' },
        // { name: 'Asesor', uid: 'coordinator', type: 'text' },
        { name: 'Cliente', uid: 'client', type: 'text' },
        { name: 'Agente', uid: 'agent', type: 'text' },
        { name: 'Estado', uid: 'status', type: 'text' },
        { name: 'Estado de operacion', uid: 'operation_status', type: 'text' }
    ]

    const actions = [
        {
            name: 'Seleccionar',
            icon: 'View',
            handle: (item) => {
                setSelectedOperation({id:item.id, radication:item.radication})
                setShowTable(false)
            }
        },
        {
            name: 'Borrar',
            icon: 'Trash',
            handle: (item) => {
                setSelectedItemId(item.id)
                setOpenConfirmationModal(true)
            }
        },
    ]

    const handleDelete = () => {
        deleteOperation(selectedItemId, token).then(() => {
            setSelectedOperation('')
            setOpenConfirmationModal(false)
            toast('success', 'Operacion eliminada')
            setUpdateTable(updateTable + 1);
        }).catch(() => toast('error', 'No se pudo borrar la operacion'))
    }
    // background: '#EBEDF0',
    return (
        <div style={{ borderRadius: '8px' }}>
            {items.length > 0 && <Table columns={columns} items={items} actions={actions} searchBar={false}/>}
            <StyledFormModal
                aria-label="modal-title"
                open={openConfirmationModal}
                onClose={() => setOpenConfirmationModal(false)}
                width="fit-content"
            >
                <Modal.Header justify='space-between'>
                    <h3>Confirmar</h3>
                </Modal.Header>
                <Modal.Body justify='space-between'>
                    <p>Confirma que desea borrar la operacion seleccionada</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
                        <Button text='Cancelar' bold bgColor={'primary_300'} textColor='neutral_50' handler={() => setOpenConfirmationModal(false)} />
                        <Button text='Borrar' bold bgColor={'delete'} textColor='neutral_50' disabled={!selectedItemId} handler={() => handleDelete()} />
                    </div>
                </Modal.Body>
            </StyledFormModal>
        </div>
    )
}