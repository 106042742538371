import { URL } from "../constants"

export const calculateCO2 = async (data, token) => {
    const response = await fetch(`${URL}compensation/co2/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            data: data
        })
    })
    return response.json()
}

export const getShippingsCo2 = async (token) => {
    const response = await fetch(`${URL}shipping-co2/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const getCO2Stats = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}shippingstats/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}
export const getCO2StatsCompensation = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}shippingstatscompensation/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const getEmissions = async (token, radication) => {
    const response = await fetch(`${URL}co2_radication/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const calculateCO2byPerson = async (token, data) => {
    const response = await fetch(`${URL}calculateco2personal/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            data: data
        })
    })
    return response;
}
export const getCompanyCo2 = async (token, user) => {
    const response = await fetch(`${URL}co2PerUser/${user}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const getUserCo2 = async (token, user) => {
    const response = await fetch(`${URL}co2-peruser/${user}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response;
}

export const recalculateCO2 = async (token,radication) => {
    const response = await fetch(`${URL}recalculate-co2/${radication}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response.json()
}

export const calculateCO2Massive = async (token, data) => {
    const response = await fetch(`${URL}calculateco2massive/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify({
            data: data
        })
    })
    return response;
}
export const compensateCO2 = async (token, instance, method) => {
    const response = await fetch(`${URL}compensate-co2/${instance}/${method=='radication'?'radication':'id'}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response.json()
}
export const getCompensations = async (token, instance, method) => {
    const response = await fetch(`${URL}getcompensations/${instance}/${method=='radication'?'radication':'id'}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response.json()
}
export const approveCompensation = async (token, id, user) => {
    const response = await fetch(`${URL}approve-compensation/${id}/${user}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
    })
    return response.json()
}