import {URL,CARGOES_API_KEY, CARGOES_TOKEN} from '../constants'

// const apiKey = CARGOES_API_KEY
// const token = CARGOES_TOKEN

export const getShipmentTracking = async (token, tag, shipmentType = "INTERMODAL_SHIPMENT") => {
    //shipmentTypes : INTERMODAL_SHIPMENT, TRUCK_SHIPMENT, AIR_SHIPMENT, OUTSOURCED_LOGISTICS_SHIPMENT
    console.log("tag :",tag)
    const response = await fetch(`${URL}trackingv2/?shipmentType=${shipmentType}&_page=1&_limit=50&tags=${tag}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
export const getCarriers = async (token) => {
    const response = await fetch(`${URL}trackingv2-carrierlist/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const postByAWB = async (token, data) => {
    const response = await fetch(`${URL}awbnumber/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}
export const postByBooking = async (token, data) => {
    const response = await fetch(`${URL}bookingnumber/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}
export const postByMBL = async (token, data) => {
    const response = await fetch(`${URL}mblnumber/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}
export const postByContainer = async (token, data) => {
    const response = await fetch(`${URL}containertrack/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}
export const postByVessel = async (token, data) => {
    const response = await fetch(`${URL}vesselid/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}

export const SubscribeTracking= async (token, data) => {
    // {
    //     "radication": "VAL-202403-0022", (OPCIONAL)
    //     "purchase_order" (OPCIONAL),
    //     " URL": (OPCIONAL WEBHOOKS)
    //     "emails": [
    //         "julianramirezfelipe@gmail.com",
    //         "andreseriverab@gmail.com"
    //     ]
    // }
    const response = await fetch(`${URL}trackingsubscribers/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        },
        body: JSON.stringify(data)
    });
    if (response.ok){
        return response
    } else throw Error('Error en POST :', response.message)
}