import React from "react";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ViewsHandler from "../../components/ViewsHandler/ViewsHandler";
import { Collapse, Loading } from "@nextui-org/react";
import { styled } from "@nextui-org/react";
import OperationsTable from "./OperationsTable";
import Events from "./Events/Events";
import TrackingNotes from "./Notes";
import Notificate from "./Notificate/Notificate";
import CreateOperation from "./CreateOperation";
import Instructions from "./Instructions/Instructions";

import {
  getOperations,
  getOperationTypes,
  getStatuses,
  getOperationStatuses,
  getAgents,
  getOperationsSplit,
} from "../../services/operations.services";
import { getUsers } from "../../services/user.services";
import { useNavigate, useSearchParams } from "react-router-dom";
import AddShipment from "./AddShipment/AddShipment";
import { getServiceSplitRadication } from "../../services/quotation.services";
import { getShippingMethods } from "../../services/shippingMethods.services";
import Icon from "../../components/Icon/Icon";
import { format, parseISO } from "date-fns";
import FactorLogistico from "./FactorLogistico/FactorLogistico";
import { useTranslation } from "react-i18next";
import Pagination from "../../components/Pagination/Pagination";
import Input from "../../components/Input/Input";
import { debounce } from "lodash";
import LeadTimes from "./LeadTimes/LeadTimes";

const StyledContainer = styled("div", {
  marginBottom: "50px",
  ["header"]: {
    backgroundColor: "$neutral_50",
    padding: "1rem 2rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    [".title"]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  [".css-1a4cg4j-MuiButtonBase-root-MuiTab-root"]: {
    fontFamily: "inherit",
    color: "$primary_300",
    textTransform: "none",
  },
  [".Mui-selected"]: {
    fontWeight: "600",
    color: "$primary_300 !important",
  },
  [".MuiTabs-indicator"]: {
    backgroundColor: "$secondary_300",
  },
});

const StyledCollapse = styled(Collapse, {
  padding:'20px',
  backgroundColor:"$neutral_100",
  borderRadius:'8px',
  [".nextui-collapse-title"]: {
    color: "$primary_300",
  },
});

export default function OperationsTracking() {
  const token = useSelector((state) => state.user.token);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tabValue, setTabValue] = useState(searchParams.get("tab"));

  const [tableItems, setTableItems] = useState([]);
  const [updateTable, setUpdateTable] = useState(1);
  const [selectedOperation, setSelectedOperation] = useState("");
  const [operationsData, setOperationData] = useState({});
  const [shippingMethod, setShippingMethod] = useState("");

  const [showTable, setShowTable] = useState(false);
  const { t: translate } = useTranslation("OperationsTracking");

  //paginado
  const [count, setCount] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterSearch, setFilterSearch] = useState("");
  const [loading, setLoading] = useState(true)

  const tabs = [
    { id: "create_operation", value: "create_operation", label: translate("create_operation")},
    { id: "events", value: "events", label: translate("operation_status") },
    { id: "notes", value: "notes", label: translate("logbook") },
    { id: "notificate", value: "notificate", label: translate("notify") },
    { id: "instructions", value: "instructions", label: translate("instructions")},
    { id: "add-shipment-tracking", value: "trackingForm", label: translate("add_tracking"), disabled: shippingMethod?.name === "ground"},
    { id: "factor_logistico", value: "factor_logistico", label: "Factor logístico"},
    { id: "lead_times", value: "lead_times", label: "Lead times"},
  ];

  const [tabId, setTabId] = useState(
    tabs.findIndex((tab) => tab.value === searchParams.get("tab"))
  );

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabId(tabs.findIndex((tab) => tab.value === newValue));
    navigate(`/operations/tracking/?tab=${newValue}`);
  };

  function formatDate(dateString) {
    const originalDate = new Date(dateString);
    const day = originalDate.getDate();
    const month = originalDate.getMonth() + 1;
    const year = originalDate.getFullYear();

    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;

    return formattedDate;
  }

  useEffect(() => {
    setLoading(true)
    const asyncTasks = [
      getOperationsSplit(token, currentPage, pageSize, filterSearch),
      getOperationTypes(token),
      getUsers(token),
      getAgents(token),
      getStatuses(token),
      getOperationStatuses(token),
    ];
    Promise.all(asyncTasks)
      .then(async (responses) => {
        const operationsData = await responses[0].json();
        const operationTypesData = await responses[1].json();
        const usersData = await responses[2].json();
        const agentsData = await responses[3].json();
        const statusesData = await responses[4].json();
        const operationStatusesData = await responses[5].json();

        setCount(operationsData?.count)
        setOperationData(operationsData);

        if (searchParams.get("selectId")) {
          const operation = operationsData.find(
            (op) => op.id == searchParams.get("selectId")
          );
          setSelectedOperation({
            id: operation?.id,
            radication: operation?.radication,
          });
        }
        const tableData = operationsData.results.map((operation, i) => {

          const client = usersData.find(
            (client) => client.id === operation.client
          );
          const fecha = parseISO(operation.created_at);
          const fechaFormateada = format(fecha, "dd/MM/yyyy");
          return {
            id: operation.id,
            radication: operation.radication,
            operation_type_id: operation.operation_type,
            operation_type: operationTypesData.find(
              (type) => type.id === operation.operation_type
            )?.name,
            etd: operation.ETD ? formatDate(operation.ETD) : "No asignado",
            eta: operation.ETA ? formatDate(operation.ETA) : "No asignado",
            created_at_date: fechaFormateada,
            origin: `${operation?.shipping?.origin_boarding_terminal}`,
            destination: `${operation?.shipping?.destination_boarding_terminal}`,
            incoterm: operation.business_term_name,
            coordinator: ``,
            client: client?.name + " " + client.last_name,
            agent: agentsData.find((client) => client.id === operation.agent)
              ?.name,
            status: statusesData.find(
              (status) => status.id === operation.status
            )?.name,
            operation_status: operationStatusesData.find(
              (OS) => OS.id === operation.operation_status
            )?.name,
            created_at: operation.created_at,
          };
        });
        // const sortedTableData = tableData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        const compareDates = (a, b) => {
          const dateA = parseISO(a.created_at);
          const dateB = parseISO(b.created_at);
          if (dateA < dateB) {
            return 1;
          }
          if (dateA > dateB) {
            return -1;
          }
          return 0;
        };
        const sortedTableData = tableData.sort(compareDates);
        setTableItems(sortedTableData);
        setLoading(false)
      })
      .catch((err) => {console.log("ERROR :", err);  setLoading(false)});
  }, [updateTable, searchParams.get("selectId"), currentPage, filterSearch, pageSize]);

  useEffect(() => {
    if (selectedOperation && searchParams.get("tab") === "create_operation") {
      handleChange("", "events");
    }
  }, [selectedOperation, operationsData]);

  useEffect(() => {
    if (selectedOperation) {
      Promise.all([
        getServiceSplitRadication(selectedOperation.radication, token),
        getShippingMethods(token),
      ]).then(async (res) => {
        const serviceData = await res[0].json();
        const shippingMethodsData = await res[1].json();

        const service = serviceData.results[0];
        const shippingMethod = shippingMethodsData.find(
          (method) => method.id === service.shipping.shipping_method
        );
        setShippingMethod(shippingMethod);
      });
    }
  }, [selectedOperation]);

  const handleSearch = (value) => {
    setFilterSearch(value)
  }
  const debouncedSearch = debounce((value) => {
    handleSearch(value)
  }, 300);

  return (
    <StyledContainer>
      <header>
        <StyledCollapse
          title={translate("current_operations")}
          expanded={showTable}
          onClick={() => setShowTable(true)}
        >
          <Input
              placeholder={"Buscar operacion..."}
              startIcon={{ name: 'Search' }}
              onChange={(e) => { debouncedSearch(e.target.value) }}
              disabled={loading}
              fullWidth
          />
          {
          loading? <Loading size="lg"/> :
          <OperationsTable
            loading={loading}
            items={tableItems}
            setSelectedOperation={setSelectedOperation}
            setShowTable={setShowTable}
            updateTable={updateTable}
            setUpdateTable={setUpdateTable}
          />
          }
          <Pagination
            page={currentPage}
            setPage={setCurrentPage}
            itemsPerPage={pageSize}
            setItemsPerPage={setPageSize}
            totalItems={count}
          />
        </StyledCollapse>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="service"
        >
          {tabs.map((tab, i) => (
            <Tab
              key={i}
              value={tab.value}
              label={tab.label}
              disabled={tab.disabled}
            />
          ))}
        </Tabs>
      </header>
      <div style={{ padding: "30px" }}>
        {
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p style={{ margin: "20px" }}>
              {translate("selected_op")}:{" "}
              {selectedOperation?.radication || "Ninguna"}{" "}
            </p>
            <Icon
              size="30"
              name={
                shippingMethod.name == "air"
                  ? "Aereo"
                  : shippingMethod.name == "ground"
                  ? "Terrestre"
                  : "Maritimo"
              }
            />
          </div>
        }
        <ViewsHandler activeView={tabId}>
          <CreateOperation
            updateTable={updateTable}
            setUpdateTable={setUpdateTable}
            setSelectedOperation={setSelectedOperation}
          />
          <Events
            selectedOperation={selectedOperation?.id}
            setSelectedOperation={setSelectedOperation}
            setTabValue={setTabValue}
            setTabId={setTabId}
          />
          <TrackingNotes
            selectedOperation={selectedOperation?.id}
            operationsData={operationsData}
          />
          <Notificate selectedOperation={selectedOperation?.id} />
          <Instructions />
          <AddShipment
            selectedOperation={selectedOperation}
            operationsData={operationsData}
            shippingMethod={shippingMethod}
            handleChange={handleChange}
          />
          <FactorLogistico selectedOperation={selectedOperation} />
          <LeadTimes selectedOperation={selectedOperation}/>
        </ViewsHandler>
      </div>
    </StyledContainer>
  );
}
