import React from 'react'
import Table from '../../../../components/Table/Table'

export default function MilestoneTable() {

  const columns = [
    { name: 'Latest update', uid: 'last_update', type: 'action' },
    { name: 'Milestone', uid: 'milestone', type: 'text' },
    { name: 'Tracking #', uid: 'tracking_number', type: 'text' },
    { name: 'Vessel', uid: 'vessel', type: 'text' },
    { name: 'Container #', uid: 'container', type: 'text' },
    { name: 'Reference #', uid: 'reference', type: 'text' },
    { name: 'Transport', uid: 'transport', type: 'text' },
    { name: 'From', uid: 'from', type: 'text' },
    { name: 'To', uid: 'to', type: 'text' },
    { name: 'Location', uid: 'location', type: 'text' },
  ]
  const mockData = [
    {
      key:1,
      last_update:"04/04 05:51 AM",
      milestone:"Vessel departure",
      tracking:'TS-5M5D87',
      container:"OOLU0287829	",
      reference:"",
      transport:"CMA CGM ESTELLE",
      from:"Cartagena, Colombia",
      to:"Caucedo",
      location:"In Transit",
    },
    {
      key:2,
      last_update:"04/04 05:51 AM",
      milestone:"Vessel departure",
      tracking:'TS-5M5D87',
      container:"OOLU0287829	",
      reference:"",
      transport:"CMA CGM ESTELLE",
      from:"Cartagena, Colombia",
      to:"Caucedo",
      location:"In Transit",
    },
  ]
  return (
    <Table columns={columns} items={mockData} excel={false} searchBar={false}/>
  )
}
