import React from 'react'
import { styled } from '@nextui-org/react'
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import theme from '../../theme';

const StyledLabel = styled('div', {
    color: '$primary_500',
    fontSize: '12px',
    paddingLeft: '0.25rem',
    marginBottom: '0.55rem',
    ['span']: {
        color: '#ff676c'
    }
})

export default function FormInputSelect({name, label, options, isMulti=false, required=false}) {
    const colourStyles = {
        menuPortal: provided => ({ ...provided, zIndex: 9999, position:'absolute', top:'15px', left:0 }),
        menu: provided => ({ ...provided, width:'100%', zIndex: 9999, position:'absolute', top:'15px', left:0 }),
        control: (styles) => ({
            ...styles,
            border: '1px solid ' + theme.colors.primary_300,
            borderRadius: '8px',
            margin: 0,
            padding: 0,
            height: '40px',
            // minWidth:'300px',
            width:'100%',
            minWidth:'200px',
            boxShadow: 'none',
            outline: 'none',
            [':hover']: {
                borderColor: 'grey',
            }
        }),
    };
    const animatedComponents = makeAnimated();
    const { control } = useFormContext();
    const errors = useFormState()?.errors
    const thisFieldError = errors[name[0]]? errors[name[0]][name.slice(2)] : errors[name] || false

    return (
    <Controller
        name={name}
        defaultValue=""
        control={control}
        rules= {required ? { required: "Este campo es obligatorio" } : {}}
        render={({ field }) => (
            <div style={{display:'flex', flexDirection:'column'}}>
                {label &&
                    <StyledLabel>
                        <label>{label} {required ? <span>*</span> : ""}</label>
                    </StyledLabel>
                }
                <Select
                    {...field}
                    isMulti={isMulti}
                    options={options}
                    styles={colourStyles}
                    components={animatedComponents}
                    menuPosition="fixed"
                    menuPlacement="auto"
                />
                {thisFieldError && <span style={{color:'red', fontSize:'12px'}}>{thisFieldError.message}</span>}
            </div>
        )}
    />
  )
}
