import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate, useSearchParams } from "react-router-dom";
import { styled } from "@nextui-org/react";
import ViewsHandler from "../../../components/ViewsHandler/ViewsHandler";
import Summary from "./Summary";
import { useTranslation } from "react-i18next";
import OceanicShipmentDashboard from "./OceanicShipmentDashboard/OceanicShipmentDashboard";
import AddShipment from "../../OperationsTracking/AddShipment/AddShipment";
const tabs = [
  {
    id: "summary-tab",
    value: "summary",
    label: "Resumen",
  },
  {
    id: "ocean-shipment-tab",
    value: "ocean-shipment",
    label: "Envios por mar",
  },
  {
    id: "add-shipment-tab",
    value: "add-shipment",
    label: "Añadir rastreo",
  },
];

const Container = styled("div", {
  // background:'$neutral_70',
  borderRadius: "8px",
  padding: "30px",
  ".title": {
    margin: "20px 0",
    color: "$primary_300",
  },
  ".views": { margin: "40px" },
  [".css-1a4cg4j-MuiButtonBase-root-MuiTab-root"]: {
    fontFamily: "inherit",
    color: "$primary_300",
    textTransform: "none",
  },
  [".Mui-selected"]: {
    fontWeight: "600",
    color: "$primary_300 !important",
  },
  [".MuiTabs-indicator"]: {
    backgroundColor: "$secondary_300",
  },
});

export default function TrackingDashboard() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [tabId, setTabId] = useState(0);
  const [tabValue, setTabValue] = useState(searchParams.get("tab"));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setTabId(tabs.findIndex((tab) => tab.value === newValue));
    navigate(`/tracking/dashboard/?tab=${newValue}`);
  };

  useEffect(() => {
    setTabValue(tabValue);
    setTabId(tabs.findIndex((tab) => tab.value === tabValue));
  }, []);

  return (
    <Container>
      <h2 className="title">Dashboard</h2>
      <Tabs
        value={tabValue}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="service"
      >
        {tabs.map((tab, i) => (
          <Tab key={i} value={tab.value} label={tab.label} />
        ))}
      </Tabs>
      <div className="views">
        <ViewsHandler activeView={tabId}>
          <Summary />
          <OceanicShipmentDashboard />
          <AddShipment optionalRadication={true} hasPurchaseOrder={true}/>
        </ViewsHandler>
      </div>
    </Container>
  );
}
