import { URL } from "../constants"

export const getBusinessTerms = async (token) => {
    const response = await fetch(`${URL}business-terms/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getBusinessTermsByUser = async (token, startDate, endDate) => {
    //fecha en formato 2023-09-30 YYYY-MM-DD
    const response = await fetch(`${URL}business-terms-by-user/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getBusinessTermsByCountryForUser = async (token, country, startDate, endDate) => {
    const response = await fetch(`${URL}business-term-by-country-for-user/${country}/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}

export const getIncotermsByUser = async (token, startDate, endDate) => {
    const response = await fetch(`${URL}total-incoterms-by-user/${startDate}/${endDate}/`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Token " + token,
        }
    })
    return response
}
